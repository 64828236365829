import React, { useEffect, useState } from "react";
import SidebarLayout from "../../../layout/SidebarLayout";
import { useDispatch, useSelector } from "react-redux";
import { ImageCropper, Loader } from "../../../Components";
import { isString } from "../../../Utility/validations";
import { useFormik } from "formik";
import * as Yup from "yup";
import { bytesToKB, routeName, showToast } from "../../../Utility";
import { getInsightDetailApi } from "../../../Redux/Services/OtherServices";
import { useLocation, useNavigate } from "react-router-dom";
import EditText from "../../../Components/TextEditor";
import {
  addEditBlogApi,
  updateAdminProfile,
  uploadMedia,
} from "../../../Redux/Services/AdminServices";
import {
  removeImage,
  uploadGalleryProfile,
} from "../../../Redux/Services/AuthServices";

const EditAdminProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location?.search);
  const id = queryParams?.get("id");

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [cropped, setCropped] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [loading2, setLoading2] = useState(false);

  const uploadProfilePhoto = async (data, type) => {
    let uploadData = new FormData();
    uploadData.append("upload_type", "profile");
    uploadData.append("user_id", loggedInUser?.user_data?.user_id);
    uploadData.append("profile_id", loggedInUser?.user_data?.profile_id);
    uploadData.append("file_type", data);
    let res = await dispatch(uploadGalleryProfile(uploadData));
    if (res?.status == 200) {
      setCropped(false);
      setSelectedFile([]);
      setProfileImage({
        ...profileImage,
        uri: res?.results?.uri,
        name: res?.results.name,
        size: res?.results.size,
        type: res?.results?.type,
      });
    }
  };

  const handleChooseProfile = (event) => {
    const file = event.target.files[0];
    setSelectedFile(event.target.files[0]);
    if (file) {
      if (file && file.type.startsWith("image/")) {
        const detail = {
          uri: URL.createObjectURL(file),
          name: file.name,
          size: file.size,
          type: file?.type,
        };
        setSelectedFile(detail);
        setCropped(true);
      } else {
        showToast("Please select a valid image", "error");
      }
    } else {
      setProfileImage(null);
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    tagline: Yup.string().required("Tagline is required"),
  });

  const onSubmit = async (values) => {
    if (!profileImage) {
      showToast("Please upload profile image");
      return;
    }
    try {
      console.log("Form values:", values);
      let body = {
        user_id: loggedInUser?.user_data?.user_id,
        post_id: loggedInUser?.user_data?.profile_id,
        first_name: values.firstName,
        last_name: values.lastName,
        tagline: values.tagline,
      };

      setLoading(true);
      let res = await dispatch(updateAdminProfile(body));

      setLoading(false);

      console.log("identityVerification res---", res);
      if (res?.status === 200) {
        navigate(routeName.DASHBOARD, { replace: true });
      }
    } catch (error) {
      setLoading(false);
      console.error("API request failed:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      tagline: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const removeGalleryImage = async () => {
    const body = {
      user_id: loggedInUser?.user_data?.user_id,
      profile_id: loggedInUser?.user_data?.profile_id,
      image_type: "profile",
      image_id: profileImage?.attachment_id,
      action: "remove",
    };
    let res = await dispatch(removeImage(body));
    if (res?.status == 200) {
      setProfileImage("");
    }
  };

  useEffect(() => {
    if (loggedInUser) {
      formik.setFieldValue(
        "firstName",
        loggedInUser?.user_data?.first_name || ""
      );
      formik.setFieldValue(
        "lastName",
        loggedInUser?.user_data?.last_name || ""
      );
      formik.setFieldValue("email", loggedInUser?.user_data?.user_email || "");
      formik.setFieldValue(
        "tagline",
        loggedInUser?.company_details?.tag_line || ""
      );
      setProfileImage({
        ...profileImage,
        uri: loggedInUser?.profile_image?.[0]?.guid,
        attachment_id: loggedInUser?.profile_image?.[0]?.ID,
      });
    }
  }, []);

  return (
    <SidebarLayout>
      <Loader loading={loading || loading2} />
      <main>
        <div className="wt-dashboardbox wt-dashboardinvocies disputes-header">
          <div className=" idVerification">
            <div className="col-md-12">
              <form
                onSubmit={formik.handleSubmit}
                className="post-identity-form wt-haslayout wt-attachmentsholder"
              >
                <div className="wt-dashboardbox">
                  <div className="wt-dashboardboxtitle wt-titlewithsearch">
                    <h2>Edit Profile</h2>
                  </div>
                  <div className="wt-dashboardboxcontent">
                    <div className="wt-helpsupportcontents">
                      <div className="form-grou">
                        <div className="wt-formtheme wt-formidentityinfo wt-formprojectinfo wt-formcategory wt-userform">
                          <fieldset>
                            <div className="form-group">
                              <h2>First Name</h2>
                              <input
                                type="text"
                                name="firstName"
                                className="form-control"
                                placeholder="First Name"
                                onChange={(val) => {
                                  if (
                                    val.target.value === " " ||
                                    val.target.value === "."
                                  ) {
                                  } else if (isString(val.target.value)) {
                                    formik.handleChange(val);
                                  }
                                }}
                                value={formik.values.firstName}
                              />
                              {formik.errors.firstName &&
                                formik.touched.firstName && (
                                  <div className="error">
                                    {formik.errors.firstName}
                                  </div>
                                )}
                            </div>

                            <div className="form-group">
                              <h2>Last Name</h2>
                              <input
                                type="text"
                                name="lastName"
                                className="form-control"
                                placeholder="Last Name"
                                onChange={(val) => {
                                  if (
                                    val.target.value === " " ||
                                    val.target.value === "."
                                  ) {
                                  } else if (isString(val.target.value)) {
                                    formik.handleChange(val);
                                  }
                                }}
                                value={formik.values.lastName}
                              />
                              {formik.errors.lastName &&
                                formik.touched.lastName && (
                                  <div className="error">
                                    {formik.errors.lastName}
                                  </div>
                                )}
                            </div>

                            <div className="form-group">
                              <h2>Email</h2>
                              <input
                                type="text"
                                name="email"
                                className="form-control"
                                placeholder="Email"
                                disabled
                                onChange={(val) => {
                                  if (
                                    val.target.value === " " ||
                                    val.target.value === "."
                                  ) {
                                  } else if (isString(val.target.value)) {
                                    formik.handleChange(val);
                                  }
                                }}
                                value={formik.values.email}
                              />
                              {formik.errors.email && formik.touched.email && (
                                <div className="error">
                                  {formik.errors.email}
                                </div>
                              )}
                            </div>

                            <div className="form-group">
                              <h2>Tagline</h2>
                              <input
                                type="text"
                                name="tagline"
                                className="form-control"
                                placeholder="Tagline"
                                onChange={(val) => {
                                  if (
                                    val.target.value === " " ||
                                    val.target.value === "."
                                  ) {
                                  } else if (isString(val.target.value)) {
                                    formik.handleChange(val);
                                  }
                                }}
                                value={formik.values.tagline}
                              />
                              {formik.errors.tagline &&
                                formik.touched.tagline && (
                                  <div className="error">
                                    {formik.errors.tagline}
                                  </div>
                                )}
                            </div>

                            <div className="form-group">
                              <div className="wt-tabscontenttitle">
                                <h2>Profile Image</h2>
                              </div>
                              <div class="wt-bannerphoto wt-tabsinfo">
                                <div class="wt-profilephotocontent">
                                  <div class="form-group form-group-label">
                                    <div class="wt-labelgroup">
                                      <label for="filep">
                                        <span class="wt-btn">Select Files</span>
                                        <input
                                          type="file"
                                          name="file"
                                          id="filep"
                                          onChange={handleChooseProfile}
                                        />
                                      </label>
                                      <span>Choose file to upload</span>
                                    </div>
                                  </div>
                                  {selectedFile && (
                                    <ImageCropper
                                      selectedFile={selectedFile}
                                      setSelectedFile={setSelectedFile}
                                      imageSrc={selectedFile?.uri}
                                      type={"profile"}
                                      show={cropped}
                                      setShow={setCropped}
                                      cropped={cropped}
                                      setCropped={setCropped}
                                      profileImage={profileImage}
                                      setProfileImage={setProfileImage}
                                      onCrop={uploadProfilePhoto}
                                    />
                                  )}
                                  {profileImage && (
                                    <div class="form-group">
                                      <ul class="wt-attachfile wt-attachfilevtwo">
                                        <li class="wt-uploadingholder">
                                          <div class="wt-uploadingbox">
                                            <div class="wt-designimg">
                                              <input
                                                id="demoq"
                                                type="radio"
                                                name="employees"
                                                value="company"
                                              />
                                              <label for="demoq">
                                                <img
                                                  src={
                                                    profileImage?.url
                                                      ? profileImage?.url
                                                      : profileImage?.uri
                                                  }
                                                  alt="Hire talent | Model near me | Talent acquisition | Modeling agencies near me | Modeling Jobs | Modeling companies near me"
                                                />
                                                <i class="fa fa-check"></i>
                                              </label>
                                            </div>
                                            <div class="wt-uploadingbar">
                                              <span class="uploadprogressbar"></span>
                                              <span>{profileImage?.name}</span>
                                              <em>
                                                {profileImage?.size && (
                                                  <>
                                                    File size:{" "}
                                                    {bytesToKB(
                                                      profileImage?.size
                                                    )}
                                                  </>
                                                )}
                                                <i
                                                  class="fa fa-times lnr lnr-cross"
                                                  aria-hidden="true"
                                                  onClick={() =>
                                                    removeGalleryImage()
                                                  }
                                                ></i>
                                              </em>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <button
                              type="submit"
                              className="wt-btn wt-save-identity"
                            >
                              Submit
                            </button>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </SidebarLayout>
  );
};

export default EditAdminProfile;
