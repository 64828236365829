import React, { useEffect, useState } from "react";
import Sidebar from "../Screens/Others/Sidebar";
import { DeletePost, Header, Loader } from "../Components";
import { useDispatch, useSelector } from "react-redux";
import { partnerAccessPermissions } from "../Redux/Actions/PartnerActions";
import { routeName } from "../Utility";
import { useNavigate } from "react-router-dom";
import { Images } from "../Constants";
import { removeData, storageKey } from "../Utility/Storage";
import moment from "moment";

const SidebarLayout = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { partnerPermissions } = useSelector((state) => state.authReducer);
  
  const [permissions, setPermissions] = useState("");
  const [isAccess, setIsAccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (partnerPermissions?.email) {
      setPermissions(partnerPermissions);
    }
  }, [partnerPermissions]);

  return (
    <div className="">
      <Loader loading={loading} />
      <div className={!isVisible ? "sideBarArea expandBar" : "sideBarArea"}>
        <Sidebar isVisible={isVisible} setIsVisible={setIsVisible} />
        <div className="dashContent container-fluid">
          <Header />
          {partnerPermissions?.email ? (
            <div className="prtnr_acs">
              <span className="usern">
                {" "}
                <img
                  alt="access"
                  className="mCS_img_loaded"
                  src={
                    partnerPermissions?.image
                      ? partnerPermissions?.image
                      : Images.user
                  }
                />
                <p>{partnerPermissions?.name || ""}</p>
              </span>
              <div
                onClick={() => {
                  setIsAccess(true);
                }}
                className="exitAccess"
              >
                Exit Access
                <i class="fa fa-times" aria-hidden="true"></i>
              </div>
            </div>
          ) : undefined}
          {props.children}
          <div className="dashFoot">{`Book Sculp ${moment().format(
            "YYYY"
          )} © All Rights Reserved.`}</div>
        </div>
      </div>
      {isAccess ? (
        <DeletePost
          setShow={setIsAccess}
          show={isAccess}
          title="Alert"
          handleConfirm={() => {
            setLoading(true)
            removeData(storageKey.ACCESS_BASED);
            removeData(storageKey.ACCESS_TOKEN);
            dispatch(partnerAccessPermissions(null));
            const queryParams = new URLSearchParams({
              type:
                permissions?.role === "model" ? "talent" : permissions?.role,
              tab: 1,
            });
            setLoading(false)
            navigate(`${routeName.LIST}?${queryParams.toString()}`, {
              replace: true,
            });
          }}
          text="Are you sure you want to exit permission Access?"
        />
      ) : undefined}
    </div>
  );
};

export default SidebarLayout;
