import { useContext } from "react";
import { AuthContext } from "../Context/AuthContext";
import {
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { routeName } from "./routeName";
import { useNavigate } from "react-router-dom";
import { db, auth } from "./firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { addFirebaseUid } from "../Redux/Services/OtherServices";
import { useDispatch } from "react-redux";
import { signOut } from "firebase/auth";

export const doubleEncodedData = (body) => {
  let data = JSON.stringify(body);
  let encodeData = btoa(data);
  let encoded = btoa(`sculp_${encodeData}`);
  return encoded;
};

// Custom hook to handle the message logic
export const useHandleMessage = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  
  const updateUserStatus = async (userId, status) => {
    if (userId) {
      try {
        await setDoc(
          doc(db, 'userStatus', userId),
          { status },
          { merge: true }
        );
      } catch (error) {
        console.error('Error updating status:', error.message);
      }
    }
  };

  const handleFirebaseLogin = async (
    password,
    email,
    uid,
    displayName,
    setLoading,
    photoURL
  ) => {
    try {
      setLoading(true)
      await signInWithEmailAndPassword(auth, currentUser?.email, password);
      await createChatCollection(email, uid, displayName, setLoading, photoURL);
    } catch (err) {
      setLoading(false)
    }
  };

  const handleFirebaseRegister = async (
    password,
    email,
    displayName,
    setLoading,
    photoURL,
    id
  ) => {
    setLoading(true);
    try {
      //Create user
      const res = await createUserWithEmailAndPassword(auth, email, password);

      await updateProfile(res.user, {
        displayName,
        photoURL: photoURL,
      });

      await setDoc(doc(db, "users", res.user.uid), {
        uid: res.user.uid,
        displayName: displayName,
        email,
        photoURL: photoURL,
      });

      //create empty user chats on firestore
      await setDoc(doc(db, "userChats", res.user.uid), {});

      let body = {
        user_id: id || "818",
        chat_udi: res.user.uid,
      };

      let response = await dispatch(addFirebaseUid(body));

      if (response?.status === 200) {
        await updateUserStatus(res.user.uid, "offline")
      await signOut(auth);
      // await createChatCollection(email, res.user.uid, displayName, setLoading, photoURL);
     await handleFirebaseLogin(
        "AIzaSyC7DgkbTD-uT_KenPEeQvkJmxLMxJhsZdY",
        email,
        res.user.uid,
        displayName,
        setLoading,
        photoURL
      );
      }
    } catch (err) {
      setLoading(false)
    }
  };

  const createChatCollection = async (
    email,
    uid,
    displayName,
    setLoading,
    photoURL
  ) => {
    const combinedId =
      currentUser.uid > uid ? currentUser.uid + uid : uid + currentUser.uid;

    try {
      setLoading(true);
      const res = await getDoc(doc(db, "chats", combinedId));

      if (!res.exists()) {
        // Create a chat in the chats collection
        await setDoc(doc(db, "chats", combinedId), { messages: [] });

        // Create user chats
        await updateDoc(doc(db, "userChats", currentUser.uid), {
          [combinedId + ".userInfo"]: {
            uid: uid,
            displayName: displayName,
            email: email,
            photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        await updateDoc(doc(db, "userChats", uid), {
          [combinedId + ".userInfo"]: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            email: currentUser.email,
            photoURL: currentUser.photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        setLoading(false);
        const queryParams = new URLSearchParams({
          displayName: displayName || "",
          uid: uid || "",
          email: email || "",
          photoURL: photoURL || "",
        });
        navigate(`${routeName.CHAT}?${queryParams.toString()}`);
      } else {
        setLoading(false);
        await updateDoc(doc(db, "userChats", currentUser?.uid), {
          [combinedId + ".userInfo"]: {
            uid: uid,
            displayName: displayName,
            email: email,
            photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        await updateDoc(doc(db, "userChats", uid), {
          [combinedId + ".userInfo"]: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            email: currentUser.email,
            photoURL: currentUser.photoURL,
          },
          [combinedId + ".date"]: serverTimestamp(),
        });

        const queryParams = new URLSearchParams({
          displayName: displayName || "",
          uid: uid || "",
          email: email || "",
          photoURL: photoURL || "",
        });
        navigate(`${routeName.CHAT}?${queryParams.toString()}`);
      }
    } catch (err) {
      setLoading(false);
      console.error("Error handling message:", err);
    }
  };

  const handleMessage = async (
    email,
    uid,
    displayName,
    setLoading,
    photoURL,
    id
  ) => {
    if (email) {
      if (!uid) {
        await signOut(auth);
        await handleFirebaseRegister(
          "AIzaSyC7DgkbTD-uT_KenPEeQvkJmxLMxJhsZdY",
          email,
          displayName,
          setLoading,
          photoURL,
          id
        );
      } else {
        await createChatCollection(
          email,
          uid,
          displayName,
          setLoading,
          photoURL
        );
      }
    }
  };
  return handleMessage;
};
