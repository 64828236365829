import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routeName, showToast } from "../../Utility";
import { useDispatch, useSelector } from "react-redux";
import SidebarLayout from "../../layout/SidebarLayout";
import { Container, Tab, Tabs } from "react-bootstrap";
import {
  addPartnerAccessApi,
  deletePartnerFromListApi,
  getPartnerListApi,
} from "../../Redux/Services/PartnerServices";
import { DeletePost, Loader } from "../../Components";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { SitePagination } from "../../Components/filtersComponents";
import { SendMailModal, ViewProjects } from "../../Components/Popups";
import SearchBar from "../../Components/SearchBar";
import { isValidInput } from "../../Utility/validations";
import { partnerAccessPermissions } from "../../Redux/Actions/PartnerActions";
import { getData, storageKey, storeData } from "../../Utility/Storage";
import { userLoginRedirect } from "../../Redux/Services/AuthServices";
import { selectedTabAction } from "../../Redux/Actions/AdminActions";
import { addStripeAccount } from "../../Redux/Services/OtherServices";

const Listing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(location?.search);
  const type = queryParams?.get("type");
  const tabs = queryParams?.get("tab");
  const partnerId = queryParams?.get("partnerId");

  const [tab, setTab] = useState(tabs || 1);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [sendMailModal, setSendMailModal] = useState(false);
  const [isProjectModal, setIsProjectModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isViewAccess, setIsViewAccess] = useState(false);
  const [bankDetails, setBankDetails] = useState("");

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const tabSelected = useSelector((state) => state.authReducer.tabSelected);

  let totalPages = Math.ceil(totalCount / 10);

  // console.log(selectedRows);

  const onPageChange = (newPage) => {
    setPage(newPage);
    // handleGetFunctions(tab, newPage);
  };

  const options = [
    {
      name: "Registered",
      type: 1,
    },
    {
      name: "Pending",
      type: 2,
    },
  ];

  const getAllPartnerList = async () => {
    setLoading(true);
    let body = {
      user_id: partnerId || loggedInUser?.user_data?.profile_id,
      user_type: type || "talent",
      action: Number(tab) === 1 ? "registered" : "unregistered",
      search: debouncedSearchTerm,
      page_number: page,
      per_page: "10",
    };
    let res = await dispatch(getPartnerListApi(body));
    setLoading(false);
    if (res?.status == 200) {
      setList(res?.results?.user_data);
      setTotalCount(res?.results?.pagination?.total_users || 0);
    } else {
      setList([]);
    }
  };

  const handleDeletePartnerData = async () => {
    let body = {
      email_id: selectedId?.ID,
      partner_id: selectedId?.partner_id,
    };
    setLoading(true);

    let res = await dispatch(deletePartnerFromListApi(body));
    setLoading(false);
    if (res?.status == 200) {
      getAllPartnerList();
    } else {
    }
  };

  const handleGetPermissions = async () => {
    try {
      let body = {
        user_id: selectedId?.email_user_id,
        action: "get",
      };
      setLoading(true);
      var body2 = {
        user_id: selectedId?.email_user_id || "",
        profile_id: selectedId?.email_profile_id || "",
      };
      let resp = await dispatch(userLoginRedirect(body2));
      if (resp?.status === 200) {
        const res = await dispatch(addPartnerAccessApi(body));
        storeData(storageKey?.ACCESS_TOKEN, resp?.results?.access_token);
        if (res?.status === 200) {
          const permissions = res?.results;
          permissions.user_id = selectedId?.email_user_id;
          permissions.name = selectedId?.name || "";
          permissions.email = selectedId?.user_email || "";
          permissions.role = type === "talent" ? "model" : type;
          permissions.image = selectedId?.profile_img?.url || "";
          permissions.profile_id = selectedId?.email_profile_id || "";
          storeData(storageKey.ACCESS_BASED, JSON.stringify(permissions));
          dispatch(partnerAccessPermissions(permissions));
          setIsViewAccess(false);
          navigate(routeName.DASHBOARD, { replace: true });
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getBankAccDetails = async () => {
    try {
      setLoading(true);
      let userID = await getData(storageKey?.USER_ID);
      if (userID) {
        let body = {
          user_id: userID,
          action: "get_react",
        };
        const res = await dispatch(addStripeAccount(body));
        setLoading(false);
        if (res?.status === 200) {
          setBankDetails(res?.results);
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (loggedInUser) {
      getAllPartnerList();
    }
  }, [tab, debouncedSearchTerm, page, loggedInUser]);

  const handleRowChange = (event, item) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, item]);
    } else {
      setSelectedRows(selectedRows.filter((n) => n !== item));
    }
  };

  useEffect(() => {
    getBankAccDetails();
    if (tabSelected) {
      setTab(tabSelected);
    }
  }, []);

  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <div>
        <Container>
          <div className="list_cnnt">
            <h3>{`MANAGE ${
              type
                ? type === "talent"
                  ? "MODEL"
                  : type?.toUpperCase()
                : "MODEL"
            }`}</h3>

            <a
              class="wt-btn"
              onClick={() => {
                if (!bankDetails?.user_stripe_bank_details) {
                  showToast("Please add your bank details first", "error");
                  navigate(routeName.PAYOUT)
                  return;
                }
                const queryParams = new URLSearchParams({
                  type,
                  tab,
                  partnerId: partnerId ? partnerId : "",
                });
                navigate(`${routeName.EXCEL_UPLOAD}?${queryParams.toString()}`);
              }}
            >
              {` Add ${type ? (type === "talent" ? "Model" : type) : "Model"}`}
            </a>
          </div>
        </Container>
        <div className="dashboard_main">
          <div className="myJobs">
            <Tabs
              defaultActiveKey="posted"
              id="list"
              activeKey={tab}
              onSelect={(type) => {
                setTab(type);
                setSelectedRows([]);
              }}
            >
              {options?.map((item, index) => (
                <Tab key={index} eventKey={item.type} title={item.name}>
                  <div class="wt-managejobcontent">
                    <div className="cards">
                      <div className="cards_header">
                        {list?.length ? (
                          <a
                            className="wt-btn"
                            onClick={() => {
                              if (selectedRows?.length) {
                                setSendMailModal(true);
                              } else {
                                showToast(
                                  "Select list of users first",
                                  "error"
                                );
                              }
                            }}
                          >
                            Send Mail
                          </a>
                        ) : (
                          <div />
                        )}
                        <SearchBar
                          searchTerm={searchTerm}
                          setDebouncedSearchTerm={setDebouncedSearchTerm}
                          value={searchTerm}
                          onCross={() => setSearchTerm("")}
                          onChange={(val) => {
                            if (isValidInput(val.target.value)) {
                              setSearchTerm(val.target.value);
                            }
                          }}
                        />
                      </div>
                      <TableContainer className="table_container">
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      onChange={(event) => {
                                        if (event.target.checked) {
                                          setSelectedRows(
                                            list.filter((item) => item)
                                          );
                                        } else {
                                          setSelectedRows([]);
                                        }
                                      }}
                                      checked={
                                        list?.length > 0 &&
                                        selectedRows.length === list?.length
                                      }
                                      inputProps={{
                                        "aria-label": "select all checkbox",
                                      }}
                                    />
                                  }
                                  label=""
                                />
                              </TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell>Phone no</TableCell>
                              <TableCell>Email</TableCell>
                              <TableCell align="center">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          {list?.length ? (
                            list?.map((item, i) => {
                              return (
                                <TableBody>
                                  <TableRow>
                                    <TableCell align="center">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            onChange={(event) =>
                                              handleRowChange(event, item)
                                            }
                                            checked={selectedRows.includes(
                                              item
                                            )}
                                            inputProps={{
                                              "aria-label": "row checkbox",
                                            }}
                                          />
                                        }
                                      />
                                    </TableCell>
                                    <TableCell
                                      onClick={() => {
                                        dispatch(selectedTabAction(tab));
                                        if (
                                          Number(tab) === 1 &&
                                          type !== "client"
                                        ) {
                                          const queryParams =
                                            new URLSearchParams({
                                              id: item?.email_user_id,
                                            });
                                          navigate(
                                            `${
                                              routeName.TALENT_PROFILE
                                            }?${queryParams.toString()}`
                                          );
                                        } else {
                                          const queryParams =
                                            new URLSearchParams({
                                              id: item?.email_user_id,
                                            });
                                          navigate(
                                            `${
                                              routeName.CLIENT_PROFILE
                                            }?${queryParams.toString()}`
                                          );
                                        }
                                      }}
                                    >
                                      {`${item?.name || "-"}` || ""}
                                    </TableCell>
                                    <TableCell>{item?.phone || "-"}</TableCell>
                                    <TableCell>
                                      {item?.user_email || ""}
                                    </TableCell>

                                    <TableCell align="center">
                                      <div className="table_actions">
                                        <IconButton
                                          onClick={() => {
                                            setSelectedId(item);
                                            setSendMailModal(true);
                                          }}
                                        >
                                          <div>
                                            <i
                                              class="fa fa-envelope"
                                              aria-hidden="true"
                                              color="#00000"
                                            ></i>
                                            <p>Email</p>
                                          </div>
                                        </IconButton>
                                        <IconButton
                                          onClick={() => {
                                            setSelectedId(item);
                                            setIsDeleteModal(true);
                                          }}
                                        >
                                          <div>
                                            <i
                                              class="fa fa-trash-o"
                                              aria-hidden="true"
                                              color="#00000"
                                            ></i>
                                            <p>Delete</p>
                                          </div>
                                        </IconButton>
                                        {Number(tab) === 2 ? undefined : (
                                          <IconButton
                                            onClick={() => {
                                              setSelectedId(item);
                                              setIsViewAccess(true);
                                            }}
                                          >
                                            <div>
                                              <i
                                                class="fas fa-business-time"
                                                aria-hidden="true"
                                                color="#00000"
                                              ></i>
                                              <p>Dashboard</p>
                                            </div>
                                          </IconButton>
                                        )}
                                      </div>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              );
                            })
                          ) : (
                            <TableBody>
                              <TableCell
                                align="center"
                                colSpan={10}
                                sx={{ color: "black" }}
                              >
                                {`No ${type || "model"} Found`}
                              </TableCell>
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      <div className="pg_vew">
                        {totalPages > 1 ? (
                          <SitePagination
                            module={list}
                            page={page}
                            onPageChange={onPageChange}
                            totalPages={totalPages}
                            setPage={setPage}
                          />
                        ) : undefined}
                      </div>
                    </div>
                  </div>
                </Tab>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
      {isDeleteModal ? (
        <DeletePost
          setShow={setIsDeleteModal}
          show={isDeleteModal}
          title="Alert"
          handleConfirm={handleDeletePartnerData}
          text={`Are you sure you want to delete this ${
            type === "talent" ? "model" : type
          }?`}
        />
      ) : undefined}
      {isViewAccess ? (
        <DeletePost
          setShow={setIsViewAccess}
          show={isViewAccess}
          title="Alert"
          handleConfirm={handleGetPermissions}
          text={`Are you sure you want to access of this profile?`}
        />
      ) : undefined}
      <SendMailModal
        setVisible={setSendMailModal}
        visible={sendMailModal}
        selectedItem={selectedId}
        // getAllJobsList={get}
        setTab={setTab}
        setLoading={setLoading}
        tab={tab}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        partnerId={partnerId}
      />
      <ViewProjects show={isProjectModal} setShow={setIsProjectModal} />
    </SidebarLayout>
  );
};

export default Listing;
