import React, { useEffect } from "react";
import { useState } from "react";
import { getCountryList } from "../../../Redux/Services/OtherServices";
import { useDispatch, useSelector } from "react-redux";
import SidebarLayout from "../../../layout/SidebarLayout";
import { Form } from "react-bootstrap";
import { SingleFields, SizeDetails, YourBasic } from "../../../features/admin";
import {
  ActorAudition,
  SocialMediaHandle,
  UserImages,
} from "../../../features/profile";
import { InputField, Loader, MultiSelectDropdown } from "../../../Components";
import {
  removeImage,
  uploadGalleryProfile,
} from "../../../Redux/Services/AuthServices";
import { routeName, showToast } from "../../../Utility";
import {
  addActorByAdmin,
  addModelByIdApi,
  getUsersDataById,
  photographerRegisterAdmin,
  updateActoryAdminApi,
  updateModelByIdApi,
  updatePhotographerAdmin,
} from "../../../Redux/Services/AdminServices";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

const AddEditUser = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location?.search);
  const id = queryParams?.get("id");
  const type = queryParams?.get("type");

  const [talent, setTalent] = useState(type === "photo" ? "Photographer" : "");
  const [gender, setGender] = useState("");
  const [values, setValues] = useState({
    dob: "",
    firstName: "",
    lastName: "",
    displayName: "",
    phoneNo: "",
    email: "",
    password: "",
    hourlyPrice: "",
    guardianName: "",
    tagline: "",
    addressLine1: "",
    addressLine2: "",
    postalCode: "",
    professionalBio: "",
    personalBio: "",
    yearsOfExp: "",
    customExpertise: "",
  });
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [locationList, setLocationList] = useState({
    countryList: "",
    stateList: "",
    cityList: "",
  });
  const [selectedLang, setSelectedLang] = useState([]);
  const [engLang, setEngLang] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [ethinicity, setEthinicity] = useState([]);
  const [hairColor, setHairColor] = useState("");
  const [eyeColor, setEyeColor] = useState("");
  const [experienceLevel, setExperienceLevel] = useState("");
  const [socialLinks, setSocialLinks] = useState({
    website: "",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    vimeo: "",
    tikTok: "",
  });

  const [tiktokFollowers, setTiktokFollowers] = useState("");
  const [vimeoFollowers, setVimeoFollowers] = useState("");
  const [instagramFollowers, setInstagramFollowers] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [reelLink, setReelLink] = useState("");
  const [twitterFollowers, setTwitterFollowers] = useState("");
  const [facebookFollowers, setFacebookFollowers] = useState("");
  const [youtubeFollowers, setYoutubeFollowers] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [customSkill, setCustomSkill] = useState("");
  const [pantSize, setPantSize] = useState("");
  const [pantSizeW, setPantSizeW] = useState("");
  const [pantSizeL, setPantSizeL] = useState("");
  const [shirtSize, setShirtSize] = useState("");
  const [shirtSizeF, setShirtSizeF] = useState("");
  const [dressShirt, setDressShirt] = useState("");
  const [shirtSleeve, setShirtSleeve] = useState("");
  const [neckSize, setNeckSize] = useState("");
  const [chestSize, setChestSize] = useState("");
  const [jacket, setJacket] = useState("");
  const [braCup, setBraCup] = useState("");
  const [braSize, setBraSize] = useState("");
  const [dressSize, setDressSize] = useState("");
  const [shoeSize, setShoeSize] = useState("");
  const [shirtSizeC, setShirtSizeC] = useState("");
  const [pantSizeWC, setPantSizeWC] = useState("");
  const [dressSizeC, setDressSizeC] = useState("");
  const [shoeSizeF, setShoeSizeF] = useState("");
  const [shoeSizeC, setShoeSizeC] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [waistImageData, setWaistImageData] = useState(null);
  const [cropped, setCropped] = useState(false);
  const [galleryCropper, setGalleryCropper] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [waistImage, setWaistImage] = useState("");
  const [shoulderUpImage, setShoulderUpImage] = useState("");
  const [shoulderUpImageData, setShoulderUpImageData] = useState("");
  const [happy, setHappy] = useState("");
  const [happyData, setHappyData] = useState("");
  const [sad, setSad] = useState("");
  const [sadData, setSadData] = useState("");
  const [angry, setangry] = useState("");
  const [AngryData, setAngryData] = useState("");
  const [shoulderDownImage, setShoulderDownImage] = useState("");
  const [shoulderDownImageData, setShoulderDownImageData] = useState("");
  const [images, setImages] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [userResponse, setUserResponse] = useState("");
  const [editData, setEditData] = useState("");
  const [relationStatus, setRelationStatus] = useState("");
  const [childrenCount, setChildrenCount] = useState("");
  const [auditions, setAuditions] = useState({
    occupation: "",
    threeThings: "",
    showOn: "",
    realityShow: "",
  });
  const [expertise, setExpertise] = useState("");
  const [tattooLoc, setTattoLoc] = useState("");
  const [memberOf, setMemberOf] = useState("");
  const [educationTraining, setEducationTraining] = useState("");
  const [theaterExp, setTheaterExp] = useState("");
  const [commercialExp, setCommercialExp] = useState("");
  const [awards, setAwards] = useState("");

  // console.log(selectedLang,"jkdhgdjfk");

  const optionsType = useSelector((state) => state.authReducer.allOptionData);

  const handleSelectCountry = (e, type) => {
    const selectedValue = e.target.value;
    const selectedOptionObject = locationList?.countryList?.find(
      (option) =>
        option.value === selectedValue || option.name === selectedValue
    );
    getAllStateName(selectedOptionObject?.id);
    if (e.target.value == "") {
      setCountry("");
    } else {
      setCountry(e.target.value);
    }
  };

  const handleSelectState = (e, type) => {
    const selectedValue = e.target.value;
    const selectedOptionObject = locationList?.stateList?.find(
      (option) =>
        option.value === selectedValue || option.name === selectedValue
    );
    getAllCityName(selectedOptionObject?.id);
    if (e.target.value == "") {
      setState("");
    } else {
      setState(e.target.value);
    }
  };

  const handleSelectCity = (e, type) => {
    const selectedValue = e.target.value;
    const selectedOptionObject = locationList?.cityList?.find(
      (option) =>
        option.value === selectedValue || option.name === selectedValue
    );
    if (e.target.value == "") {
      setCity("");
    } else {
      setCity(e.target.value);
    }
  };

  const getAllCityName = async (id, countryId, countryData, stateData) => {
    const selectedOptionObject = countryId
      ? countryId
      : locationList?.countryList?.find(
          (option) => option.value === country || option.name === country
        );
    var body = {
      country: countryId ? countryId : selectedOptionObject?.id,
      state: id || "",
      city: "",
    };
    setLoading(true);
    let res = await dispatch(getCountryList(body));
    setLoading(false);
    if (res?.status == 200) {
      let data;
      if (res?.results?.length != 0) {
        data = JSON?.parse(res?.results);
        if (countryId) {
          setLocationList({
            ...locationList,
            countryList: countryData || [],
            stateList: stateData || [],
            cityList: data,
          });
        } else {
          setLocationList({
            ...locationList,
            cityList: data,
          });
        }
      } else {
        setLocationList({
          ...locationList,
          cityList: [],
        });
      }
    }
  };

  const getAllStateName = async (id, stateId, countryData) => {
    var body = {
      country: id || "",
      state: "",
      city: "",
    };
    setLoading(true);
    let res = await dispatch(getCountryList(body));
    setLoading(false);
    if (res?.status == 200) {
      let data;
      if (res?.results?.length != 0) {
        data = JSON?.parse(res?.results);
        setLocationList({
          ...locationList,
          stateList: data,
          cityList: [],
        });
        if (stateId) {
          getAllCityName(stateId, id, countryData, data);
        }
      } else {
        setLocationList({
          ...locationList,
          stateList: [],
          cityList: [],
        });
      }
    }
  };

  const getAllCountryName = async (countryID, stateId) => {
    var body = {
      country: "",
      state: "",
      city: "",
    };
    setLoading(true);
    let res = await dispatch(getCountryList(body));
    setLoading(false);
    if (res?.status == 200) {
      let data;
      data = res?.results;
      setLocationList({
        ...locationList,
        countryList: data,
      });
      if (countryID) {
        getAllStateName(countryID, stateId, data);
      }
    }
  };

  const handleChooseProfile = (event) => {
    const file = event.target.files[0];
    setSelectedFile(event.target.files[0]);
    if (file) {
      if (file && file.type.startsWith("image/")) {
        const detail = {
          uri: URL.createObjectURL(file),
          name: file.name,
          size: file.size,
          type: file?.type,
        };
        setSelectedFile(detail);
        setCropped(true);
      } else {
        showToast("Please select a valid email address", "error");
      }
    } else {
      setProfileImage(null);
    }
  };

  const handleChooseImages = (event, type) => {
    const file = event.target.files[0];
    if (type === "waist") {
      setWaistImage(event.target.files[0]);
    } else if (type === "shoulderUp") {
      setShoulderUpImage(event.target.files[0]);
    } else if (type === "shoulderDown") {
      setShoulderDownImage(event.target.files[0]);
    } else if (type === "happy") {
      setHappy(event.target.files[0]);
    } else if (type === "sad") {
      setSad(event.target.files[0]);
    } else if (type === "angry") {
      setangry(event.target.files[0]);
    }
    if (file) {
      if (file && file.type.startsWith("image/")) {
        const detail = {
          uri: URL.createObjectURL(file),
          name: file.name,
          size: file.size,
          type: file?.type,
        };
        if (type === "waist") {
          setWaistImage(detail);
        } else if (type === "shoulderUp") {
          setShoulderUpImage(detail);
        } else if (type === "shoulderDown") {
          setShoulderDownImage(detail);
        } else if (type === "happy") {
          setHappy(detail);
        } else if (type === "sad") {
          setSad(detail);
        } else if (type === "angry") {
          setangry(detail);
        }

        setCropped(true);
      } else {
        showToast("Please select a valid image", "error");
      }
    } else {
      if (type === "waist") {
        setWaistImageData(null);
      } else if (type === "shoulderUp") {
        setShoulderUpImageData(null);
      } else if (type === "shoulderDown") {
        setShoulderDownImageData(null);
      } else if (type === "happy") {
        setHappyData(null);
      } else if (type === "sad") {
        setSadData(null);
      } else if (type === "angry") {
        setAngryData(null);
      }
    }
  };

  const uploadProfilePhoto = async (data, type) => {
    let uploadData = new FormData();
    uploadData.append("upload_type", "profile");
    uploadData.append(
      "user_id",
      userResponse?.user_id || editData?.user_data?.user_id
    );
    uploadData.append(
      "profile_id",
      userResponse?.profile_id || editData?.user_data?.profile_id
    );
    uploadData.append("file_type", data);
    let res = await dispatch(uploadGalleryProfile(uploadData));
    if (res?.status == 200) {
      setCropped(false);
      setSelectedFile([]);
      setProfileImage({
        ...profileImage,
        uri: res?.results?.uri,
        name: res?.results.name,
        size: res?.results.size,
        type: res?.results?.type,
      });
    }
  };

  const uploadWaistphoto = async (data, type) => {
    let uploadData = new FormData();
    uploadData.append("upload_type", type);
    uploadData.append(
      "user_id",
      userResponse?.user_id || editData?.user_data?.user_id
    );
    uploadData.append(
      "profile_id",
      userResponse?.profile_id || editData?.user_data?.profile_id
    );
    uploadData.append("file_type", data);
    let res = await dispatch(uploadGalleryProfile(uploadData));
    if (res?.status == 200) {
      setCropped(false);

      if (type === "waist_up") {
        setWaistImage([]);
        setWaistImageData({
          ...waistImageData,
          uri: res?.results?.uri,
          name: res?.results.name,
          size: res?.results.size,
          type: res?.results?.type,
        });
      } else if (type === "shoulders_up_straight") {
        setShoulderUpImage([]);
        setShoulderUpImageData({
          ...shoulderUpImageData,
          uri: res?.results?.uri,
          name: res?.results.name,
          size: res?.results.size,
          type: res?.results?.type,
        });
      } else if (type === "shoulders_up_turn") {
        setShoulderDownImage([]);
        setShoulderDownImageData({
          ...shoulderDownImageData,
          uri: res?.results?.uri,
          name: res?.results.name,
          size: res?.results.size,
          type: res?.results?.type,
        });
      } else if (type === "happy") {
        setHappy([]);
        setHappyData({
          ...happyData,
          uri: res?.results?.uri,
          name: res?.results.name,
          size: res?.results.size,
          type: res?.results?.type,
        });
      } else if (type === "sad") {
        setSad([]);
        setSadData({
          ...sadData,
          uri: res?.results?.uri,
          name: res?.results.name,
          size: res?.results.size,
          type: res?.results?.type,
        });
      } else if (type === "angry") {
        setangry([]);
        setAngryData({
          ...AngryData,
          uri: res?.results?.uri,
          name: res?.results.name,
          size: res?.results.size,
          type: res?.results?.type,
        });
      }
    }
  };

  const handleChooseGallery = async (event) => {
    const files = event.target.files;
    const file = files?.[0];
    if (files?.length != 0) {
      if (file && file.type.startsWith("image/")) {
        const newDetails = Array.from(files).map((file) => ({
          uri: URL.createObjectURL(file),
          name: file.name,
          size: file.size,
          type: file?.type,
        }));

        let newArr = [...(images || []), ...newDetails];
        if (newArr?.length > 10) {
          alert(
            "You cannot upload another Image. You reached your limit, Choose Minimum 3 Images or Maximum 10 Images."
          );
        } else {
          setSelectedFiles((prevFileDetails) => [
            ...(prevFileDetails || []),
            ...newDetails,
          ]);
          setGalleryCropper(true);
        }
      } else {
        showToast("PLease select a valid image", "error");
      }
    }
  };

  const handleGallerySubmit = async (data) => {
    let uploadData = new FormData();

    uploadData.append("upload_type", "gallery");
    uploadData.append(
      "user_id",
      userResponse?.user_id || editData?.user_data?.user_id
    );
    uploadData.append(
      "profile_id",
      userResponse?.profile_id || editData?.user_data?.profile_id
    );
    Array.from(data).map((file, index) => {
      // console.log("filesizezezeez-----", file);

      uploadData.append("file_type" + "[" + [index] + "]", file);
    });
    let res = await dispatch(uploadGalleryProfile(uploadData));

    if (res?.status == 200) {
      setImages(res.results);
      setSelectedFiles([]);
    }
  };

  const removeGalleryImage = async (eachImage, type) => {
    var body = {
      user_id: userResponse?.user_id || editData?.user_data?.user_id,
      profile_id: userResponse?.profile_id || editData?.user_data?.profile_id,
      image_type: type,
      image_id:
        type == "gallery"
          ? eachImage?.attachment_id
          : type === "waist_up"
          ? waistImageData?.attachment_id
          : type === "shoulders_up_straight"
          ? shoulderUpImageData?.attachment_id
          : type === "shoulders_up_turn"
          ? shoulderDownImageData?.attachment_id
          : type === "happy"
          ? happyData?.attachment_id
          : type === "sad"
          ? sadData?.attachment_id
          : type === "angry"
          ? AngryData?.attachment_id
          : profileImage?.attachment_id || profileImage?.ID,
      action: "remove",
    };
    let res = await dispatch(removeImage(body));

    if (type == "profile") {
      setProfileImage("");
    } else if (type === "waist_up") {
      setWaistImageData("");
    } else if (type === "shoulders_up_straight") {
      setShoulderUpImageData("");
    } else if (type === "shoulders_up_turn") {
      setShoulderDownImageData("");
    } else if (type === "happy") {
      setHappyData("");
    } else if (type === "sad") {
      setSadData("");
    } else if (type === "angry") {
      setAngryData("");
    } else if (type === "gallery") {
      // console.log(images, eachImage,"imageeeremoceee");
      let imgs = images?.filter((image) => image.url !== eachImage?.url);
      setImages(imgs);
    }
  };

  const handleNavigate = () => {
    const queryParams = new URLSearchParams({
      type: type,
    });
    if (type === "photo") {
      navigate(`${routeName.PHOTO_LIST}?${queryParams.toString()}`, {
        replace: true,
      });
    } else if (type === "actor") {
      navigate(`${routeName.ACTOR_LIST}?${queryParams.toString()}`, {
        replace: true,
      });
    } else {
      navigate(`${routeName.MODEL_LIST}?${queryParams.toString()}`, {
        replace: true,
      });
    }
  };

  const handleForm = async (event) => {
    setError(true);
    event.preventDefault();

    if (!talent) {
      showToast("Talent type is required", "error");
      return;
    }

    if (!values?.dob) {
      showToast("Date Of Birth is required", "error");
      return;
    }
    if (!values?.dob) {
      showToast("Date Of Birth is required", "error");
      return;
    }
    if (!gender) {
      showToast("Gender is required", "error");
      return;
    }
    if (!values?.firstName) {
      showToast("First Name is required", "error");
      return;
    }

    if (!values?.lastName) {
      showToast("Last Name is required", "error");
      return;
    }
    if (!values?.displayName) {
      showToast("Display Name is required", "error");
      return;
    }
    if (!values?.phoneNo) {
      showToast("Phone number is required", "error");
      return;
    }
    if (!values?.email) {
      showToast("Email is required", "error");
      return;
    }
    if (!values?.password && !id) {
      showToast("Password is required", "error");
      return;
    }
    if (
      (talent === "Model Kid" || talent === "Actor Kid") &&
      !values.guardianName
    ) {
      showToast("Guardian Name is required", "error");
      return;
    }
    if (!country) {
      showToast("Country is required", "error");
      return;
    }
    if (locationList?.stateList?.length && !state) {
      showToast("State is required", "error");
      return;
    }
    if (locationList?.cityList?.length && !city) {
      showToast("City is required", "error");
      return;
    }
    if (!values?.addressLine1) {
      showToast("Address is required", "error");
      return;
    }
    if (!values?.postalCode) {
      showToast("Postal Code is required", "error");
      return;
    }
    if (type === "photo" && !values?.yearsOfExp) {
      showToast("Years of experience is required", "error");
      return;
    }
    if (!selectedLang?.length && talent !== "Photographer") {
      showToast("Languages are required", "error");
      return;
    }
    if (!engLang && talent !== "Photographer") {
      showToast("Language Level are required", "error");
      return;
    }
    if (!values.professionalBio) {
      showToast("Professional Bio is required", "error");
      return;
    }
    if (!values.personalBio) {
      showToast("Personal Bio is required", "error");
      return;
    }
    if (!height && type !== "photo") {
      showToast("Height is required", "error");
      return;
    }
    if (!weight && type !== "photo") {
      showToast("Weight is required", "error");
      return;
    }
    if (!expertise?.length && type === "Photographer") {
      showToast("Expertise are required", "error");
      return;
    }
    if (!ethinicity?.length && type !== "photo") {
      showToast("Ethinicity is required", "error");
      return;
    }
    if (!hairColor && type !== "photo") {
      showToast("Hair Color is required", "error");
      return;
    }
    if (!eyeColor && type !== "photo") {
      showToast("Eye Color is required", "error");
      return;
    }
    if (!experienceLevel) {
      showToast("Experience Level is required", "error");
      return;
    }
    // measurements
    if (talent === "Model Kid" || talent === "Actor Kid") {
      if (!shirtSizeC?.length) {
        showToast("Shirt Size is required", "error");
        return;
      }
      if (!pantSizeWC?.length) {
        showToast("Pant Size is required", "error");
        return;
      }
      if (!shoeSizeC?.length) {
        showToast("Shoe Size is required", "error");
        return;
      }
      if (!dressSizeC?.length && (gender == "Female" || gender == "female")) {
        showToast("Dress Size is required", "error");
        return;
      }
    }
    if (
      (talent === "Model" || talent === "Actor") &&
      (gender === "female" || gender === "Female")
    ) {
      if (!shirtSizeF?.length) {
        showToast("Shirt Size is required", "error");
        return;
      }
      if (!pantSize?.length) {
        showToast("Pant Size is required", "error");
        return;
      }
      if (!braCup) {
        showToast("Bra Cup is required", "error");
        return;
      }
      if (!braSize) {
        showToast("Bra Size is required", "error");
        return;
      }
      if (!dressSize) {
        showToast("Dress Size is required", "error");
        return;
      }
      if (!shoeSizeF?.length) {
        showToast("Shoe Size is required", "error");
        return;
      }
    }
    if (
      (talent === "Model" || talent === "Actor") &&
      (gender === "Male" || gender === "male")
    ) {
      if (!shirtSize?.length) {
        showToast("Shirt Size is required", "error");
        return;
      }
      if (!pantSizeW?.length) {
        showToast("Pant Size (Waist) is required", "error");
        return;
      }
      if (!pantSizeL?.length) {
        showToast("Pant Size (Length) is required", "error");
        return;
      }
      if (!shoeSize?.length) {
        showToast("Shoe Size is required", "error");
        return;
      }
      if (!dressShirt) {
        showToast("Dress Shirt Size is required", "error");
        return;
      }
      if (!shirtSleeve) {
        showToast("Dress Shirt Sleeve is required", "error");
        return;
      }
      if (!neckSize) {
        showToast("Neck Size is required", "error");
        return;
      }
      if (!chestSize) {
        showToast("Chest Sleeve is required", "error");
        return;
      }
      if (!jacket) {
        showToast("Jacket Size is required", "error");
        return;
      }
    }
    if (!selectedSkills?.length) {
      showToast("Skills are required", "error");
      return;
    }

    const form = event.currentTarget;

    if (form.checkValidity()) {
      let body = {
        user_type:
          type === "photo"
            ? "photographer"
            : talent?.includes("Model")
            ? "freelancer"
            : "actor",
        per_website: socialLinks?.website || "",
        email: values.email,
        password: values.password,
        expertise: expertise,
        custom_expertise: values?.customExpertise,
        address_line: values.addressLine1,
        address_line1: values.addressLine1,
        address_line2: values.addressLine2,
        avatar:
          "https://booksculp.com/dev/wp-content/uploads/workreap-temp/00002-2.jpg",
        bra_cup: braCup,
        bra_size: braSize,
        chest_size: chestSize,
        children_dress_size: dressSizeC,
        children_pant_size: pantSizeWC,
        children_shirt_size: shirtSizeC,
        children_shoe_size: shoeSizeC,
        children_shirt_size_fe: shirtSizeC,
        children_pant_size_fe: pantSizeWC,
        children_shoe_size_fe: shoeSizeC,
        children_dress_size_fe: dressSizeC,
        children_bra_size: "",
        children_bra_cup: "",
        toddler_shirt_size: shirtSizeC,
        toddler_pant_size: pantSizeWC,
        toddler_shoe_size: shoeSizeC,

        toddler_shirt_size_fe: shirtSizeC,
        toddler_pant_size_fe: pantSizeWC,
        toddler_shoe_size_fe: shoeSizeC,
        toddler_dress_size_fe: dressSizeC,
        infant_shirt_size: shirtSizeC,
        infant_pant_size: pantSizeWC,
        infant_shoe_size: shoeSizeC,

        infant_shirt_size_fe: shirtSizeC,
        infant_pant_size_fe: pantSizeWC,
        infant_shoe_size_fe: shoeSizeC,
        infant_dress_size_fe: dressSizeC,
        city: city,
        country: country,
        custom_skills: customSkill,
        display_name: values.displayName,
        dob: moment(values.dob).format("YYYY-MM-DD"),
        dress_shirt_size: dressShirt,
        dress_shirt_sleeve: shirtSleeve,
        dress_size_f: dressSize,
        english: engLang,
        ethnicity:
          type === "actor"
            ? ethinicity
            : ethinicity?.length
            ? ethinicity?.map(({ value }) => value)
            : [],
        experience_level: experienceLevel,
        experiences_level: experienceLevel,
        eye_color: eyeColor,
        facebook_followers: facebookFollowers,
        facebook_link: socialLinks.facebook,
        first_name: values.firstName,
        gardian_name: values.guardianName,
        gender:
          gender === "non binary" || gender === "Non Binary"
            ? "other"
            : gender?.toLowerCase(),
        hair_color: hairColor,
        height_inch: height,
        hourly_rate: values.hourlyPrice,
        instagram_followers: instagramFollowers,
        instagram_link: socialLinks.instagram,
        jacket: jacket,
        languages:
          selectedLang?.length && type === "actor"
            ? selectedLang?.map((item) => {
                return {
                  id: item?.value,
                  name: item?.label,
                };
              })
            : selectedLang?.map(({ label }) => label),
        last_name: values.lastName,
        mobile: values.phoneNo,
        model_type:
          talent === "Model Kid" || talent === "Actor Kid"
            ? "child"
            : talent === "Model"
            ? "model"
            : "actor",
        neck_size: neckSize,
        pant_size_f: pantSize,
        pant_size_length: pantSizeL,
        pant_size_waist: pantSizeW,
        personal_bio: values.personalBio,
        postal_code: values.postalCode,
        professional_bio: values.professionalBio,
        shirt_size: shirtSize,
        shirt_size_f: shirtSizeF,
        shoe_size: shoeSize,
        shoe_size_f: shoeSizeF,
        skills:
          type === "photo"
            ? selectedSkills
            : selectedSkills?.length
            ? selectedSkills?.map(({ value }) => value)
            : [],
        state: state,
        tiktok_followers: tiktokFollowers,
        tiktok_link: socialLinks?.tikTok,
        twitter_followers: twitterFollowers,
        twitter_link: socialLinks.twitter,
        vimeo_followers: vimeoFollowers,
        vimeo_link: socialLinks.vimeo,
        weight_pound: weight,
        phone_number: values?.phoneNo,
        mobile: values?.phoneNo,
        youtube_followers: youtubeFollowers,
        youtube_link: socialLinks.youtube,
        years_experience: values?.yearsOfExp,
      };

      if (type === "photo") {
        body.pre = id ? "update" : "new";
        body.tageline = values?.tagline;
        body.years_experience = values?.yearsOfExp;
      }

      if (talent === "Actor" || talent === "Actor Kid") {
        body.relationship_status = relationStatus || "";
        body.have_children = childrenCount || "";
        body.occupation = auditions?.occupation || "";
        body.about_you_nobody_knows = auditions?.threeThings || "";
        body.reality_show_yourself_why = auditions?.showOn || "";
        body.reality_show_what_when = auditions?.realityShow || "";
        body.tattoo_location = tattooLoc;
        body.member_of = memberOf;
        body.theater_exp = theaterExp;
        body.edu_training = educationTraining;
        body.comm_exp = commercialExp;
        body.video_link = videoLink;
        body.personal_website = socialLinks?.website;
        body.list_awards = awards;
      }
      if (id && (talent === "Actor" || talent === "Actor Kid")) {
        body.action = "update";
      }

      if (id) {
        body.user_id = id;
        body.post_id = editData?.user_data?.profile_id || "";
        body.profile_id = editData?.user_data?.profile_id || "";
      } else {
        body.password = values.password;
      }
      setLoading(true);
      try {
        let res = "";
        if (id) {
          if (type === "photo") {
            res = await dispatch(updatePhotographerAdmin(body));
          } else if (type === "actor") {
            res = await dispatch(updateActoryAdminApi(body));
          } else {
            res = await dispatch(updateModelByIdApi(body));
          }
        } else {
          if (type === "photo") {
            res = await dispatch(photographerRegisterAdmin(body));
          } else if (type === "actor") {
            res = await dispatch(addActorByAdmin(body));
          } else {
            res = await dispatch(addModelByIdApi(body));
          }
        }
        setLoading(false);
        if (res?.status === 200) {
          setUserResponse(res?.results);
          if (id) {
            handleSubmit("update");
            // showToast("User Details Updated Successfully", "success");
            // handleNavigate()
          }
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const handleSubmit = async (val) => {
    let uploadData = new FormData();

    uploadData.append("upload_type", "next");
    uploadData.append(
      "user_id",
      userResponse?.user_id || editData?.user_data?.user_id
    );
    uploadData.append(
      "profile_id",
      userResponse?.profile_id || editData?.user_data?.profile_id
    );
    setLoading(true);
    let res = await dispatch(uploadGalleryProfile(uploadData));
    setLoading(false);
    if (res?.status === 200) {
      if (val) {
        showToast("User Details Updated Successfully", "success");
      } else {
        showToast("User Added Successfully", "success");
      }
      handleNavigate();
    }
  };

  useEffect(() => {
    if (!id) {
      getAllCountryName();
    }
  }, []);

  const getUserData = async () => {
    let body = {
      user_id: id,
    };
    // console.log(body, "klmmmm");
    let res = await dispatch(getUsersDataById(body));
    if (res?.status === 200) {
      setEditData(res?.results);
      let userData = res?.results?.user_data;
      let company_details = res?.results?.company_details;
      let post_meta_details = res?.results?.post_meta_details;
      let measurment_male = res?.results?.measurment_male;
      let social_followers = res?.results?.social_followers;
      let measurment = res?.results?.measurment;
      setValues({
        ...values,
        dob:
          post_meta_details?.dob ||
          res?.results?.personal_details?.date_of_birth ||
          new Date() ||
          "",
        firstName: userData?.first_name || "",
        lastName: userData?.last_name || "",
        displayName: userData?.display_name || "",
        phoneNo: res?.results?.fw_option?.[0]?.user_phone_number || "",
        email: userData?.user_email || "",
        hourlyPrice:
          post_meta_details?.perhour_rate ||
          res?.results?.personal_details?.perhour_rate ||
          "",
        guardianName: res?.results?.guardian_detail?.guardian_name || "",
        tagline: res?.results?.personal_details?.tag_line
          ? res?.results?.personal_details?.tag_line
          : post_meta_details?.tag_line,
        addressLine1:
          post_meta_details?.address_line || post_meta_details?.address || "",
        addressLine2: post_meta_details?.address_line_2 || "",
        postalCode: post_meta_details?.postal_code || "",
        professionalBio:
          post_meta_details?.professional_bio ||
          res?.results?.personal_details?.professional_bio ||
          "",
        personalBio:
          post_meta_details?.personal_bio ||
          res?.results?.personal_details?.personal_bio ||
          "",
        yearsOfExp: res?.results?.personal_details?.years_experience || "",
        customExpertise: res?.results?.personal_details?.custom_expertise,
      });
      setSocialLinks({
        ...socialLinks,
        facebook: social_followers?.facebook_profile_link,
        instagram: social_followers?.instagram_profile_link,
        tikTok: social_followers?.tiktok_profile_link
          ? social_followers?.tiktok_profile_link
          : social_followers?.ticktok_profile_link,
        youtube: social_followers?.youtube_profile_link,
        twitter: social_followers?.twitter_profile_link,
        vimeo: social_followers?.vimeo_profile_link,
        website:
          post_meta_details?.personal_website ||
          res?.results?.personal_details?.per_website,
      });
      setFacebookFollowers(social_followers?.facebook_follower);
      setInstagramFollowers(social_followers?.instagram_follower);
      setYoutubeFollowers(social_followers?.youtube_follower);
      setTwitterFollowers(social_followers?.twitter_follower);
      setTiktokFollowers(social_followers?.tiktok_follower);
      setVimeoFollowers(social_followers?.vimeo_follower);
      if (
        userData?.gender == "male" ||
        userData?.gender === "male" ||
        res?.results?.personal_details?.gender === "male"
      ) {
        setGender("Male");
      } else if (
        userData?.gender == "female" ||
        userData?.gender === "female" ||
        res?.results?.personal_details?.gender === "female"
      ) {
        setGender("Female");
      } else if (
        userData?.gender == "other" ||
        userData?.gender === "non binary" ||
        res?.results?.personal_details?.gender === "other"
      ) {
        setGender("Non Binary");
      }
      getAllCountryName(
        post_meta_details?.country_id,
        post_meta_details?.state_id
      );
      setEngLang(post_meta_details?.english_level);
      setEthinicity(post_meta_details?.ethnicity);
      setHairColor(post_meta_details?.hair_colour);
      setHeight(post_meta_details?.height);
      setWeight(post_meta_details?.weight);
      setNeckSize(measurment?.neck_size);
      setShoulderUpImageData(
        res?.results?.fw_option?.[0]?.shoulders_up_straight
          ? res?.results?.fw_option?.[0]?.shoulders_up_straight
          : ""
      );
      setExpertise(res?.results?.personal_details?.expertise);
      setEyeColor(post_meta_details?.eye_color);
      setExperienceLevel(
        post_meta_details?.experience_level ||
          res?.results?.personal_details?.experience_level
      );
      setSelectedLang(
        post_meta_details?.languages?.length
          ? post_meta_details?.languages?.map(({ name, id }) => ({
              label: name,
              value: id,
            }))
          : []
      );
      setTalent(
        post_meta_details?.user_type === "actor" &&
          post_meta_details?.freelancer_type === "child" &&
          userData?.user_role == 15
          ? "Actor Kid"
          : post_meta_details?.freelancer_type === "child" &&
            userData?.user_role == 11
          ? "Model Kid"
          : userData?.user_role == 11
          ? "Model"
          : userData?.user_role == 15 &&
            post_meta_details?.user_type === "actor"
          ? "Actor"
          : userData?.user_role == 14
          ? "Partners"
          : userData?.user_role == 12
          ? "Client"
          : userData?.user_role == 13
          ? "Photographer"
          : post_meta_details?.freelancer_type
          ? post_meta_details?.freelancer_type
          : ""
      );
      setAngryData(
        res?.results?.fw_option?.[0]?.angry
          ? res?.results?.fw_option?.[0]?.angry
          : ""
      );
      setHappyData(
        res?.results?.fw_option?.[0]?.happy
          ? res?.results?.fw_option?.[0]?.happy
          : ""
      );
      setCountry(post_meta_details?.country);
      setState(post_meta_details?.state);
      setCity(post_meta_details?.city);
      setSadData(
        res?.results?.fw_option?.[0]?.sad
          ? res?.results?.fw_option?.[0]?.sad
          : ""
      );
      setWaistImageData(
        res?.results?.fw_option?.[0]?.waist_up
          ? res?.results?.fw_option?.[0]?.waist_up
          : ""
      );
      setShoulderDownImageData(
        res?.results?.fw_option?.[0]?.shoulders_up_turn
          ? res?.results?.fw_option?.[0]?.shoulders_up_turn
          : ""
      );
      setSelectedSkills(post_meta_details?.skills_names);
      setCustomSkill(post_meta_details?.custom_skills);
      setImages(
        res?.results?.images_gallery &&
          res?.results?.images_gallery?.length != 0
          ? res?.results?.images_gallery
          : []
      );
      setProfileImage({
        ...profileImage,
        uri: res?.results?.profile_image?.[0]?.guid,
        attachment_id: res?.results?.profile_image?.[0]?.ID,
      });
      setRelationStatus(res?.results?.user_data?.relationship_status || "");
      setChildrenCount(post_meta_details?.have_children || "");
      setAuditions({
        occupation: post_meta_details?.occupation || "",
        threeThings: post_meta_details?.about_you_nobody_knows || "",
        showOn: post_meta_details?.reality_show_yourself_why || "",
        realityShow: post_meta_details?.reality_show_what_when || "",
      });
      setTattoLoc(post_meta_details?.tattoo_location || "");
      setTheaterExp(post_meta_details?.theater_exp || "");
      setEducationTraining(post_meta_details?.edu_training || "");
      setAwards(post_meta_details?.list_awards || "");
      setCommercialExp(post_meta_details?.comm_exp || "");
      setMemberOf(post_meta_details?.member_of || "");
      // setReelLink(social_followers?.link_to_reel || "");
      setVideoLink(post_meta_details?.video_link || "");
      if (measurment) {
        setShirtSize(
          measurment?.shirt_size
            ? measurment?.shirt_size
            : measurment?.shirt_size_m
        );
        setPantSizeW(measurment?.pant_size_waist);
        setPantSizeL(measurment?.pant_size_length);
        setJacket(measurment?.jacket);
        setChestSize(measurment?.chest_size);
        setDressShirt(measurment?.dress_shirt_size);
        setShirtSleeve(measurment?.dress_shirt_sleeve);
        setShoeSize(
          measurment?.shoe_size
            ? measurment?.shoe_size
            : measurment?.shoe_size_m
        );
        setShoeSizeF(
          measurment?.shoe_size_f
            ? measurment?.shoe_size_f
            : measurment?.shoe_size
        );
        setShirtSizeF(
          measurment?.shirt_size
            ? measurment?.shirt_size
            : measurment?.shirt_size_f
        );
        setBraCup(measurment?.bra_cup);
        setBraSize(measurment?.bra_size);
        setDressSize(
          measurment?.dress_size?.length
            ? measurment?.dress_size?.[0]?.label
            : measurment?.dress_size
        );
        setPantSize(
          measurment?.pant_size
            ? measurment?.pant_size
            : measurment?.pant_size_f
        );
        setShirtSizeC(measurment?.shirt_size);
        setPantSizeWC(measurment?.pant_size);
        setShoeSizeC(measurment?.shoe_size);
        setDressSizeC(measurment?.dress_size);
      }
    }
  };

  useEffect(() => {
    if (id) {
      getUserData();
    }
  }, [id]);

  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <div className="pd-30">
        <div className="verification_contain_form wt-email-cont">
          <div>
            <Form onSubmit={handleForm}>
              <div className="form-section">
                <div className="form-section-title">Your Basics</div>
                <YourBasic
                  gender={gender}
                  setGender={setGender}
                  submitted={submitted}
                  values={values}
                  setValues={setValues}
                  setTalent={setTalent}
                  talent={talent}
                  error={error}
                  country={country}
                  state={state}
                  city={city}
                  setCountry={setCountry}
                  setCity={setCity}
                  setState={setState}
                  setLocationList={setLocationList}
                  locationList={locationList}
                  handleSelectCity={handleSelectCity}
                  handleSelectCountry={handleSelectCountry}
                  handleSelectState={handleSelectState}
                  id={id}
                  type={type}
                  relationStatus={relationStatus}
                  setRelationStatus={setRelationStatus}
                />
              </div>
              <SingleFields
                error={error}
                selectedLang={selectedLang}
                setSelectedLang={setSelectedLang}
                engLang={engLang}
                setEngLang={setEngLang}
                values={values}
                setValues={setValues}
                height={height}
                weight={weight}
                setHeight={setHeight}
                setWeight={setWeight}
                ethinicity={ethinicity}
                setEthinicity={setEthinicity}
                hairColor={hairColor}
                setHairColor={setHairColor}
                eyeColor={eyeColor}
                setEyeColor={setEyeColor}
                experienceLevel={experienceLevel}
                setExperienceLevel={setExperienceLevel}
                tattooLoc={tattooLoc}
                setTattoLoc={setTattoLoc}
                memberOf={memberOf}
                setMemberOf={setMemberOf}
                educationTraining={educationTraining}
                setEducationTraining={setEducationTraining}
                theaterExp={theaterExp}
                setTheaterExp={setTheaterExp}
                awards={awards}
                setAwards={setAwards}
                talent={talent}
                commercialExp={commercialExp}
                setCommercialExp={setCommercialExp}
                setExpertise={setExpertise}
                expertise={expertise}
              />
              <SizeDetails
                error={error}
                talent={talent}
                gender={gender}
                shirtSizeC={shirtSizeC}
                setShirtSizeC={setShirtSizeC}
                pantSizeWC={pantSizeWC}
                setPantSizeWC={setPantSizeWC}
                shoeSizeC={shoeSizeC}
                setShoeSizeC={setShoeSizeC}
                dressSizeC={dressSizeC}
                setDressSizeC={setDressSizeC}
                shirtSize={shirtSize}
                setShirtSize={setShirtSize}
                pantSizeW={pantSizeW}
                setPantSizeW={setPantSizeW}
                pantSizeL={pantSizeL}
                setPantSizeL={setPantSizeL}
                setShoeSize={setShoeSize}
                shoeSize={shoeSize}
                dressShirt={dressShirt}
                setDressShirt={setDressShirt}
                shirtSleeve={shirtSleeve}
                setShirtSleeve={setShirtSleeve}
                neckSize={neckSize}
                setNeckSize={setNeckSize}
                chestSize={chestSize}
                setChestSize={setChestSize}
                jacket={jacket}
                setJacket={setJacket}
                shirtSizeF={shirtSizeF}
                setShirtSizeF={setShirtSizeF}
                pantSize={pantSize}
                setPantSize={setPantSize}
                braCup={braCup}
                setBraCup={setBraCup}
                braSize={braSize}
                setBraSize={setBraSize}
                dressSize={dressSize}
                setDressSize={setDressSize}
                shoeSizeF={shoeSizeF}
                setShoeSizeF={setShoeSizeF}
              />
              <ActorAudition
                talent={talent}
                auditions={auditions}
                setAuditions={setAuditions}
                childrenCount={childrenCount}
                setChildrenCount={setChildrenCount}
              />
              <SocialMediaHandle
                socialLinks={socialLinks}
                setSocialLinks={setSocialLinks}
                talent={talent}
                setFacebookFollowers={setFacebookFollowers}
                facebookFollowers={facebookFollowers}
                setVideoLink={setVideoLink}
                videoLink={videoLink}
                setInstagramFollowers={setInstagramFollowers}
                instagramFollowers={instagramFollowers}
                setTiktokFollowers={setTiktokFollowers}
                tiktokFollowers={tiktokFollowers}
                vimeoFollowers={vimeoFollowers}
                setVimeoFollowers={setVimeoFollowers}
                twitterFollowers={twitterFollowers}
                setTwitterFollowers={setTwitterFollowers}
                youtubeFollowers={youtubeFollowers}
                setYoutubeFollowers={setYoutubeFollowers}
                reelLink={reelLink}
                setReelLink={setReelLink}
              />
              <div className="form-section">
                <div className="form-section-title">My Skills</div>
                <div class="form-group form-group-50">
                  <MultiSelectDropdown
                    required
                    title="Select Skills"
                    options={
                      optionsType?.otherOptions?.add_new_skill?.field_meta
                        ?.choices
                    }
                    value={selectedSkills}
                    setValue={setSelectedSkills}
                    isValid={error && !selectedSkills?.length}
                  />
                </div>
              </div>
              <div className="form-section">
                <div className="form-section-title">Add custom skills</div>
                <div class="form-group form-group-50">
                  <InputField
                    // required
                    title="Custom Skills"
                    type="textArea"
                    placeholder="Add Custom Skills. eg. Baseball, Football..."
                    value={customSkill}
                    onChangeText={(e) => {
                      setCustomSkill(e?.target?.value);
                    }}
                  />
                </div>
              </div>

              {id || userResponse ? undefined : (
                <button
                  onClick={handleForm}
                  class="wt-btn hide_org"
                  type="submit"
                >
                  Save
                </button>
              )}
            </Form>
            {userResponse || editData ? (
              <div>
                <UserImages
                  handleChooseProfile={handleChooseProfile}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  cropped={cropped}
                  setCropped={setCropped}
                  uploadProfilePhoto={uploadProfilePhoto}
                  profileImage={profileImage}
                  setProfileImage={setProfileImage}
                  removeGalleryImage={removeGalleryImage}
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  galleryCropper={galleryCropper}
                  setGalleryCropper={setGalleryCropper}
                  images={images}
                  setImages={setImages}
                  handleChooseGallery={handleChooseGallery}
                  talent={talent}
                  handleGallerySubmit={handleGallerySubmit}
                  setAngryData={setAngryData}
                  AngryData={AngryData}
                  uploadWaistphoto={uploadWaistphoto}
                  angry={angry}
                  setangry={setangry}
                  happy={happy}
                  setHappy={setHappy}
                  happyData={happyData}
                  setHappyData={setHappyData}
                  shoulderDownImage={shoulderDownImage}
                  setShoulderDownImage={setShoulderDownImage}
                  shoulderUpImage={shoulderUpImage}
                  setShoulderUpImage={setShoulderUpImage}
                  shoulderDownImageData={shoulderDownImageData}
                  shoulderUpImageData={shoulderUpImageData}
                  sad={sad}
                  sadData={sadData}
                  setSad={setSad}
                  setSadData={setSadData}
                  waistImage={waistImage}
                  waistImageData={waistImageData}
                  setWaistImageData={setWaistImageData}
                  setWaistImage={setWaistImage}
                  steps={3}
                  handleChooseImages={handleChooseImages}
                  setShoulderDownImageData={setShoulderDownImageData}
                  setShoulderUpImageData={setShoulderUpImageData}
                />
                <button
                  onClick={(e) => {
                    if (!profileImage) {
                      showToast("Profile Image is compulsory");
                      return;
                    }
                    if (!images?.length) {
                      showToast("Gallery Images are compulsory");
                      return;
                    }
                    if (images?.length < 3 || images?.length > 10) {
                      showToast(
                        "Upload minimum of 3 images and maximum of 10 images"
                      );
                      return;
                    }
                    if (id) {
                      handleForm(e);
                    } else {
                      handleSubmit();
                    }
                  }}
                  class="wt-btn hide_org"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            ) : undefined}
          </div>
        </div>
      </div>
    </SidebarLayout>
  );
};

export default AddEditUser;
