import React, { useEffect } from "react";
import { Routing } from "./Routing";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "./Assets/css/normalize.css";
import "./Assets/css/scrollbar.css";
import "./Assets/css/fontawesome/fontawesome-all.css";
import "./Assets/css/font-awesome.min.css";
import "./Assets/css/owl.carousel.min.css";
import "./Assets/css/linearicons.css";
import "./Assets/css/jquery-ui.css";
import "./Assets/css/tipso.css";
import "./Assets/css/chosen.css";
import "./Assets/css/main.css";
import "./Assets/css/dashboard.css";
import "./Assets/css/color.css";
import "./Assets/css/transitions.css";
import "./Assets/css/style.css";
import "react-image-crop/dist/ReactCrop.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "./Assets/css/responsive.css";
import "./Message.scss";
import { useDispatch } from "react-redux";
import {
  getCurrentLocationApi,
  getOptionsData,
} from "./Redux/Services/AuthServices";
import { onAuthStateChanged } from "firebase/auth";
import { doc, setDoc, onSnapshot } from "firebase/firestore";
import { auth, db } from "./Utility/firebase";
import { setCurrentLocation } from "./Redux/Actions/AuthActions";
import { storageKey, storeData } from "./Utility/Storage";
import ct from "countries-and-timezones";

function App() {
  const dispatch = useDispatch();

  const getCountry = async () => {
    try {
    
    async function getLocationData() {
        // Get the timezone using Intl API
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      
        // Attempt to map the timezone to a country using the library
        const countryData = ct.getCountryForTimezone(timezone);
        const country = countryData ? countryData.name : "Unknown";
      
        return { country, timezone };
      }
      
      async function fetchLocationData() {
        const { country, timezone } = await getLocationData();
        return { country, timezone }; 
      }
      const response = await fetchLocationData();
      
        try {
          let body = {
            country: response?.country,
            timezones: response?.timezone,
          };

          const res = await dispatch(getCurrentLocationApi(body));

          if (res?.status === 200) {
            let params = {
              id: res?.results?.id,
              phonecode: res?.results?.phonecode,
              timeZone: res?.results?.time_zone,
              country: response?.country,
            };
            storeData(storageKey?.COUNTRY_ID, res?.results?.id);
            dispatch(setCurrentLocation(params));
          }
        } catch (error) {}

    } catch (error) {
      console.error("Error fetching location:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchCountry = async () => {
      await getCountry();
    };
    fetchCountry();
  }, []);

  useEffect(() => {
    dispatch(getOptionsData());
  }, [dispatch]);

  useEffect(() => {
    let userId;
    let statusInterval;

    const updateUserStatus = async (status) => {
      if (userId) {
        try {
          await setDoc(
            doc(db, "userStatus", userId),
            { status },
            { merge: true }
          );
        } catch (error) {
          console.error("Error updating status:", error.message);
        }
      }
    };

    const handleBeforeUnload = () => {
      updateUserStatus("offline");
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        updateUserStatus("online");
        clearInterval(statusInterval);
        statusInterval = setInterval(() => {
          updateUserStatus("online");
        }, 10000);
      } else {
        updateUserStatus("away");
        clearInterval(statusInterval);
      }
    };

    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        userId = user.uid;
        updateUserStatus("online");
        const userRef = doc(db, "users", userId);
        onSnapshot(userRef, (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            const unreadMessages = data.unreadMessages || [];
            console.log(unreadMessages.length);
          }
        });
        document.addEventListener("visibilitychange", handleVisibilityChange);
        window.addEventListener("beforeunload", handleBeforeUnload);
        statusInterval = setInterval(() => {
          updateUserStatus("online");
        }, 10000);
      } else {
        updateUserStatus("offline");
        userId = null;
      }
    });
    return () => {
      unsubscribeAuth();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      clearInterval(statusInterval);
      updateUserStatus("offline");
    };
  }, []);

  return (
    <>
      <Routing />
    </>
  );
}

export default App;
