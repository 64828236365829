import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Rating } from "react-simple-star-rating";
import { useDispatch, useSelector } from "react-redux";
import {
  completePay,
  getModelRatingOptions,
  getModelProjects,
  getRatingOptions,
  postClientFeedbackApi,
  postModelFeedbackApi,
} from "../../Redux/Services/OtherServices";
import { getData, storageKey } from "../../Utility/Storage";
import { showToast } from "../../Utility";
import { InputField } from "../InputField";
import moment from "moment";

const ProjectsModal = (props) => {
  const { show, setShow, type, profileID, setLoading, modelID } = props;
  const dispatch = useDispatch();

  const [ratingOptions, setRatingOptions] = useState([]);
  const [message, setMessage] = useState("");
  const [projectsList, setProjectsList] = useState([]);

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  useEffect(() => {
    getProjectsList();
  }, [type]);

  const getProjectsList = async () => {
    setLoading(true);
    var body = {
      user_id: modelID,
      profile_id: profileID,
      job_status: type,
    };
    console.log("bodybody----", body);
    let res = await dispatch(getModelProjects(body));
    setLoading(false);
    console.log("getPostedListing------", res?.results);
    if (res?.status == 200) {
      setProjectsList(res?.results);
    } else {
      setProjectsList([]);
    }
  };
  console.log("projectsListprojectsList-----", projectsList);

  return projectsList?.length != 0 ? (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h4>{type} Projects</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {projectsList?.length
            ? projectsList?.map((item, index) => {
                return (
                  <div className="modal-body modal-body_content fooedbackModel">
                    <div className="top_model_cont">
                      <div className="project-block">
                        <div class="clientDetailBlock">
                          <span>{`Published by "${
                            item?.project_author?.author_title || ""
                          }"`}</span>
                          {type == "cancelled" ? (
                            <span>
                              {moment(item?.post_meta_details?.end_date).format(
                                "DD/MM/YYYY"
                              )}
                            </span>
                          ) : type == "completed" ? (
                            <span>
                              {moment(
                                item?.post_meta_details?.starting_date
                              ).format("DD/MM/YYYY")}{" "}
                              to{" "}
                              {moment(
                                item?.post_meta_details?.complete_date
                              ).format("DD/MM/YYYY")}
                            </span>
                          ) : (
                            <span>
                              {moment(
                                item?.post_meta_details?.starting_date
                              ).format("DD/MM/YYYY")}{" "}
                              to{" "}
                              {moment(item?.post_meta_details?.end_date).format(
                                "DD/MM/YYYY"
                              )}
                            </span>
                          )}
                        </div>
                        <div>
                          <div className="wt-username">
                            <h3>{item?.profile?.post_title || ""}</h3>
                            {type == "completed" ? (
                              <Rating
                                name="half-rating-read"
                                precision={0.5}
                                value={
                                  item?.post_meta_details?.user_rating
                                    ? Number(item?.post_meta_details?.user_rating)
                                    : 0

                                }
                                readOnly
                                initialValue={
                                  item?.post_meta_details?.user_rating
                                    ? Number(item?.post_meta_details?.user_rating)
                                    : 0
                                }
                                size={25}
                                allowFraction={false}
                              />
                            ) : type == "cancelled" ? (
                              "Cancelled"
                            ) : type == "ongoing" ? (
                              "Ongoing"
                            ) : null}
                          </div>

                          <span>{item?.profile?.post_content}</span>
                        </div>
                      </div>
                      {/* 
                      {item?.review_title ? (
                        <p>
                          {" "}
                          <i className="fa fa-quote-left"></i>
                          {item?.review_title || ""}
                          <i className="fa fa-quote-right"></i>
                        </p>
                      ) : undefined} */}
                    </div>
                  </div>
                );
              })
            : undefined}
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="footer-center">
        {type == "Rating" ? (
          <>
            <Button
              onClick={() => {
                if (
                  role &&
                  status &&
                  status !== "completed" &&
                  feedbackType === 2
                ) {
                  if (role === "client") {
                    submitRating("skip");
                  } else {
                    submitTalentRating("skip");
                  }
                }
                setShow(false);
              }}
            >
              {role && feedbackType === 2 && status && status !== "completed"
                ? "Skip And Pay"
                : "Skip"}
            </Button>
            <Button
              onClick={() => {
                if (role === "client" && feedbackType === 2) {
                  submitRating("submit");
                } else if (role === "client" && feedbackType === 1) {
                  submitClientRating();
                } else {
                  submitTalentRating("submit");
                }
              }}
            >
              Submit feedback
            </Button>
          </>
        ) : (
          <Button
            onClick={() => {
              setShow(false);
            }}
          >
            Close
          </Button>
        )}
      </Modal.Footer> */}
    </Modal>
  ) : null;
};

export default ProjectsModal;
