import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { OverlayTrigger, Tooltip, InputGroup } from "react-bootstrap";
import { storageKey, storeData } from "../Utility/Storage";
import Select from "react-select";
import { BsSearch } from "react-icons/bs";

export const DropdownList = (props) => {
  const {
    type,
    title,
    options,
    placeholder,
    value,
    setValue,
    required,
    tooltip,
    disabled,
    onSelect,
    toolTipText,
    isValid,
    page,
    search,
    isTimeZone,
  } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [optionsList, setOptionsList] = useState("");
  const filteredOptions = "";
  useEffect(() => {
    setSearchQuery("");
    if (options?.length != 0) {
      setOptionsList(options);
    } else {
      setOptionsList([]);
    }
  }, [options, props]);

  const handleSelectOptionSearch = (ele) => {
    const selectedValue = ele;
    const selectedOptionObject = options.find(
      (option) =>
        option.value === selectedValue || option.name === selectedValue
    );
    if (type) {
      if (type == "country") {
        storeData(
          storageKey?.COUNTRY_ID,
          JSON?.stringify(selectedOptionObject?.id)
        );
      } else if (type == "state") {
        storeData(
          storageKey?.STATE_ID,
          JSON?.stringify(selectedOptionObject?.id)
        );
      }
    }
    if (onSelect) {
      onSelect();
    }
    if (ele == "") {
      setValue("");
    } else {
      setValue(ele);
    }
  };

  const handleSelectOption = (ele) => {
    const selectedValue = ele.target.value;
    const selectedOptionObject = options.find(
      (option) =>
        option.value === selectedValue || option.name === selectedValue
    );
    if (type) {
      if (type == "country") {
        storeData(
          storageKey?.COUNTRY_ID,
          JSON?.stringify(selectedOptionObject?.id)
        );
      } else if (type == "state") {
        storeData(
          storageKey?.STATE_ID,
          JSON?.stringify(selectedOptionObject?.id)
        );
      }
    }
    if (onSelect) {
      onSelect();
    }
    if (ele.target.value == "") {
      setValue("");
    } else {
      setValue(ele.target.value);
    }
  };

  const onChangeSearch = (query) => {
    setSearchQuery(query);

    if (options?.length != 0) {
      if (query) {
        let arr = [];
        var expr = new RegExp(query, "gi");
        var wordList = options.filter((elem, index) => expr.test(elem?.name));
        setOptionsList(wordList);
      }
    } else {
      setOptionsList(options);
    }
  };

  return (
    <>
      <Form.Group>
        {toolTipText ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-top">{toolTipText}</Tooltip>}
          >
            <Form.Label>
              {required && <span className="astrik_req">*</span>}
              {title}
              <span className="tooltipOverlay">
                <i class="fa fa-question-circle" aria-hidden="true"></i>
              </span>
            </Form.Label>
          </OverlayTrigger>
        ) : (
          title && (
            <Form.Label>
              {required && <span className="astrik_req">*</span>} {title}
            </Form.Label>
          )
        )}

        {isTimeZone ? (
          <Select
            // isMulti
            options={options}
            value={value}
            onChange={(e) => {
              handleSelectOptionSearch(e);
            }}
            isDisabled={disabled}
            required={required}
            className={isValid ? "border_red" : "border_grey"}
          />
        ) : undefined}
        {/*           
          <Form.Control
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />   */}

        {isTimeZone ? undefined : (
          <Form.Select
            required={required}
            onChange={(e) => {
              handleSelectOption(e);
            }}
            value={value}
            isInvalid={value && isValid}
            isValid={value && !isValid}
            disabled={disabled}
            className={isValid ? "required_inpt form-control" : "form-control"}
          >
            <option value="">{placeholder}</option>
            {optionsList?.length != 0 &&
              optionsList?.map((item, index) => {
                return (
                  <>
                    <option
                      value={item?.value ? item?.value : item?.name}
                      label={
                        item?.value
                          ? item?.value == "Model Kid"
                            ? item?.value + " (13 years and under)"
                            : item?.value == "Model"
                            ? item?.value + " (14 years and above)"
                            : item?.value == "Actor Kid"
                            ? item?.value + " (13 years and under)"
                            : item?.value == "Actor"
                            ? item?.value + " (14 years and above)"
                            : item?.value
                          : item?.name
                      }
                    >
                      {item?.value
                        ? item?.value == "Model Kid"
                          ? item?.value + " (13 years and under)"
                          : item?.value == "Model"
                          ? item?.value + " (14 years and above)"
                          : item?.value == "Actor Kid"
                          ? item?.value + " (13 years and under)"
                          : item?.value == "Actor"
                          ? item?.value + " (14 years and above)"
                          : item?.value
                        : item?.name}
                    </option>
                  </>
                );
              })}
          </Form.Select>
        )}

        {page !== "auth" && value ? (
          <i className="fa fa-angle-down dropDownIcon"></i>
        ) : null}

        {isValid && page !== "auth" ? (
          <span
            // className="invalid-feedback"
            style={{
              color: "#dc3545",
              fontSize: 11,
              position: "absolute",
              bottom: -20,
            }}
          >
            This field is required !
          </span>
        ) : null}

        {page === "auth" && isValid && value?.length === 0 ? (
          <Form.Control.Feedback type={"invalid"}>
            This field is required !
          </Form.Control.Feedback>
        ) : null}
      </Form.Group>
    </>
  );
};
