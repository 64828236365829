import React, { useContext, useEffect, useState } from "react";
import { Tabs, Tab, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DeletePost, Loader } from "../../Components";
import SidebarLayout from "../../layout/SidebarLayout";
import {
  askCancelRequestApi,
  cancelClientProposalApi,
  getLatestProposals,
  getModelProjects,
} from "../../Redux/Services/OtherServices";
import { useDispatch, useSelector } from "react-redux";
import { getData, storageKey, storeData } from "../../Utility/Storage";
import { routeName, showToast } from "../../Utility";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../Constants";
import {
  CAncelModal,
  CancelRequests,
  RequestPayment,
} from "../../Components/Popups";
import FeedbackModal from "../../Components/Popups/Feedback";
import { useHandleMessage } from "../../Utility/helper";
import moment from "moment";

const ModelJobs = () => {
  let queryType = "";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMessage = useHandleMessage();

  const { partnerPermissions } = useSelector((state) => state.authReducer);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [jobsList, setJobsList] = useState([]);
  const [proposalList, setProposalList] = useState([]);
  const [tab, setTab] = useState(queryType || "proposals");
  const [isWithDrawModal, setIsWithDrawModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [RatingModal, setRatingModal] = useState(false);
  const [rating, setRating] = useState([]);
  const [isCancelConfirm, setIsCancelConfirm] = useState(false);
  const [isDirectConfirm, setIsDirectConfirm] = useState(false);
  const [isAskModel, setIsAskModel] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [isDeclineModal, setIsDeclineModal] = useState(false);
  const [isApproveModal, setIsApproveModal] = useState(false);

  useEffect(() => {
    getQueryData();
  }, []);

  const getQueryData = async () => {
    queryType = await getData(storageKey.SELECTED_JOB_TYPE);
    if (queryType === "proposals") {
      getLatestProposalsList();
    } else {
      getAllJobsList(queryType || "ongoing");
    }

    setTab(queryType || "proposals");
  };

  const handleTabs = async (type) => {
    setJobsList([]);
    storeData(storageKey.SELECTED_JOB_TYPE, type);
    setTab(type);
    if (type == "proposals") {
      getLatestProposalsList(type);
    } else {
      getAllJobsList(type);
    }
  };

  const handleCancelProposal = async (cancelType) => {
    let body = {
      action: "project_cancel_model",
      project_id: selectedItem?.profile?.ID || "",
      user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      canceled_type: cancelType || "",
      proposal_id: selectedItem?.post_meta_details?.proposal_id || "",
    };
    if (isAskModel) {
      body.description = description;
    }
    setLoading(true);
    let res = await dispatch(cancelClientProposalApi(body));
    setLoading(false);
    if (res?.status == 200) {
      showToast(res?.message || "", "success");
      setIsDirectConfirm(false);
      if (cancelType === "ask_client") {
        getAllJobsList("ongoing");
      } else {
        setTab("cancelled");
        getAllJobsList("cancelled");
      }
      setIsCancelConfirm(false);
      setIsAskModel(false);
    }
  };

  const handleAcceptCancelRequest = async (cancelType, item) => {
    let body = {
      action: "project_request_model",
      project_id: item ? item?.profile?.ID : selectedItem?.profile?.ID,
      user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      action_type: cancelType || "",
      proposal_id: item
        ? item?.post_meta_details?.proposal_id
        : selectedItem?.post_meta_details?.proposal_id,
      request_id: item
        ? item?.ask_cancel_project?.data?.ID
        : selectedItem?.ask_cancel_project?.data?.ID,
    };

    if (cancelType === "decline") {
      body.reject_reason = selectedReason;
    }

    // console.log(body, "xdlkhdlkgh");
    // return

    setLoading(true);
    let res = await dispatch(askCancelRequestApi(body));
    setLoading(false);
    if (res?.status == 200) {
      if (cancelType === "decline") {
        showToast("Cancel Request Declined Successfully", "success");
        getAllJobsList("ongoing");
      } else {
        showToast("Cancel Request Accepted Successfully", "success");
        setTab("cancelled");
        getAllJobsList("cancelled");
      }
      setIsAskModel(false);
      setIsDeclineModal(false);
      setIsApproveModal(false);
      setSelectedReason("");
    }
  };

  const handleButton = (endDate) => {
    const providedDate = new Date(endDate);
    const today = new Date();
    const differenceInMs = today - providedDate;
    const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);
    return differenceInDays <= 10;
  };

  const getAllJobsList = async (type) => {
    var body = {
      user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
      profile_id:
        partnerPermissions?.profile_id || loggedInUser?.user_data?.profile_id,
      job_status: type,
    };
    setLoading(true);
    let res = await dispatch(getModelProjects(body));
    setLoading(false);
    if (res?.status == 200) {
      setJobsList(res?.results || []);
    } else {
      setJobsList([]);
    }
  };

  const options = [
    {
      name: "Applied Jobs",
      type: "proposals",
    },
    {
      name: "Ongoing Jobs",
      type: "ongoing",
    },
    {
      name: "Completed Jobs",
      type: "completed",
    },
    {
      name: "Direct Jobs",
      type: "direct",
    },
    {
      name: "Cancelled Jobs",
      type: "cancelled",
    },
  ];

  const getLatestProposalsList = async () => {
    let body = {
      user_id: partnerPermissions?.user_id || loggedInUser?.user_data?.user_id,
    };
    setLoading(true);
    let res = await dispatch(getLatestProposals(body));
    setLoading(false);
    if (res?.status == 200) {
      setProposalList(res?.results);
    }
  };

  // const { currentUser } = useContext(AuthContext);

  // const handleMessage = async (displayName, uid) => {

  //   const combinedId =
  //     currentUser.uid > uid ? currentUser.uid + uid : uid + currentUser.uid;
  //   try {
  //     setLoading(true)
  //     const res = await getDoc(doc(db, "chats", combinedId));

  //     if (!res.exists()) {
  //       //create a chat in chats collection
  //       await setDoc(doc(db, "chats", combinedId), { messages: [] });

  //       //create user chats
  //       await updateDoc(doc(db, "userChats", currentUser.uid), {
  //         [combinedId + ".userInfo"]: {
  //           uid: uid,
  //           displayName: displayName,
  //         },
  //         [combinedId + ".date"]: serverTimestamp(),
  //       });

  //       await updateDoc(doc(db, "userChats", uid), {
  //         [combinedId + ".userInfo"]: {
  //           uid: currentUser.uid,
  //           displayName: currentUser.displayName,
  //         },
  //         [combinedId + ".date"]: serverTimestamp(),
  //       });
  //       setLoading(false)
  //       const queryParams = new URLSearchParams({
  //         displayName: displayName || "",
  //         uid: uid || "",
  //       });
  //       navigate(`${routeName.CHAT}?${queryParams.toString()}`);
  //     } else {
  //       setLoading(false)
  //       const queryParams = new URLSearchParams({
  //         displayName: displayName || "",
  //         uid: uid || "",
  //       });
  //       navigate(`${routeName.CHAT}?${queryParams.toString()}`);
  //     }
  //   } catch (err) {
  //     setLoading(false)
  //   }
  // };
  let cancel0 =
  "Job has already started If you cancel this job, you will get 1 star rating for this job and 50% of the amount of your next job on this platform will be deducted as compensation or penalty.";
  let cancel24 =
    " If you cancel the job within 24 hours of the start date, you will get 1 star rating for this job and 50% of the amount of your next job on this platform will be deducted as compensation or penalty.";
  let cancel48 =
    "If you cancel the job in between 24 hours to 48 hours of the start date, you will get 3 star rating for this job and 25% of the amount of your next job on this platform will be deducted as compensation or penalty.";

  const durationString = selectedItem?.post_meta_details?.differenceInHours
  ? selectedItem?.post_meta_details?.differenceInHours
  : "00:00";
  const [hours, minutes] = durationString.split(":").map(Number);
  const duration = moment.duration({ hours, minutes });

  return (
    <>
      <SidebarLayout>
        <Loader loading={loading} />
        <div class="dashboard_main">
          <div className="myJobs">
            <Tabs
              defaultActiveKey="posted"
              id="jobs"
              activeKey={tab}
              onSelect={handleTabs}
            >
              {options?.map((item, index) => (
                <Tab key={index} eventKey={item.type} title={item.name}>
                  <div class="wt-managejobcontent">
                    {item?.type == "proposals"
                      ? proposalList?.map((ele, ind) => {
                          return (
                            <>
                              <div class="wt-userlistinghold wt-featured wt-userlistingvtwo">
                                <div class="wt-userlistingcontent wt-userlistingcontentvtwo">
                                  <div class="wt-contenthead">
                                    <div class="wt-title">
                                      <h2
                                        onClick={() => {
                                          if (
                                            item?.project_hide_status === "hide"
                                          ) {
                                            showToast(
                                              "You cannot see this job as job is hide by admin, wait for client to take action on it",
                                              "error"
                                            );
                                          } else {
                                            storeData(
                                              storageKey.SELECTED_JOB_TYPE,
                                              tab
                                            );
                                            const queryParams =
                                              new URLSearchParams({
                                                id:
                                                  item?.type == "proposals"
                                                    ? ele?.project_id
                                                    : ele?.profile?.ID,
                                              });
                                            navigate(
                                              `${
                                                routeName.JOB_DETAILS
                                              }?${queryParams.toString()}`
                                            );
                                          }
                                        }}
                                      >
                                        {ele?.post_title}
                                      </h2>
                                    </div>
                                    <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                                      <li>
                                        <span class="wt-dashboradclock">
                                          <i class="fa fa-clock"></i> Estimated
                                          Hours :{" "}
                                          {ele?.fw_options?.estimeted_time}
                                        </span>
                                      </li>
                                      <li>
                                        <span class="wt-dashboradclock">
                                          <i class="far fa-calendar"></i> Amount
                                          per hour :{" "}
                                          {ele?.fw_options?.per_hour_amount}
                                        </span>
                                      </li>
                                    </ul>

                                    <div class="wt-btnarea-btn">
                                      <span
                                        onClick={() => {
                                          const queryParams =
                                            new URLSearchParams({
                                              id: ele?.project_id,
                                              proposalId: ele?.proposal_id,
                                            });
                                          navigate(
                                            `${
                                              routeName.JOB_PROPOSAL
                                            }?${queryParams.toString()}`
                                          );
                                        }}
                                        class="wt-bt"
                                      >
                                        Edit Proposal
                                      </span>
                                      {/* <span
                                        class="wt-bt"
                                        onClick={() => {
                                          handleMessage(
                                            "Wally World",
                                            "T8b8u90HilfAdNRMmNu7NUfro6o1",
                                            setLoading
                                          );
                                        }}
                                      >
                                        Message
                                      </span> */}
                                    </div>
                                  </div>
                                  <div class="wt-rightarea">
                                    <div class="wt-hireduserstatus">
                                      <h4>{ele?.amount}</h4>
                                      <span>Quoted Price</span>
                                      <ul class="wt-hireduserimgs">
                                        <li>
                                          <h5
                                            style={{
                                              textTransform: "capitalize",
                                              color: "red",
                                            }}
                                          >
                                            {ele?.status}
                                          </h5>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })
                      : jobsList?.map((ele, ind) => {
                          return (
                            <>
                              <div class="wt-userlistinghold wt-featured wt-userlistingvtwo">
                                <div class="wt-userlistingcontent wt-userlistingcontentvtwo">
                                  <div class="wt-contenthead">
                                    <div class="wt-title">
                                      {ele?.post_meta_details
                                        ?.project_status && (
                                        <a
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          <i
                                            style={{
                                              color:
                                                ele?.post_meta_details
                                                  ?.project_status ==
                                                "completed"
                                                  ? Colors?.green
                                                  : ele?.post_meta_details
                                                      ?.project_status ==
                                                    "cancelled"
                                                  ? Colors?.red
                                                  : Colors?.blue,
                                            }}
                                            class={
                                              ele?.post_meta_details
                                                ?.project_status == "completed"
                                                ? "fa fa-check-circle"
                                                : ele?.post_meta_details
                                                    ?.project_status ==
                                                  "cancelled"
                                                ? "fa fa-times-circle"
                                                : "fa fa-check-fa fa-spinner"
                                            }
                                          ></i>
                                          {"  "}
                                          {
                                            ele?.post_meta_details
                                              ?.project_status
                                          }
                                        </a>
                                      )}
                                      <h2
                                        onClick={() => {
                                          storeData(
                                            storageKey.SELECTED_JOB_TYPE,
                                            tab
                                          );
                                          const queryParams =
                                            new URLSearchParams({
                                              id: ele?.profile?.ID,
                                              type:
                                                item?.type === "direct"
                                                  ? "notification"
                                                  : "",
                                            });
                                          navigate(
                                            `${
                                              routeName.JOB_DETAILS
                                            }?${queryParams.toString()}`
                                          );
                                        }}
                                      >
                                        {ele?.profile?.post_title}
                                      </h2>
                                    </div>
                                    <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                                      <li>
                                        <span>
                                          <i class="far fa-flag"></i> Location :{" "}
                                          {ele?.post_meta_details?.country} |{" "}
                                          {ele?.post_meta_details?.city}
                                        </span>
                                      </li>
                                      <li>
                                        <a class="wt-clicksavefolder">
                                          <i class="far fa-folder"></i> Type:{" "}
                                          {
                                            ele?.post_meta_details
                                              ?._project_type
                                          }
                                        </a>
                                      </li>
                                      <li>
                                        <span class="wt-dashboradclock">
                                          <i class="fa fa-usd"></i> Hourly Rate
                                          :{" "}
                                          {ele?.post_meta_details?._hourly_rate}
                                        </span>
                                      </li>
                                    </ul>
                                    <ul class="wt-saveitem-breadcrumb wt-userlisting-breadcrumb">
                                      <li>
                                        <span class="wt-dashboradclock">
                                          <i class="far fa-calendar"></i> Start
                                          Date :{" "}
                                          {
                                            ele?.post_meta_details
                                              ?.starting_date
                                          }
                                        </span>
                                      </li>
                                      <li>
                                        <span class="wt-dashboradclock">
                                          <i class="far fa-calendar"></i> End
                                          Date :{" "}
                                          {ele?.post_meta_details?.end_date}
                                        </span>
                                      </li>
                                    </ul>
                                    <div class="wt-btnarea-btn">
                                      {item?.type === "ongoing" ? (
                                        <span
                                          class="wt-bt"
                                          onClick={() => {
                                            setSelectedItem(ele);
                                            setIsWithDrawModal(true);
                                          }}
                                        >
                                          Request Payment
                                        </span>
                                      ) : null}

                                      {item?.type === "completed" &&
                                      handleButton(
                                        ele?.post_meta_details?.end_date
                                      ) ? (
                                        <span
                                          class="wt-bt"
                                          onClick={() => {
                                            setSelectedItem(ele);
                                            setIsWithDrawModal(true);
                                          }}
                                        >
                                          Request Payment
                                          <OverlayTrigger
                                            placement="top"
                                            overlay={
                                              <Tooltip id={`tooltip`}>
                                                This Extra Payment Request
                                                option is available for 10 days
                                                after the end date
                                              </Tooltip>
                                            }
                                          >
                                            <span className="tooltipOverlay">
                                              <i
                                                class="fa fa-info-circle"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          </OverlayTrigger>
                                        </span>
                                      ) : null}

                                      {item?.type === "completed" &&
                                      handleButton(
                                        ele?.post_meta_details?.end_date
                                      ) &&
                                      !ele?.post_meta_details
                                        ?.feedback_status ? (
                                        <>
                                          <span
                                            class="wt-bt"
                                            onClick={() => {
                                              setSelectedItem(ele);
                                              setRatingModal(true);
                                              setRating([]);
                                            }}
                                          >
                                            Feedback
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip id={`tooltip`}>
                                                  This Feedback option is
                                                  available for 10 days after
                                                  the end date
                                                </Tooltip>
                                              }
                                            >
                                              <span className="tooltipOverlay">
                                                <i
                                                  class="fa fa-info-circle"
                                                  aria-hidden="true"
                                                ></i>
                                              </span>
                                            </OverlayTrigger>
                                          </span>
                                        </>
                                      ) : null}

                                      {item?.type === "completed" &&
                                      Number(
                                        ele?.post_meta_details?.extra_pay_count
                                      ) !== 0 ? (
                                        <span
                                          class="wt-bt"
                                          onClick={() => {
                                            storeData(
                                              storageKey.SELECTED_JOB_TYPE,
                                              tab
                                            );
                                            navigate(
                                              `${
                                                routeName.EXTRA_PAY_LIST
                                              }?${new URLSearchParams({
                                                proposalId:
                                                  ele?.post_meta_details
                                                    ?.proposal_id,
                                                id: ele?.profile?.ID,
                                              }).toString()}`
                                            );
                                          }}
                                        >
                                          {"Extra Paying Requests" +
                                            " (" +
                                            ele?.post_meta_details
                                              ?.extra_pay_count +
                                            ")"}
                                        </span>
                                      ) : undefined}

                                      {item?.type === "ongoing" &&
                                      Number(
                                        ele?.post_meta_details?.extra_pay_count
                                      ) !== 0 ? (
                                        <span
                                          class="wt-bt"
                                          onClick={() => {
                                            storeData(
                                              storageKey.SELECTED_JOB_TYPE,
                                              tab
                                            );
                                            navigate(
                                              `${
                                                routeName.EXTRA_PAY_LIST
                                              }?${new URLSearchParams({
                                                proposalId:
                                                  ele?.post_meta_details
                                                    ?.proposal_id,
                                                id: ele?.profile?.ID,
                                              }).toString()}`
                                            );
                                          }}
                                        >
                                          {"Extra Paying Requests" +
                                            " (" +
                                            ele?.post_meta_details
                                              ?.extra_pay_count +
                                            ")"}
                                        </span>
                                      ) : undefined}

                                      {item?.type === "ongoing" ? (
                                        <>
                                          <span
                                            class="wt-bt"
                                            onClick={() => {
                                              setSelectedItem(ele);
                                              setSelectedReason("");
                                              setIsCancelConfirm(true);
                                            }}
                                          >
                                            Cancel Job
                                          </span>
                                        </>
                                      ) : undefined}

                                      <span
                                        class="wt-bt"
                                        onClick={() => {
                                          handleMessage(
                                            ele?.project_author?.author_email,
                                            ele?.project_author?.firebase_uid,
                                            ele?.post_meta_details
                                              ?.published_by,
                                            setLoading,
                                            ele?.project_author?.profile_url
                                              ?.url,
                                            ele?.profile?.post_author
                                          );
                                        }}
                                      >
                                        Message
                                      </span>
                                    </div>
                                    {item?.type === "ongoing" &&
                                    ele?.ask_cancel_project?.data &&
                                    !ele?.ask_cancel_project?.data?.status ? (
                                      <div class="wt-haslayout page-data re-send-email">
                                        <div class="wt-jobalerts">
                                          <div class="alert alert-warning cancelReq">
                                            <p>
                                              <b>
                                                Project Cancel
                                                Request&nbsp;:&nbsp;
                                              </b>
                                              {`The Client has requested for the cancellation of this Job`}
                                            </p>
                                            <p>
                                              {ele?.ask_cancel_project?.data
                                                ?.description ? (
                                                <p>
                                                  {" "}
                                                  <b>Reason: </b>
                                                  {ele?.ask_cancel_project?.data
                                                    ?.description || ""}
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                            </p>

                                            <div className="btnsAppDec">
                                              <button
                                                className="btn"
                                                onClick={() => {
                                                  setSelectedItem(ele);
                                                  setIsApproveModal(true);
                                                }}
                                              >
                                                Approve
                                              </button>
                                              <button
                                                className="btn"
                                                onClick={() => {
                                                  setSelectedItem(ele);
                                                  setIsDeclineModal(true);
                                                }}
                                              >
                                                Decline
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : undefined}

                                    {item?.type === "ongoing" &&
                                    ele?.ask_cancel_project_decline?.data &&
                                    ele?.ask_cancel_project_decline?.data
                                      ?.status ? (
                                      <div class="wt-haslayout page-data re-send-email">
                                        <div class="wt-jobalerts">
                                          <div class="alert alert-warning cancelReq">
                                            <p>
                                              <b>
                                                Project cancel request declined
                                                :
                                              </b>
                                              {` Your request for job Cancellation has been rejected by the Client`}
                                            </p>

                                            {ele?.ask_cancel_project_decline
                                              ?.data?.reject_reason ? (
                                              <p>
                                                {" "}
                                                <b>Reason : </b>
                                                {ele?.ask_cancel_project_decline
                                                  ?.data?.reject_reason || ""}
                                              </p>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ) : undefined}
                                  </div>
                                  <div class="wt-rightarea">
                                    <div class="wt-hireduserstatus">
                                      <span>Published By</span>
                                      <h4>
                                        {ele?.project_author?.author_title}
                                      </h4>
                                      <ul class="wt-hireduserimgs">
                                        <li>
                                          <figure>
                                            <img
                                              src={
                                                ele?.project_author?.profile_url
                                                  ?.url
                                              }
                                              alt="Hire talent | Model near me | Talent acquisition | Modeling agencies near me | Modeling Jobs | Modeling companies near me"
                                              class="mCS_img_loaded"
                                            />
                                          </figure>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}

                    {item?.type == "proposals" && !proposalList?.length ? (
                      <div className="wt-contenthead-center">
                        <p>No Jobs Found</p>
                      </div>
                    ) : undefined}

                    {item?.type !== "proposals" && !jobsList?.length ? (
                      <div className="wt-contenthead-center">
                        <p>No Jobs Found</p>
                      </div>
                    ) : undefined}
                  </div>
                </Tab>
              ))}
            </Tabs>
          </div>
        </div>

        <RequestPayment
          setVisible={setIsWithDrawModal}
          visible={isWithDrawModal}
          selectedItem={selectedItem}
          getAllJobsList={getAllJobsList}
          setTab={setTab}
          setLoading={setLoading}
          tab={tab}
        />

        <FeedbackModal
          setShow={setRatingModal}
          show={RatingModal}
          proposalId={selectedItem?.post_meta_details?.proposal_id}
          projectId={selectedItem?.profile?.ID}
          type="Rating"
          role="talent"
          setLoading={setLoading}
          getAllJobsList={getAllJobsList}
          setRating={setRating}
          rating={rating}
        />
        {isDirectConfirm ? (
          <DeletePost
            setShow={setIsDirectConfirm}
            show={isDirectConfirm}
            title="Cancellation"
            handleConfirm={() => {
              handleCancelProposal("direct_cancel");
            }}
            text={`${
              duration.asHours() < 24
                ? cancel24
                : duration.asHours() <= 48
                ? cancel48
                : ''
            }
            Are you sure you want to cancel this project?`}
          />
        ) : undefined}

        {isApproveModal ? (
          <DeletePost
            setShow={setIsApproveModal}
            show={isApproveModal}
            title="Approve Cancellation Request"
            handleConfirm={() => {
              handleAcceptCancelRequest("accept");
            }}
            text="Are you sure you want to approve the cancellation job request?"
          />
        ) : undefined}

        {isCancelConfirm ? (
          <CAncelModal
            setVisible={setIsCancelConfirm}
            visible={isCancelConfirm}
            title="Alert"
            handleDirectConfirm={() => {
              setIsCancelConfirm(false);
              setIsDirectConfirm(true);
            }}
            handleAskToClient={() => {
              setIsAskModel(true);
              if (!description && isAskModel) {
                showToast("Please add description");
              } else if (isAskModel && description) {
                handleCancelProposal("ask_client");
              }
            }}
            setDescription={setDescription}
            description={description}
            isAskModel={isAskModel}
            setIsAskModel={setIsAskModel}
            data={selectedItem}
            selected={selectedItem}
          />
        ) : undefined}

        {isDeclineModal ? (
          <CancelRequests
            visible={isDeclineModal}
            setVisible={setIsDeclineModal}
            setDescription={setSelectedReason}
            description={selectedReason}
            handleAccept={handleAcceptCancelRequest}
          />
        ) : undefined}
      </SidebarLayout>
    </>
  );
};

export default ModelJobs;
