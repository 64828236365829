// Filename - Home.jsx
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Header,
  Footer,
  Loader,
  Filter,
  AppliedFilters,
} from "../../Components";
import { Colors } from "../../Constants";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  getModelsList,
  getSearchResults,
  userFollowing,
} from "../../Redux/Services/OtherServices";
import { getUserDetail } from "../../Redux/Services/AuthServices";
import { SitePagination } from "../../Components/filtersComponents";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import TooltipMui from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import { routeName } from "../../Utility";
import { getData, storageKey } from "../../Utility/Storage";
import { LoginAlert } from "../../Components/Popups/LoginAlert";
import { Login } from "../../Components/Popups";
import { saveTalentFiltersAction } from "../../Redux/Actions/OtherActions";
import { Helmet } from "react-helmet";
import {
  SEARCH_TALENT_DESCRIPTION,
  SEARCH_TALENT_TITLE,
} from "../../Constants/MetaData";

export const SearchTalent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const other = useSelector((state) => state?.otherReducer);
  const auth = useSelector((state) => state?.authReducer);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const talentFilter = useSelector((state) => state.otherReducer.talentFilter);

  const [modelsList, setModelsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [country, setCountry] = useState(talentFilter?.country || "");
  const [state, setState] = useState(talentFilter?.state || "");
  const [city, setCity] = useState(talentFilter?.city || "");
  const [mileRange, setMileRange] = useState(
    talentFilter?.searchmilescity || 0
  );
  const [totalCount, setTotalCount] = useState(0);
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [selectedAge, setSelectedAge] = useState(talentFilter?.age || "");
  const [selectedGender, setSelectedGender] = useState(
    talentFilter?.gender === "other" ? "Non Binary" : talentFilter?.gender || ""
  );
  const [addTitle, setAddTitle] = useState("");
  const [selectedTalent, setSelectedTalent] = useState(
    talentFilter?.talent_type === "actor" && talentFilter?.user_type === "actor"
      ? "actor"
      : talentFilter?.talent_type === "talent" &&
        talentFilter?.user_type === "model"
      ? "model"
      : talentFilter?.talent_type === "child" &&
        talentFilter?.user_type === "talent"
      ? "modelKid"
      : talentFilter?.talent_type === "child" &&
        talentFilter?.user_type === "actor"
      ? "actorKid"
      : talentFilter?.user_type === "photographer"
      ? "photographer"
      : ""
  );
  const [selectedkeyword, setSelectedkeyword] = useState(
    talentFilter?.keyword || ""
  );
  const [myUsers, setMyUsers] = useState(talentFilter?.myusers || "");

  const [isSubmit, setIsSubmit] = useState(false);
  const resultsPerPage = 21;
  let totalPages = Math.ceil(totalCount / resultsPerPage);
  const resultHpourly = talentFilter?.hourly_rate?.length
    ? talentFilter?.hourly_rate?.map((item) => {
        let label = item;
        let value = item;

        if (!item.includes("-")) {
          label = `${item}+`;
          value = `${item}+`;
        }

        return {
          label: label,
          value: value,
        };
      })
    : [];
  const [selectedFilters, setSelectedFilters] = useState({
    hair_colour: talentFilter?.hair_color?.length
      ? talentFilter?.hair_color?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    weight: talentFilter?.weight?.length
      ? talentFilter?.weight?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    ethnicities: talentFilter?.ethnicity?.length
      ? talentFilter?.ethnicity?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    height: talentFilter?.height?.length
      ? talentFilter?.height?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    language: talentFilter?.language?.length
      ? talentFilter?.language?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    english_level: talentFilter?.english_level?.length
      ? talentFilter?.english_level?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    influencers: talentFilter?.influencer?.length
      ? talentFilter?.influencer?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    hourlyRate: resultHpourly || [],
    skills: talentFilter?.skill?.length
      ? talentFilter?.skill?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    projectType: "",
    projectLength: [],
    projectLocation: [],
  });

  const startRange = modelsList?.length ? (page - 1) * resultsPerPage + 1 : 0;
  const endRange = Math.min(page * resultsPerPage, totalCount);

  const [selectedSizes, setSelectedSizes] = useState({
    shirtSize: talentFilter?.shirt_size?.length
      ? talentFilter?.shirt_size?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    pantSize: talentFilter?.pant_size?.length
      ? talentFilter?.pant_size?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    shoeSize: talentFilter?.shoe_size?.length
      ? talentFilter?.shoe_size?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    dressShirtSize: talentFilter?.dress_shirt_size?.length
      ? talentFilter?.dress_shirt_size?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    braCup: talentFilter?.bra_cup?.length
      ? talentFilter?.bra_cup?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    braSize: talentFilter?.bra_size?.length
      ? talentFilter?.bra_size?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    dressSize: talentFilter?.dress_size?.length
      ? talentFilter?.dress_size?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    pantSizeL: talentFilter?.pant_size_length?.length
      ? talentFilter?.pant_size_length?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    pantSizeW: talentFilter?.pant_size_waist?.length
      ? talentFilter?.pant_size_waist?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    dressShirtSleeve: talentFilter?.dress_shirt_sleeve?.length
      ? talentFilter?.dress_shirt_sleeve?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    neckSize: talentFilter?.neck_size?.length
      ? talentFilter?.neck_size?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    chestSize: talentFilter?.chest_size?.length
      ? talentFilter?.chest_size?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    jacket: talentFilter?.jacket?.length
      ? talentFilter?.jacket?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    dressSizeFemale: talentFilter?.dress_size_female?.length
      ? talentFilter?.dress_size_female?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    shirtSizeFemail: talentFilter?.shirt_size_female?.length
      ? talentFilter?.shirt_size_female?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    pantSizeFemale: talentFilter?.pant_size_female?.length
      ? talentFilter?.pant_size_female?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
    shoeSizeFemale: talentFilter?.shoe_size_female?.length
      ? talentFilter?.shoe_size_female?.map((label) => {
          return {
            label: label,
            value: label,
          };
        })
      : [],
  });
  const [isSpace, setIsSpace] = useState(false);
  const [showFilter, setShowFilter] = useState(true);

  const handleApplyFilter = async (pg, selectedTalentVal) => {
    let user = await getData(storageKey.USER_ID);
    setLoading(true);
    var body = {
      page_number: pg || 1,
      per_page: resultsPerPage,
      user_id: user ? user : "",
    };

    if (Number(loggedInUser?.user_data?.user_role) === 14) {
      body.myusers = myUsers ? "myall" : "";
    }

    if (mileRange !== 0) {
      body.searchmilescity = mileRange;
    }

    if (selectedTalentVal) {
      body.user_type =
        selectedTalentVal === "model" || selectedTalentVal === "modelKid"
          ? "talent"
          : selectedTalentVal === "photographer"
          ? "photographer"
          : "actor";
      body.talent_type =
        selectedTalentVal === "modelKid" || selectedTalentVal === "actorKid"
          ? "child"
          : selectedTalentVal === "photographer"
          ? ""
          : selectedTalentVal;
    }

    const addIfDefined = (key, value) => {
      if (value?.length) {
        body[key] = value;
      }
    };

    addIfDefined("age", selectedAge);
    addIfDefined(
      "bra_cup",
      selectedSizes?.braCup?.map((item) => item.value)
    );
    addIfDefined(
      "bra_size",
      selectedSizes?.braSize?.map((item) => item.value)
    );
    addIfDefined("country", country);
    addIfDefined("state", state);
    addIfDefined("city", city);
    addIfDefined("searchmilescity", mileRange);

    addIfDefined(
      "dress_shirt_size",
      selectedSizes?.dressShirtSize?.map((item) => item.value)
    );
    addIfDefined(
      "dress_shirt_sleeve",
      selectedSizes?.dressShirtSleeve?.map((item) => item.value)
    );
    addIfDefined(
      "dress_size",
      selectedSizes?.dressSize?.map((item) => item.value)
    );
    addIfDefined(
      "dress_size_female",
      selectedSizes?.dressSizeFemale?.map((item) => item.value)
    );
    addIfDefined(
      "english_level",
      selectedFilters?.english_level?.map((item) => item.value)
    );
    addIfDefined(
      "ethnicity",
      selectedFilters?.ethnicities?.map((item) => item.value)
    );
    addIfDefined(
      "gender",
      selectedGender === "Non Binary" ? "other" : selectedGender
    );
    addIfDefined(
      "hair_color",
      selectedFilters?.hair_colour?.map((item) => item.value)
    );
    addIfDefined(
      "height",
      selectedFilters?.height?.map((item) => item.value)
    );
    addIfDefined(
      "hourly_rate",
      selectedFilters?.hourlyRate?.map((item) =>
        item.value?.includes("+") ? item.value?.replace(/\+/, "") : item.value
      )
    );
    addIfDefined(
      "influencer",
      selectedFilters?.influencers?.map((item) => item.value)
    );
    addIfDefined(
      "jacket",
      selectedSizes?.jacket?.map((item) => item.value)
    );
    addIfDefined(
      "neck_size",
      selectedSizes?.neckSize?.map((item) => item.value)
    );
    addIfDefined(
      "chest_size",
      selectedSizes?.chestSize?.map((item) => item.value)
    );
    addIfDefined(
      "language",
      selectedFilters?.language?.map((item) => item.value)
    );
    addIfDefined(
      "pant_size_female",
      selectedSizes?.pantSizeFemale?.map((item) => item.value)
    );
    addIfDefined(
      "pant_size_length",
      selectedSizes?.pantSizeL?.map((item) => item.value)
    );
    addIfDefined(
      "pant_size_waist",
      selectedSizes?.pantSizeW?.map((item) => item.value)
    );
    addIfDefined(
      "shirt_size",
      selectedSizes?.shirtSize?.map((item) => item.value)
    );
    addIfDefined(
      "shoe_size",
      selectedSizes?.shoeSize?.map((item) => item.value)
    );
    addIfDefined(
      "pant_size",
      selectedSizes?.pantSize?.map((item) => item.value)
    );
    addIfDefined(
      "shirt_size_female",
      selectedSizes?.shirtSizeFemail?.map((item) => item.value)
    );
    addIfDefined(
      "shoe_size_female",
      selectedSizes?.shoeSizeFemale?.map((item) => item.value)
    );
    addIfDefined(
      "skill",
      selectedFilters?.skills?.map((item) => item.value)
    );
    addIfDefined(
      "weight",
      selectedFilters?.weight?.map((item) => item.value)
    );
    addIfDefined("keyword", selectedkeyword);
    // console.log("selectedFiltersbody-----", body);
    let res = await dispatch(getSearchResults(body));
    if (res?.status == 200) {
      dispatch(saveTalentFiltersAction(body));
      setModelsList(res?.results?.search);
      setTotalCount(res?.results?.pagination?.total_result);
      setLoading(false);
    }
  };

  const onPageChange = (newPage) => {
    setPage(newPage);
    handleApplyFilter(newPage, isSubmit ? selectedTalent : "");
  };

  useEffect(() => {
    handleApplyFilter(page, selectedTalent);
  }, []);

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        user_id: userID ? JSON?.parse(userID) : "",
      };
      let res = await dispatch(getUserDetail(body));
      if (res?.status == 200) {
      }
    } else {
    }
  };

  const getAllModelsList = async () => {
    let user = await getData(storageKey.USER_ID);
    setLoading(true);
    var body = {
      page_number: "1",
      per_page: resultsPerPage,
      user_id: user ? user : "",
    };
    let res = await dispatch(getModelsList(body));
    if (res?.status == 200) {
      dispatch(saveTalentFiltersAction(body));
      setModelsList(res?.results?.user_list);
      setTotalCount(res?.results?.pagination?.total_result);
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>{SEARCH_TALENT_TITLE}</title>
        <meta
          name="keywords"
          content={SEARCH_TALENT_TITLE}
        />
        <meta name="description" content={SEARCH_TALENT_DESCRIPTION} />
      </Helmet>
      <Header />
      <Loader
        loading={
          loading
            ? loading
            : other?.isLoading
            ? other?.isLoading
            : auth?.isLoading
            ? auth?.isLoading
            : false
        }
      />

      <div className="pageTitle">
        <div className="wt-title">
          <h1>Search Talent</h1>
          <ol className="wt-breadcrumb">
            <li className="first-item">
              <a>Home</a>
            </li>
            <li className="last-item">Search talent</li>
          </ol>
        </div>
      </div>

      <section className="searchtalentList">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div
                className="wt-usersidebaricon"
                onClick={() => setShowFilter(!showFilter)}
              >
                <i class="fa fa-filter" aria-hidden="true"></i>
                Refine your search
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-3 float-left sidebarFilterFixed">
              <Filter
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                setSelectedSizes={setSelectedSizes}
                selectedSizes={selectedSizes}
                selectedFilters={selectedFilters}
                setSelectedFilters={setSelectedFilters}
                setCountry={setCountry}
                country={country}
                state={state}
                setState={setState}
                city={city}
                setCity={setCity}
                mileRange={mileRange}
                setMileRange={setMileRange}
                handleApplyFilter={handleApplyFilter}
                setPage={setPage}
                setIsSubmit={setIsSubmit}
                selectedAge={selectedAge}
                setSelectedAge={setSelectedAge}
                selectedGender={selectedGender}
                setSelectedGender={setSelectedGender}
                selectedkeyword={selectedkeyword}
                setSelectedkeyword={setSelectedkeyword}
                setSelectedTalent={setSelectedTalent}
                selectedTalent={selectedTalent}
                myUsers={myUsers}
                setMyUsers={setMyUsers}
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-9 float-left">
              <div className="wt-userlistingholder wt-userlisting wt-haslayout">
                <div className="wt-userlistingtitle">
                  {loading ? undefined : (
                    <span>
                      {`${startRange} - ${endRange} of ${totalCount} results for`}{" "}
                      <em>"Talent"</em>
                    </span>
                  )}
                </div>
                <div className={isSpace ? "wt-filterholder" : ""}>
                  <AppliedFilters
                    setSelectedSizes={setSelectedSizes}
                    selectedSizes={selectedSizes}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    setCountry={setCountry}
                    country={country}
                    state={state}
                    setState={setState}
                    city={city}
                    setCity={setCity}
                    mileRange={mileRange}
                    setMileRange={setMileRange}
                    getAllModelsList={getAllModelsList}
                    setIsSpace={setIsSpace}
                    setIsSubmit={setIsSubmit}
                    isSubmit={isSubmit}
                    selectedAge={selectedAge}
                    setSelectedAge={setSelectedAge}
                    selectedGender={selectedGender}
                    setSelectedGender={setSelectedGender}
                    selectedkeyword={selectedkeyword}
                    setSelectedkeyword={setSelectedkeyword}
                    setSelectedTalent={setSelectedTalent}
                    selectedTalent={selectedTalent}
                    myUsers={myUsers}
                    setMyUsers={setMyUsers}
                  />
                </div>
                <section>
                  {modelsList?.length
                    ? modelsList?.map((item, index) => {
                        return (
                          <div
                            onClick={() => {
                              if (
                                item?.post_meta_details?.freelancer_type ===
                                  "child" &&
                                !loggedInUser?.user_data?.user_id
                              ) {
                                setAddTitle(
                                  "Only approved members will see kids profile"
                                );
                                setLoginModalVisible(true);
                                return;
                              }
                              const queryParams = new URLSearchParams({
                                id: item?.post_meta_details?.user_id,
                              });
                              navigate(
                                `${
                                  routeName.TALENT_PROFILE
                                }?${queryParams.toString()}`
                              );
                            }}
                            key={index}
                          >
                            <TalentCard
                              handleApplyFilter={handleApplyFilter}
                              cardData={item}
                              setLoginModalVisible={setLoginModalVisible}
                              setLoading={setLoading}
                              isSubmit={isSubmit}
                              talentKey={selectedTalent}
                              setAddTitle={setAddTitle}
                            />
                          </div>
                        );
                      })
                    : undefined}
                </section>
                {!loading && !modelsList?.length ? (
                  <section className="no_data">
                    <div>No Data Found</div>
                  </section>
                ) : (
                  <></>
                )}

                <SitePagination
                  module={modelsList}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                  setPage={setPage}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      {loginModalVisible && (
        <LoginAlert
          setVisible={setLoginModalVisible}
          visible={loginModalVisible}
          setLoginModal={setLoginModal}
          loginModal={loginModal}
          title={addTitle}
        />
      )}

      {loginModal && (
        <Login
          show={loginModal}
          setShow={setLoginModal}
          getUserData={getUserData}
        />
      )}
    </div>
  );
};

const TalentCard = (props) => {
  const {
    cardData,
    setLoading,
    setLoginModalVisible,
    handleApplyFilter,
    isSubmit,
    talentKey,
    setAddTitle,
  } = props;
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  // const [isFollow, setIsFollow] = useState(
  //   cardData?.post_meta_details?.user_followers?.includes(
  //     loggedInUser?.user_data?.user_id
  //   )
  //     ? true
  //     : false
  // );

  const handleFollowUnFollow = async () => {
    let user = await getData(storageKey.USER_ID);
    if (user) {
      const isFollow =
        cardData?.post_meta_details?.user_followers?.length &&
        loggedInUser?.user_data?.user_id
          ? cardData?.post_meta_details?.user_followers?.includes(
              loggedInUser?.user_data?.user_id
            )
          : false;
      setLoading(true);
      let body = {
        user_id: user,
        post_id: cardData?.profile?.ID,
        action: isFollow ? "unfollow" : "follow",
      };
      let res = await dispatch(userFollowing(body));
      setLoading(false);
      if (res?.status == 200) {
        handleApplyFilter(1, isSubmit ? talentKey : "");
        // showToast(
        //   isFollow ? "UnFollow Successfully" : "Follow successfully",
        //   "success"
        // );
      }
    } else {
      setAddTitle("");
      setLoginModalVisible(true);
      // showToast('You need to login to perform this action!')
    }
  };

  return (
    <>
      <div>
        {cardData?.post_meta_details?.subscription_pro_id != 107 ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip
                id={`tooltip-${cardData?.post_meta_details?.subscription_pro_id}`}
              >
                {cardData?.post_meta_details?.subscription_pro_id == 106 ||
                cardData?.post_meta_details?.subscription_pro_id == 104
                  ? "Professional member"
                  : cardData?.post_meta_details?.subscription_pro_id == 103 ||
                    cardData?.post_meta_details?.subscription_pro_id == 105
                  ? "Premium member"
                  : ""}
              </Tooltip>
            }
          >
            <div
              className={
                cardData?.post_meta_details?.subscription_pro_id == 106 ||
                cardData?.post_meta_details?.subscription_pro_id == 104
                  ? "paidUsers pro"
                  : cardData?.post_meta_details?.subscription_pro_id == 103 ||
                    cardData?.post_meta_details?.subscription_pro_id == 105
                  ? "paidUsers"
                  : ""
              }
            >
              <i class="fas fa-crown"></i>
            </div>
          </OverlayTrigger>
        ) : null}

        <div className="rpc-post-image">
          <a href="#">
            <div className="fixed-height-image">
              <img src={cardData?.profile_image} 
              alt="Hire Talent | Talent acquisition | Runway models | Male models | Female models | Professional models | kids Modeling"
              />
            </div>
          </a>
        </div>
        <a href="#" className="craContent" id="" tabindex="-1">
          <div className="bottomArea ">
            {cardData?.post_meta_details?.user_rating > 0 ? (
              <div
                className="numerical-rating high"
                data-rating={cardData?.post_meta_details?.user_rating}
              >
                <i className="fa fa-star"></i>
                {cardData?.post_meta_details?.user_rating}
              </div>
            ) : null}

            <h3 className="rpc-post-title">
              <div className="rpc-title" target="_self">
                {cardData?.post_meta_details?.identity_verified == 1 ? (
                  <span title="Identity verified">
                    <i className="fa fa-check"></i>
                  </span>
                ) : (
                  <span className="notVerified" title="Identity not verified">
                    <i className="fa fa-times"></i>
                  </span>
                )}

                {`${cardData?.post_meta_details?.first_name || ""} ${
                  cardData?.post_meta_details?.last_name?.slice(0, 1) || ""
                }`}
              </div>
              <span>
                {cardData?.post_meta_details?.user_type ||
                cardData?.post_meta_details?.freelancer_type
                  ? cardData?.post_meta_details?.user_type === "actor" ||
                    cardData?.post_meta_details?.freelancer_type === "actor"
                    ? "Actor"
                    : "Model"
                  : ""}
              </span>
            </h3>

            <span className="location">
              <i className="fa fa-crosshairs"></i>
              {cardData?.post_meta_details?.country +
                " | " +
                cardData?.post_meta_details?.city}
            </span>
            <TooltipMui
              style={{ marginLeft: 15 }}
              arrow
              placement="top-start"
              title={
                cardData?.post_meta_details?.user_followers?.length &&
                loggedInUser?.user_data?.user_id
                  ? cardData?.post_meta_details?.user_followers?.includes(
                      loggedInUser?.user_data?.user_id
                    )
                    ? "Unfollow"
                    : "Follow"
                  : undefined
              }
            >
              <span
                className=" wt-clicksave  wt_login_follow_offer "
                data-url=""
                data-userid="0"
                data-postid="22230"
                data-type="follower"
                onClick={(e) => {
                  e.stopPropagation();
                  handleFollowUnFollow();
                }}
              >
                <i
                  className={
                    cardData?.post_meta_details?.user_followers?.length &&
                    loggedInUser?.user_data?.user_id
                      ? cardData?.post_meta_details?.user_followers?.includes(
                          loggedInUser?.user_data?.user_id
                        )
                        ? "fa fa-heart"
                        : "fa fa-heart-o"
                      : undefined
                  }
                  style={{ color: Colors?.white }}
                ></i>
              </span>
            </TooltipMui>
          </div>

          <div className="topArea">
            <span className="hourRate">
              <i className="fa fa-money"></i>
              {`$${cardData?.post_meta_details?.perhour_rate} / Hr`}
            </span>

            <div className="listing-small-badge featured-badge"></div>
          </div>
        </a>
      </div>
    </>
  );
};
