import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { JobPostForm } from "../JobPostForm";
import { BookTalentList } from "../BookTalentList";

export const BookTalent = (props) => {
  const { show, setShow, receiverId, setLoading } = props;

  const [isJobForm, setIsJobForm] = useState(false);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {isJobForm ? "Send a Job Offer" : "Request a Booking"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isJobForm ? (
          <JobPostForm
            receiverId={receiverId}
            setShow={setShow}
            setLoading={setLoading}
            type=''
          />
        ) : (
          <BookTalentList
            receiverId={receiverId}
            setShow={setShow}
            setIsJobForm={setIsJobForm}
            setLoading={setLoading}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};
