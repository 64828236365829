import React from "react";
import Navbar from "./NavBar";
import Search from "./Search";
import Chats from "./Chats";

const Sidebar = ({setLoading}) => {
  return (
    <div className="sidebar">
      {/* <Navbar /> */}
      <Search setLoading={setLoading}/> 
      <Chats setLoading={setLoading} />
    </div>
  );
};

export default Sidebar;
