import React, { useEffect, useState } from "react";
import SidebarLayout from "../../layout/SidebarLayout";
import { useDispatch, useSelector } from "react-redux";
import { getPackages } from "../../Redux/Services/AuthServices";
import { Loader, PlansCard } from "../../Components";

export const Packages = () => {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [packagesList, setPackagesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tab, setTab] = useState(1);

  useEffect(() => {
    getAllPackages(1);
  }, []);

  const getAllPackages = async (type) => {
    setLoading(true);
    let res = await dispatch(getPackages());
    if (res?.status == 200) {
      if (type == 1) {
        let data = res?.results?.filter(
          (item) => item?.wt_duration_type == "monthly"
        );
        setPackagesList(data);
        setLoading(false);
      } else if (type == 2) {
        let data = res?.results?.filter(
          (item) => item?.wt_duration_type == "yearly"
        );
        setPackagesList(data);
        setLoading(false);
      }
      setTab(type);
    }
  };

  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <main>
        <div className="wt-dashboardbox wt-dashboardinvocies disputes-header">
          {loggedInUser?.user_data?.user_role == 12 ||
          loggedInUser?.user_data?.user_role == 14 ? undefined : (
            <section className="packages">
              <div className="wt-sectionhead" />

              <div className=" container">
                <div className="wt-tabs">
                  <div className="tabFlex">
                    <div
                      className={tab !== 1 ? "active" : null}
                      onClick={() => {
                        getAllPackages(1);
                      }}
                    >
                      Monthly
                    </div>
                    <div
                      className={tab !== 2 ? "active" : null}
                      onClick={() => {
                        getAllPackages(2);
                      }}
                    >
                      Yearly
                    </div>
                  </div>
                  <div className="row">
                    {packagesList?.map((item) => {
                      return (
                        <>
                          <PlansCard
                            cardData={item}
                            setLoading={setLoading}
                            getAllPackages={getAllPackages}
                          />
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
      </main>
    </SidebarLayout>
  );
};
