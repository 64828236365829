import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SidebarLayout from "../../../layout/SidebarLayout";
import { Loader } from "../../../Components";
import { allTransactionsApi } from "../../../Redux/Services/AdminServices";
import moment from "moment";

const AllTransactions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [startingAfter, setStartingAfter] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const getAllTransactions = async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      // Define the body with pagination parameters
      let body = {
        action: "trans",
        user_email: loggedInUser?.user_data?.user_email || "",
        limit: 5, // Set the limit per page (adjust as needed)
        starting_after: startingAfter // Pass the starting_after parameter for pagination
      };
      
      const res = await dispatch(allTransactionsApi(body));
      if (res?.status === 200) {
        const newTransactions = res?.results?.results;

        if (newTransactions.length > 0) {
          // Append new transactions to the existing state
          setTransactions((prevTransactions) => [
            ...prevTransactions,
            ...newTransactions,
          ]);

          // Check if more data is available
          setHasMore(res?.results?.has_more);

          // Update startingAfter with the ID of the last transaction in the current batch
          setStartingAfter(newTransactions[newTransactions.length - 1].transaction_id);
        } else {
          // No more data available
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllTransactions();
  }, []);

  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <main>
        <div className="wt-dashboardbox wt-dashboardtabsholder wt-accountsettingholder">
          <div className="wt-payout">
            <div className="wt-tabscontent">
              <div id="wt-account">
                <div className="wt-tabsinfo">
                  <div className="wt-settingscontent">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Amount</th>
                            <th>Currency</th>
                            <th>Transaction Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactions?.length ? (
                            transactions?.map((item, index) => (
                              <tr key={index}>
                                <td>${item?.amount || 0}</td>
                                <td>{item?.currency || ""}</td>
                                <td>
                                  {moment(item?.date).format(
                                    "DD / MM / YYYY, hh:mm a"
                                  ) || ""}
                                </td>
                                <td>
                                  <a
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    className="btn-payout"
                                    style={{
                                      color:
                                        item?.status === "succeeded"
                                          ? "green"
                                          : item?.status === "refunded"
                                          ? "orange"
                                          : "red",
                                    }}
                                  >
                                    {item?.status || ""}
                                  </a>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4" style={{ textAlign: "center" }}>
                                No Transaction History Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {hasMore && (
                    <button
                      onClick={getAllTransactions}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Load More"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </SidebarLayout>
  );
};

export default AllTransactions;
