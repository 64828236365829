const metaDescription = 'The Sculp Agency is a Little Rock, Arkansas-based modeling and talent agency that combines an artistic eye with working industry knowledge to meet the needs of models, actors/actresses, advertising professionals, and photographers.'


export const HOME_TITLE = "Top Modeling & Talent Agency - Book Talent & Models | Book Sculp";
export const HOME_DESCRIPTION = "Book Sculp is a top modeling and talent agency, offering model and talent bookings, career opportunities and international modeling services for all ages.";


export const SEARCH_JOB_TITLE = "Book Sculp | Hire Top Talent in Modeling, Photography & More";
export const SEARCH_JOB_DESCRIPTION = "Looking to hire talent? Book Sculp connects you with professionals in modeling, photography, and other creative niches. Find the perfect match for your next project";


export const SEARCH_TALENT_TITLE = "Find Talent for Modeling, Photography & Creative Projects | Book Sculp";
export const SEARCH_TALENT_DESCRIPTION = "Discover top models, actors and photographers on Book Sculp. Easily connect with skilled creatives and bring your projects to life with expert talent across multiple niches.";


export const INSIGHTS_TITLE = "Explore Talent Hiring Tips & Trends | Book Sculp Blog";
export const INSIGHTS_DESCRIPTION = "From finding models to choosing the right photographer; Book Sculp’s Insight page provides valuable tips and resources for hiring professional, creative talent.";


export const INSIGHTS_DETAIL_TITLE = "Insights Detail - Book Sculp Leading USA Modeling Agency - Models, Actors, Photographers";
export const INSIGHTS_DETAIL_DESCRIPTION = "From finding models to choosing the right photographer; Book Sculp’s Insight page provides valuable tips and resources for hiring professional, creative talent.";


export const TALENT_PROFILE_TITLE = "Talent Profile - Book Sculp Leading USA Modeling Agency - Models, Actors, Photographers";
export const TALENT_PROFILE_DESCRIPTION = "Discover top models, actors and photographers on Book Sculp. Easily connect with skilled creatives to bring your projects to life.";


export const JOB_DETAIL_TITLE = "Job Detail - Book Sculp Leading USA Modeling Agency - Models, Actors, Photographers";
export const JOB_DETAIL_DESCRIPTION = "Book Sculp is a top modeling and talent agency, offering booking opportunities, a resource to connect with local creatives and an international modeling service for all ages.";


export const CLIENT_PROFILE_TITLE = "Client Profile - Book Sculp Leading USA Modeling Agency - Models, Actors, Photographers";
export const CLIENT_PROFILE_DESCRIPTION = "Book Sculp is a top modeling and talent agency, offering booking opportunities, a resource to connect with local creatives and an international modeling service for all ages.";


export const FAQ_TITLE = "Frequently Asked Questions | Book Sculp Talent Platform";
export const FAQ_DESCRIPTION = "Need help? Visit Book Sculp’s FAQ page for quick answers on using the platform, hiring talent, and managing your profile.";


export const TERMS_CONDITIONS_TITLE = "Terms & Conditions | Book Sculp Talent Platform Policies";
export const TERMS_CONDITIONS_DESCRIPTION = "Book Sculp’s Terms & Conditions outline the policies for using our platform. Review the terms on hiring talent, account management, and more.";


export const FEEDBACK_TITLE = "Talent Profile - Book Sculp Leading USA Modeling Agency - Models, Actors, Photographers";
export const FEEDBACK_DESCRIPTION = metaDescription


export const SIGNUP_TITLE = "Sign Up at Book Sculp | Connect with Models, Photographers, Actors/Actresses, Clients and more.";
export const SIGNUP_DESCRIPTION = "Sign up at Book Sculp to access a network of creative talent. Whether you’re hiring or showcasing, join today and get started!";


export const PROFILE_SETUP_TITLE = "Profile Setup - Book Sculp Leading USA Modeling Agency - Models, Actors, Photographers";
export const PROFILE_SETUP_DESCRIPTION = metaDescription