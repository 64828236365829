import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { isString } from "../../Utility/validations";
import { bytesToKB, showToast } from "../../Utility";
import { getData, storageKey } from "../../Utility/Storage";
import { ImageCropper } from "../../Components";
import {
  getUserDetail,
  identityVerification,
} from "../../Redux/Services/AuthServices";

const SubmitIdentity = ({ setStep }) => {
  const dispatch = useDispatch();
  const optionsType = useSelector((state) => state.authReducer.allOptionData);

  const [galleryCropper, setGalleryCropper] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState("");
  const [selectedImages, setSelectedImages] = useState("");
  const [images, setImages] = useState([]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    contact_number: Yup.string().required("Contact Number is required"),
    verification_number: Yup.string().required("Document type is required"),
    address: Yup.string().required("Address is required"),
    // identity_document: Yup.mixed().required("Identity Document is required"),
  });

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      await dispatch(getUserDetail(body));
    } else {
    }
  };

  const onSubmit = async (values) => {
    try {
      let userId = await getData(storageKey?.USER_ID);
      let profileId = await getData(storageKey?.PROFILE_ID);
      let uploadData = new FormData();
      uploadData.append("user_id", JSON.parse(userId));
      uploadData.append("profile_id", JSON?.parse(profileId));
      uploadData.append("name", formik.values.name);
      uploadData.append("contact number", formik.values.contact_number);
      uploadData.append("document_type", formik.values.verification_number);
      uploadData.append("address", formik.values.address);

      Array.from(images).map((file, index) => {
        uploadData.append("identity_doc" + "[" + [index] + "]", file);
      });

      const res = await dispatch(identityVerification(uploadData));
      if (res?.status === 200) {
        setStep(2);
        getUserData();
      }
    } catch (error) {
      console.error("API request failed:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      contact_number: "",
      verification_number: "",
      address: "",
      identity_document: null,
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const handleChooseGallery = async (event) => {
    const files = event.target.files;
    const file = files[0];
    if (file && file.type.startsWith("image/")) {
      if (files?.length != 0) {
        const newDetails = Array.from(files).map((file) => ({
          uri: URL.createObjectURL(file),
          name: file.name,
          size: file.size,
          type: file?.type,
        }));

        let newArr = [...(images || []), ...newDetails];
        if (newArr?.length > 10) {
          alert(
            "You cannot upload another Image. You reached your limit, Choose Minimum 3 Images or Maximum 10 Images."
          );
        } else {
          setSelectedFiles((prevFileDetails) => [
            ...(prevFileDetails || []),
            ...newDetails,
          ]);
          setGalleryCropper(true);
        }
      }
    }else{
      showToast("Please select a valid image", "error");
    }
  };

  const handleGallerySubmit = async (data) => {
    setSelectedImages([...selectedImages, ...selectedFiles]);
    let userId = await getData(storageKey?.USER_ID);
    let profileId = await getData(storageKey?.PROFILE_ID);
    let uploadData = new FormData();
    setImages(data);
    setSelectedFiles([]);

    // uploadData.append("upload_type", "gallery");
    // uploadData.append("user_id", JSON.parse(userId));
    // uploadData.append("profile_id", JSON?.parse(profileId));
    // Array.from(data).map((file, index) => {
    //   uploadData.append("file_type" + "[" + [index] + "]", file);
    // });
    // let res = await dispatch(uploadGalleryProfile(uploadData));
    // console.log('uploadGalleryProfile cdcdc------',   res?.results);
    // if (res?.status == 200) {
    //   setSelectedImages(res.results)
    //   setImages(res.results);
    //   setSelectedFiles([]);
    // }
  };

  const removeGalleryImage = async (eachImage, type) => {
    let imageArr = [];
    imageArr = selectedImages?.filter((item) => item.uri != eachImage.uri);
    setSelectedImages(imageArr);
    const fileObjects = selectedImages.map((fileData) => {
      const { uri, name, size, type } = fileData;
      const blob = new Blob([uri], { type });
      return new File([blob], name, { type, lastModified: Date.now() });
    });
    const indexToRemove = type;
    if (indexToRemove >= 0 && indexToRemove < fileObjects.length) {
      fileObjects.splice(indexToRemove, 1);
    }
    setImages(fileObjects);
  };

  return (
    <div className=" idVerification">
      <div className="col-md-8">
        <form
          onSubmit={formik.handleSubmit}
          className="post-identity-form wt-haslayout wt-attachmentsholder"
        >
          <div className="wt-dashboardbox">
            <div className="wt-dashboardboxtitle wt-titlewithsearch">
              <h2>Upload Identity Information</h2>
            </div>
            <div className="wt-dashboardboxcontent">
              <div className="wt-helpsupportcontents">
                <div className="wt-tabscontenttitle">
                  <h2>Upload identity documents</h2>
                </div>
                <div className="wt-description">
                  <div className="wt-experienceaccordion">
                    <p>
                      Please upload your National Identity Card, Passport or
                      Drivers License to verify your identity. You will not be
                      able to apply for a job or post services before verifying
                      your identity.
                    </p>
                  </div>
                </div>
                <div className="form-grou">
                  <div className="wt-formtheme wt-formidentityinfo wt-formprojectinfo wt-formcategory wt-userform">
                    <fieldset>
                      <div className="form-group form-group-half">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Your name"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isString(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          value={formik.values.name}
                        />
                        {formik.errors.name && formik.touched.name && (
                          <div className="error">{formik.errors.name}</div>
                        )}
                      </div>

                      <div class="form-group form-group-half">
                        <input
                          type="number"
                          name="contact_number"
                          class="form-control"
                          placeholder="Contact number"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else {
                              formik.handleChange(val);
                            }
                          }}
                          value={formik.values.contact_number}
                        />
                        {formik.errors.contact_number &&
                          formik.touched.contact_number && (
                            <div className="error">
                              {formik.errors.contact_number}
                            </div>
                          )}
                      </div>

                      <div className="form-group">
                        <select
                          name="verification_number"
                          className="form-control"
                          onChange={formik.handleChange}
                          value={formik.values.verification_number}
                        >
                          <option value="">Select Document type</option>
                          {optionsType?.identityVerify?.map((item, index) => (
                            <option key={index} value={item?.label}>
                              {item?.value}
                            </option>
                          ))}
                        </select>
                        {formik.errors.verification_number &&
                          formik.touched.verification_number && (
                            <div className="error">
                              {formik.errors.verification_number}
                            </div>
                          )}
                      </div>

                      <div class="form-group">
                        <textarea
                          name="address"
                          class="form-control"
                          placeholder="Add address"
                          onChange={(val) => {
                            if (
                              val.target.value === " " ||
                              val.target.value === "."
                            ) {
                            } else if (isString(val.target.value)) {
                              formik.handleChange(val);
                            }
                          }}
                          value={formik.values.address}
                        ></textarea>
                        {formik.errors.address && formik.touched.address && (
                          <div className="error">{formik.errors.address}</div>
                        )}
                      </div>

                      <div class="form-section">
                        {selectedFiles?.length != 0 && (
                          <ImageCropper
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            type={"gallery"}
                            show={galleryCropper}
                            setShow={setGalleryCropper}
                            onCrop={handleGallerySubmit}
                            images={images}
                            setImages={setImages}
                          />
                        )}
                        <div class="wt-bannerphoto wt-tabsinfo">
                          {/* <form class="wt-formtheme wt-formp">
                              <fieldset> */}
                          <div class="form-group form-group-label">
                            <div
                              class={
                                images?.length >= 10
                                  ? "limit_border"
                                  : "wt-labelgroup"
                              }
                            >
                              <label for="filew">
                                <span class="wt-btn">Select Files</span>
                                <input
                                  type="file"
                                  name="file"
                                  id="filew"
                                  multiple
                                  onChange={handleChooseGallery}
                                  disabled={images?.length >= 10}
                                />
                              </label>
                              <span>Choose files to upload</span>
                              <em class="wt-fileuploading">
                                Uploading
                                <i class="fa fa-spinner fa-spin"></i>
                              </em>
                            </div>
                          </div>

                          <div class="form-group">
                            <ul class="wt-attachfile wt-attachfilevtwo">
                              {selectedImages?.length != 0 &&
                                selectedImages?.map((item, index) => {
                                  return (
                                    <>
                                      <li class="wt-uploadingholder">
                                        <div class="wt-uploadingbox">
                                          <div class="wt-designimg">
                                            <input
                                              id="demoq"
                                              type="radio"
                                              name="employees"
                                              value="company"
                                              checked=""
                                            />
                                            <label for="demoq">
                                              <img
                                                src={item?.uri}
                                                alt="Hire talent | Model near me | Talent acquisition | Modeling agencies near me | Modeling Jobs | Modeling companies near me"
                                              />
                                              <i class="fa fa-check"></i>
                                            </label>
                                          </div>
                                          <div class="wt-uploadingbar">
                                            <span class="uploadprogressbar"></span>
                                            <span>{item?.name}</span>
                                            <em>
                                              {item?.size && (
                                                <>
                                                  File size:{" "}
                                                  {bytesToKB(item?.size)}
                                                </>
                                              )}

                                              <i
                                                class="fa fa-times lnr lnr-cross"
                                                aria-hidden="true"
                                                onClick={() =>
                                                  removeGalleryImage(
                                                    item,
                                                    index
                                                  )
                                                }
                                              ></i>
                                            </em>
                                          </div>
                                        </div>
                                      </li>
                                    </>
                                  );
                                })}
                            </ul>
                          </div>
                          <div class="form-group">
                            <button
                              type="submit"
                              className="wt-btn wt-save-identity"
                            >
                              Submit
                            </button>
                          </div>
                          {/* </fieldset>
                            </form> */}
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SubmitIdentity;
