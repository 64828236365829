import React, { useEffect, useState } from "react";
import SidebarLayout from "../../layout/SidebarLayout";
import { useDispatch, useSelector } from "react-redux";
import { ImageCropper, Loader } from "../../Components";
import { isString } from "../../Utility/validations";
import { useFormik } from "formik";
import * as Yup from "yup";
import { bytesToKB, routeName, showToast } from "../../Utility";
import {
  getSocialPostDetails,
  updateSocialPost,
  uploadSocialPost,
} from "../../Redux/Services/OtherServices";
import { useLocation, useNavigate } from "react-router-dom";

const AddPost = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location?.search);
  const id = queryParams?.get("id");

  const { partnerPermissions } = useSelector((state) => state.authReducer);
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [galleryCropper, setGalleryCropper] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState("");
  const [selectedImages, setSelectedImages] = useState("");
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Caption is required"),
    address: Yup.string().required("Description is required"),
  });

  const onSubmit = async (values) => {
    try {
      console.log("Form values:", values);
      let uploadData = new FormData();
      uploadData.append(
        "user_id",
        partnerPermissions?.user_id || loggedInUser?.user_data?.user_id
      );
      uploadData.append(
        "profile_id",
        partnerPermissions?.profile_id || loggedInUser?.user_data?.profile_id
      );
      uploadData.append("port_title", formik.values.name);
      uploadData.append("port_description", formik.values.address);
      uploadData.append(id ? "update_type" : "upload_type", "social_post");
      if (id) {
        uploadData.append("post_id", id);
      }

      Array.from(images).map((file, index) => {
        uploadData.append("file_type" + "[" + [index] + "]", file);
      });

      let res = "";
      setLoading(true);
      if (id) {
        res = await dispatch(updateSocialPost(uploadData));
      } else {
        res = await dispatch(uploadSocialPost(uploadData));
      }
      setLoading(false);

      // console.log("identityVerification res---", res);
      if (res?.status === 200) {
        navigate(routeName.DASHBOARD, { replace: true });
      }
    } catch (error) {
      setLoading(false);
      console.error("API request failed:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  const handleChooseGallery = async (event) => {
    const files = event.target.files;
    const file = files?.[0];
    if (files?.length != 0) {
      if (file && file.type.startsWith("image/")) {
        const newDetails = Array.from(files).map((file) => ({
          uri: URL.createObjectURL(file),
          name: file.name,
          size: file.size,
          type: file?.type,
        }));

        let newArr = [...(images || []), ...newDetails];
        if (newArr?.length > 10) {
          alert(
            "You cannot upload another Image. You reached your limit, Choose Minimum 3 Images or Maximum 10 Images."
          );
        } else {
          setSelectedFiles((prevFileDetails) => [
            ...(prevFileDetails || []),
            ...newDetails,
          ]);
          setGalleryCropper(true);
        }
      } else {
        showToast("Please select a valid image", "error");
      }
    }
  };

  const handleGallerySubmit = async (data) => {
    setSelectedImages([...selectedImages, ...selectedFiles]);
    setImages([...images, ...data]);
    setSelectedFiles([]);
  };

  // console.log(selectedImages,images, "selectedImagesselectedImages");

  const removeGalleryImage = async (eachImage, type) => {
    let imageArr = [];
    imageArr = selectedImages?.filter((item) => item.uri != eachImage.uri);
    setSelectedImages(imageArr);
    const fileObjects = selectedImages.map((fileData) => {
      const { uri, name, size, type } = fileData;
      const blob = new Blob([uri], { type });
      return new File([blob], name, { type, lastModified: Date.now() });
    });
    const indexToRemove = type;
    if (indexToRemove >= 0 && indexToRemove < fileObjects.length) {
      fileObjects.splice(indexToRemove, 1);
    }
    setImages(fileObjects);
  };

  const getSinglePostData = async () => {
    try {
      let body = {
        social_id: id,
      };
      setLoading(true);
      let res = await dispatch(getSocialPostDetails(body));
      setLoading(false);
      if (res?.status === 200) {
        const transformedData = res?.results?.[0]?.media?.length
          ? res?.results?.[0]?.media?.map((item) => ({
              uri: item.url,
              name: item.name,
              size: 0,
              type: item.type,
            }))
          : [];

        setSelectedImages(transformedData || []);
        setImages(res?.results?.[0]?.media);
        // const fileObjects = res?.results?.[0]?.media?.map((fileData) => {
        //   const { url, name, size, type } = fileData;
        //   const blob = new Blob([url], { type });
        //   return new File([blob], name + ".png", { type, lastModified: Date.now() });
        // });
        // setImages(fileObjects);

        formik.setFieldValue(
          "name",
          res?.results?.[0]?.post_details?.post_title || ""
        );
        formik.setFieldValue(
          "address",
          res?.results?.[0]?.post_details?.post_content || ""
        );
      }
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getSinglePostData();
    }
  }, []);

  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <main>
        <div className="wt-dashboardbox wt-dashboardinvocies disputes-header">
          <div className=" idVerification">
            <div className="col-md-8">
              <form
                onSubmit={formik.handleSubmit}
                className="post-identity-form wt-haslayout wt-attachmentsholder"
              >
                <div className="wt-dashboardbox">
                  <div className="wt-dashboardboxtitle wt-titlewithsearch">
                    <h2>{id ? "Update Social Post" : "Add New Social Post"}</h2>
                  </div>
                  <div className="wt-dashboardboxcontent">
                    <div className="wt-helpsupportcontents">
                      <div className="form-grou">
                        <div className="wt-formtheme wt-formidentityinfo wt-formprojectinfo wt-formcategory wt-userform">
                          <fieldset>
                            <div className="form-group ">
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Write a caption"
                                onChange={(val) => {
                                  if (
                                    val.target.value === " " ||
                                    val.target.value === "."
                                  ) {
                                  } else if (isString(val.target.value)) {
                                    formik.handleChange(val);
                                  }
                                }}
                                value={formik.values.name}
                              />
                              {formik.errors.name && formik.touched.name && (
                                <div className="error">
                                  {formik.errors.name}
                                </div>
                              )}
                            </div>

                            <div class="form-group">
                              <textarea
                                name="address"
                                class="form-control"
                                placeholder="Enter Description"
                                onChange={(val) => {
                                  if (
                                    val.target.value === " " ||
                                    val.target.value === "."
                                  ) {
                                  } else if (isString(val.target.value)) {
                                    formik.handleChange(val);
                                  }
                                }}
                                value={formik.values.address}
                              ></textarea>
                              {formik.errors.address &&
                                formik.touched.address && (
                                  <div className="error">
                                    {formik.errors.address}
                                  </div>
                                )}
                            </div>

                            <div className="wt-tabscontenttitle">
                              <h2>Choose Gallery</h2>
                            </div>
                            <div class="form-section">
                              {selectedFiles?.length != 0 && (
                                <ImageCropper
                                  selectedFiles={selectedFiles}
                                  setSelectedFiles={setSelectedFiles}
                                  type={"gallery"}
                                  show={galleryCropper}
                                  setShow={setGalleryCropper}
                                  onCrop={handleGallerySubmit}
                                  images={images}
                                  setImages={setImages}
                                />
                              )}
                              <div class="wt-bannerphoto wt-tabsinfo">
                                {/* <form class="wt-formtheme wt-formp">
                              <fieldset> */}
                                <div class="form-group form-group-label">
                                  <div
                                    class={
                                      images?.length >= 10
                                        ? "limit_border"
                                        : "wt-labelgroup"
                                    }
                                  >
                                    <label for="filew">
                                      <span class="wt-btn">Select Files</span>
                                      <input
                                        type="file"
                                        name="file"
                                        id="filew"
                                        multiple
                                        onChange={handleChooseGallery}
                                        disabled={images?.length >= 10}
                                      />
                                    </label>
                                    <span>Choose files to upload</span>
                                    <em class="wt-fileuploading">
                                      Uploading
                                      <i class="fa fa-spinner fa-spin"></i>
                                    </em>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <ul class="wt-attachfile wt-attachfilevtwo">
                                    {selectedImages?.length != 0 &&
                                      selectedImages?.map((item, index) => {
                                        return (
                                          <>
                                            <li class="wt-uploadingholder">
                                              <div class="wt-uploadingbox">
                                                <div class="wt-designimg">
                                                  <input
                                                    id="demoq"
                                                    type="radio"
                                                    name="employees"
                                                    value="company"
                                                    checked=""
                                                  />
                                                  <label for="demoq">
                                                    <img
                                                      src={item?.uri}
                                                      alt="Hire talent | Model near me | Talent acquisition | Modeling agencies near me | Modeling Jobs | Modeling companies near me"
                                                    />
                                                    <i class="fa fa-check"></i>
                                                  </label>
                                                </div>
                                                <div class="wt-uploadingbar">
                                                  <span class="uploadprogressbar"></span>
                                                  <span>{item?.name}</span>
                                                  <em>
                                                    {item?.size && (
                                                      <>
                                                        File size:{" "}
                                                        {bytesToKB(item?.size)}
                                                      </>
                                                    )}

                                                    <i
                                                      class="fa fa-times lnr lnr-cross"
                                                      aria-hidden="true"
                                                      onClick={() =>
                                                        removeGalleryImage(
                                                          item,
                                                          index
                                                        )
                                                      }
                                                    ></i>
                                                  </em>
                                                </div>
                                              </div>
                                            </li>
                                          </>
                                        );
                                      })}
                                  </ul>
                                </div>
                                <div className="wt-description">
                                  <div className="wt-experienceaccordion">
                                    <p>
                                      You are allowed to upload 10 images per
                                      service. If you will upload more than 10
                                      images then first 10 images will be
                                      attached to this service.
                                    </p>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <button
                                    type="submit"
                                    className="wt-btn wt-save-identity"
                                  >
                                    Post
                                  </button>
                                </div>
                                {/* </fieldset>
                            </form> */}
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </SidebarLayout>
  );
};

export default AddPost;
