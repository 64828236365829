import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OTPInput from "otp-input-react";
import PhoneInput from "react-phone-input-2";
import { getData, storageKey, storeData } from "../../Utility/Storage";
import { getUserDetail, verifyOtpApi } from "../../Redux/Services/AuthServices";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../Utility";
import { loggedInUsedAction } from "../../Redux/Actions/AuthActions";

export const VerifyOtp = (props) => {
  const dispatch = useDispatch();

  const {
    basicDetails,
    setBasicDetails,
    phoneNumber,
    setPhoneCode,
    phoneCode,
    setVisible,
    visible,
    otp,
    setOtp,
    countDown,
    setCountDown,
    getUserData,
    // handleVerify,
    edit,
  } = props;

  const [showVerifySection, setShowVerifySection] = useState(false);
  const [number, setNumber] = useState(phoneNumber);
  const [countryCode, setCountryCode] = useState(phoneCode);

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      setCountDown(0);
    }
  }, [countDown]);
  const getLastFourDigits = (phoneNumber) => {
    const phoneStr = phoneNumber.toString();
    return phoneStr.slice(-4);
  };
  const handleChangePhone = (phone, country) => {
    setShowVerifySection(false);
    setOtp("");
    setPhoneCode(country?.dialCode);
    setCountryCode(country?.dialCode);
    setBasicDetails({
      ...basicDetails,
      mobileNumber: phone?.replace(country.dialCode, ""),
    });
  };
  const handleVerify = async (val) => {
    if (val === "verify" && otp?.length < 6) {
      showToast("Please enter a valid OTP", "error");
      return;
    }
    try {
      let userId = await getData(storageKey?.USER_ID);

      let body = {
        user_id: userId ? JSON.parse(userId) : "",
        action: val === "send" || val === "resend" ? "send" : "otp_verify",
        country_code: phoneCode,
        phone: basicDetails?.mobileNumber,
        verify_otp: val === "send" || val === "resend" ? "" : otp,
      };
      let res = await dispatch(verifyOtpApi(body));

      if (res?.status === 200) {
        setCountDown(59);
        if (val == "otp_verify") {
          setVisible(false);
        }
        if (val == "send" && !edit) {
          setShowVerifySection(true);
        }
        getUserData();
      }
    } catch (err) {
      console.log("Verify mobile number error", err);
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={visible}
      onHide={() => {
        setVisible(false);
      }}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Phone Number Verification
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!showVerifySection && !edit ? (
          <p>
            If you want to receive your Book Sculp Account notifications as SMS
            please verify your number.
          </p>
        ) : null}
        <div className="phone_inpt">
          <div className="numberForm">
            <PhoneInput
              value={phoneCode + basicDetails?.mobileNumber}
              enableSearch={true}
              country="us"
              placeholder="Phone number"
              containerClass={"cntn_telCls"}
              inputClass={"cstm_cls"}
              buttonClass={"custm_btn"}
              dropdownClass={"custom-dropdown"}
              onChange={(phone, country) => handleChangePhone(phone, country)}
            />
          </div>
        </div>

        {showVerifySection || edit ? (
          <>
            <p>
              Please enter the verification code that we have send on your
              registered phone number : ******
              {getLastFourDigits(basicDetails?.mobileNumber)}
            </p>
            <div className="optInput">
              <OTPInput
                value={otp}
                onChange={setOtp}
                autoFocus={true}
                OTPLength={6}
                otpType="number"
                disabled={false}
                inputStyle={{ padding: 0 }}
              />
            </div>
            {countDown === 0 ? (
              <div className="rsnd_btn">
                <button
                  onClick={() => handleVerify("resend")}
                  className="btn_rd"
                >
                  Resend OTP
                </button>
              </div>
            ) : (
              <h5>
                The verify code will expire in
                <span className="count"> 00 : {countDown}</span>
              </h5>
            )}
          </>
        ) : null}
      </Modal.Body>
      <Modal.Footer className="footer-center">
        <Button
          onClick={() => {
            if (showVerifySection || edit) {
              handleVerify("otp_verify");
            } else {
              handleVerify("send");
            }
          }}
        >
          {showVerifySection || edit ? "Verify" : "Send One Time Password"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
