
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header, Footer, BlogCard, Loader } from "../../Components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Spinner } from "react-bootstrap";
import {
  getBlogReviewsList,
} from "../../Redux/Services/OtherServices";
import Button from "react-bootstrap/Button";
import { Helmet } from "react-helmet";
import { INSIGHTS_DESCRIPTION, INSIGHTS_TITLE } from "../../Constants/MetaData";

export const Insights = () => {
  const dispatch = useDispatch();
  const other = useSelector((state) => state?.otherReducer);
  const auth = useSelector((state) => state?.authReducer);
  const [blogList, setBlogList] = useState([]);
  const [blogPage, setBlogPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [viewMoreLoad, setViewMoreLoad] = useState(false);

  useEffect(() => {
    handleBlogList();
  }, []);

  const getMoreBlogList = async () => {
    setViewMoreLoad(true);
    var body = {
      post_type: "blogs",
      page_number: blogPage + 1,
      per_page: "6",
    };
    let res = await dispatch(getBlogReviewsList(body));
    if (res?.status == 200) {
      setBlogList(blogList?.concat(res?.results?.post));
      setBlogPage(blogPage + 1);
      setViewMoreLoad(false);
    }
  };
  const handleBlogList = async () => {
    setLoading(true);
    var body = {
      post_type: "blogs",
      page_number: "1",
      per_page: "18",
    };
    let res = await dispatch(getBlogReviewsList(body));
    if (res?.status == 200) {
      setBlogList(res?.results?.post);
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>{INSIGHTS_TITLE}</title>
        <meta
          name="keywords"
          content={INSIGHTS_TITLE}
        />
        <meta name="description" content={INSIGHTS_DESCRIPTION} />
      </Helmet>
      <Header />
      <Loader
        loading={
          viewMoreLoad
            ? null
            : loading
            ? loading
            : other?.isLoading
            ? other?.isLoading
            : auth?.isLoading
            ? auth?.isLoading
            : false
        }
      />
      <div className="pageTitle">
        <div className="wt-title">
          <h1>From The Insights</h1>
          <ol className="wt-breadcrumb">
            <li className="first-item">
              <p>Latest News From The Insights</p>
            </li>
          </ol>
        </div>
      </div>
      <section className="blogsScreen">
        <Container>
          <div className="blogList">
            <div className="row">
              {blogList?.map((item) => {
                return (
                  <>
                    <BlogCard cardData={item} />
                  </>
                );
              })}
              {viewMoreLoad ? (
                <div className="loadMore">
                  <Button variant="" disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    Loading...
                  </Button>
                </div>
              ) : (
                <div className="row">
                  <div
                    className="col-md-12 text-center"
                    onClick={() => getMoreBlogList()}
                  >
                    {!loading ? (
                      <button class="moreBlog">View More</button>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
      </section>
      <Footer />
    </div>
  );
};
