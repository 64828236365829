import React, { useState } from "react";
import * as XLSX from "xlsx";
import SidebarLayout from "../../layout/SidebarLayout";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { routeName, showToast } from "../../Utility";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addPartnerListApi,
  checkEmailStatusApi,
} from "../../Redux/Services/PartnerServices";
import { EmailListPartner } from "../../Components/Popups";
import { Loader } from "../../Components";

const ExcelUpload = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(location?.search);
  const type = queryParams?.get("type");
  const tab = queryParams?.get("tab");
  const partnerId = queryParams?.get("partnerId");
  // console.log("Your Param Value:", type);

  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [emailsList, setEmailsList] = useState([]);
  const [isListModal, setIsListModal] = useState(false);

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const handleChange = (event) => {
    setFile(event.target.files[0]);
    const file = event.target.files[0];
    const fileType = file?.type;

    if (
      fileType !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      fileType !== "application/vnd.ms-excel"
    ) {
      showToast("Only Excel files (.xlsx, .xls) are allowed", "error");
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);
      setData(sheetData);
      if (sheetData?.length) {
        const transformedData = sheetData?.map((item) => ({
          email: item?.Email,
          name: item?.Name,
          phone: item?.Phone,
        }));
        handleGetEmailStatus(transformedData);
      }
    };

    reader.readAsBinaryString(file);
  };

  const handleGetEmailStatus = async (transformedData) => {
    try {
      setLoading(true);
      let body = {
        email_list: transformedData,
      };

      // console.log(body);

      const response = await dispatch(checkEmailStatusApi(body));
      setLoading(false);
      // console.log("add extra pay response---", response);
      if (response?.status === 200) {
        setIsListModal(true);
        setEmailsList(response?.results);
        // showToast('Added Successfully', 'success');
        // const queryParams = new URLSearchParams({
        //   type,
        //   tab:2
        // });
        // navigate(`${routeName.LIST}?${queryParams.toString()}`, {replace: true});
      }
    } catch (error) {
      setLoading(false);
      console.error("API request failed:", error);
    }
  };

  // console.log(data, "hgdhj");

  function getValidationSchema(values) {
    return Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Please enter a valid email address"),
      phone: Yup.string().required("Phone Number is required"),
    });
  }

  const handleSubmitList = async () => {
    try {
      setLoading(true);
      const filteredData = emailsList?.length
        ? emailsList
            ?.filter((item) => item.status === "false")
            ?.map((item) => ({
              name: item?.user_name,
              phone: item?.user_phone.toString(),
              email: item?.user_email,
              status: "",
            }))
        : [];
      let body = {
        user_id: partnerId || loggedInUser?.user_data?.profile_id,
        user_type:
          type === "talent"
            ? "Model"
            : type === "actor"
            ? "Actor"
            : type === "client"
            ? "Client"
            : "Photographer",
        user_list: filteredData || [],
      };

      // console.log(body);

      const response = await dispatch(addPartnerListApi(body));
      setLoading(false);
      // console.log("add extra pay response---", response);
      if (response?.status === 200) {
        showToast("Added Successfully", "success");
        const queryParams = new URLSearchParams({
          type,
          tab: 2,
          partnerId: partnerId || "",
        });
        navigate(`${routeName.LIST}?${queryParams.toString()}`, {
          replace: true,
        });
        // navigate(routeName.LIST, {replace: true})
      }
    } catch (error) {
      setLoading(false);
      console.error("API request failed:", error);
    }
  };

  const onSubmit = async (values) => {
    try {
      // console.log("Form values:", values);
      setLoading(true);
      let body = {
        user_id: partnerId || loggedInUser?.user_data?.profile_id,
        user_type:
        type === "talent"
          ? "Model"
          : type === "actor"
          ? "Actor"
          : type === "client"
          ? "Client"
          : "Photographer",
        name: values.name,
        phone: values.phone,
        email: values.email,
      };

      // console.log(body);

      const response = await dispatch(addPartnerListApi(body));
      setLoading(false);
      // console.log("add extra pay response---", response);
      if (response?.status === 200) {
        showToast("Added Successfully", "success");
        const queryParams = new URLSearchParams({
          type,
          tab: 2,
          partnerId: partnerId || "",
        });
        navigate(`${routeName.LIST}?${queryParams.toString()}`, {
          replace: true,
        });
        // navigate(routeName.LIST, {replace: true})
      }
    } catch (error) {
      setLoading(false);
      console.error("API request failed:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
    },
    validationSchema: () => getValidationSchema(formik.values),
    onSubmit: onSubmit,
  });

  return (
    <SidebarLayout>
      <Loader loading={loading} />
      {/* <div>
        <form onSubmit={handleSubmit}>
          <input type="file" onChange={handleChange} />
          <button type="submit">Upload</button>
        </form>
        {data.length > 0 && (
          <table>
            <thead>
              <tr>
                {Object.keys(data[0]).map((key) => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  {Object.values(row).map((value, index) => (
                    <td key={index}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div> */}
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pull-rightBtn">
        <a
          className="wt-btn"
          onClick={() => {
            const queryParams = new URLSearchParams({
              type,
              tab,
              partnerId: partnerId || "",
            });
            navigate(`${routeName.LIST}?${queryParams.toString()}`, {
              replace: true,
            });
          }}
        >
          {" "}
          View List
        </a>
      </div>
      <div className="dashboard_main">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 addUsers">
          <div className="container">
            <div>
              <span>
                <h3>{`Add Multiple ${
                  type === "talent" ? "Models" : type
                } ( Excel sheet only )`}</h3>
                <a
                  target="_blank"
                  href="https://booksculp.com/wp-content/uploads/Excel_screenschot.png"
                >
                  View Example
                </a>
              </span>
            </div>
            <div className="table-responsive">
              <span id="message"></span>
              <div>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="file"
                          id="my_file_input"
                          className="file_excel_uploader"
                          name="user_list_file"
                          data-user_id="1209"
                          data-sheet_type="talent"
                          onChange={handleChange}
                        />{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="exem_seprator">OR</div>
          </div>

          <div className="container">
            <div>
              <h3>{`Add Single  ${type === "talent" ? "Model" : type}`}</h3>
            </div>
            <div>
              <form
                onSubmit={formik.handleSubmit}
                id="submit_single_email_list"
              >
                <div className="row">
                  <div className="form-group form-group-half ">
                    <span className="timelabel">
                      Name<sub className="required_field"> *</sub>
                    </span>
                    <input
                      type="text"
                      className="form-control single_name"
                      id="name"
                      name="name"
                      placeholder="Name"
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      value={formik.values.name}
                      maxLength={30}
                    />
                    {formik.errors.name && formik.touched.name && (
                      <div className="error">{formik.errors.name}</div>
                    )}
                  </div>
                  <div className="form-group form-group-half ">
                    <span className="timelabel">
                      Email<sub className="required_field"> *</sub>
                    </span>
                    <input
                      type="email"
                      className="form-control single_email"
                      id="email"
                      name="email"
                      placeholder="Email"
                      maxLength={50}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      value={formik.values.email}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <div className="error">{formik.errors.email}</div>
                    )}
                  </div>
                  <div className="form-group form-group-half ">
                    <span className="timelabel">
                      Phone Number<sub className="required_field"> *</sub>
                    </span>
                    <input
                      type="number"
                      className="form-control single_phone"
                      id="phone"
                      name="phone"
                      placeholder="Phone"
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      value={formik.values.phone}
                      maxLength={12}
                    />
                    {formik.errors.phone && formik.touched.phone && (
                      <div className="error">{formik.errors.phone}</div>
                    )}
                  </div>
                </div>
                <div className="">
                  <button
                    type="submit"
                    className="wt-btn submit_single_user_email_part"
                    data-profile_id="32223"
                    data-user_id="1209"
                    data-sheet_type="talent"
                    name="load"
                  >
                    Add To List
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <EmailListPartner
        show={isListModal}
        setShow={setIsListModal}
        listData={emailsList}
        handleSubmitList={handleSubmitList}
      />
    </SidebarLayout>
  );
};

export default ExcelUpload;
