import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { InputField } from "../InputField";

export const CancelRequests = (props) => {
  const { setVisible, visible, handleAccept, description, setDescription } =
    props;

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={visible}
      onHide={() => {
        setVisible(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Cancellation Requests
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="form-group">
            <label>Reason for declining the request</label>
            <InputField
              placeholder="Describe your reason for cancelling this job"
              type="textarea"
              value={description}
              onChangeText={(e) => {
                setDescription(e?.target?.value);
              }}
            />
          </div>
          <div className="footer-center">
            <Button
              onClick={() => {
                handleAccept("decline");
              }}
            >
              Decline
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
