import React, { useState, createRef, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button, Modal } from "react-bootstrap";
import { base64StringToFile, bytesToKB } from "../Utility";

export const ImageCropper = ({
  type,
  imageSrc,
  show,
  setShow,
  profileImage,
  setProfileImage,
  onCrop,
  selectedFile,
  setSelectedFile,
  images,
  setImages,
  selectedFiles,
  setSelectedFiles,
}) => {
  const profileCropRef = createRef();
  const galleryCropRef = useRef(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [croppedFiles, setCroppedFiles] = useState([]);

  const handleImageCrop = (image, index) => {
    // setCurrentImageIndex(index);
    let arr = [...croppedFiles];
    if (typeof galleryCropRef.current?.cropper !== "undefined") {
      let cropped_file = galleryCropRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL("image/jpeg", 0.6);
      let file = base64StringToFile(cropped_file, image?.name, image?.type);
      // console.log("cropped_filecropped_file----", file);
      arr?.push(file);
      setCroppedFiles(arr);
    }
    // console.log("arrarr----", arr);
    if (selectedFiles?.length - 1 == index) {
      setShow(false);
      setCurrentImageIndex(-1);
      onCrop(arr);
    } else {
      setCurrentImageIndex(currentImageIndex + 1);
      const currentImage = images[index];
      galleryCropRef.current.src = currentImage;
    }
  };

  // console.log("imagesimagesimages----", type);

  const getCropData = () => {
    if (typeof profileCropRef.current?.cropper !== "undefined") {
      let cropped_file = profileCropRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL("image/jpeg", 0.6);
      // console.log("cropped_filecropped_filecropped_file------", cropped_file);
      let file = base64StringToFile(
        cropped_file,
        selectedFile?.name,
        selectedFile?.type
      );
      // console.log("filesizefilesize-----------", bytesToKB(file?.size));
      onCrop(file, type);
      setShow(false);
    }
  };
  return (
    <>
      {type == "gallery" ? (
        <>
          <div>
            {selectedFiles?.length != 0 && currentImageIndex != -1 && (
              <Modal
                show={show}
                onHide={() => setShow(false)}
                centered
                size="md"
              >
                <Modal.Header closeButton></Modal.Header>
                {selectedFiles?.map((image, index) => {
                  return (
                    currentImageIndex == index && (
                      <>
                        <Modal.Body key={index}>
                          <Cropper
                            style={{ height: "auto", width: "auto" }}
                            ref={galleryCropRef}
                            src={selectedFiles?.[currentImageIndex]?.uri}
                            // aspectRatio={18 / 12}
                            zoomTo={0}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            viewMode={1}
                            background={false}
                            responsive={true}
                            autoCropArea={5}
                            checkOrientation={false}
                            guides={true}
                          />
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={() => setShow(false)}
                          >
                            Cancel
                          </Button>
                          <Button
                            key={index}
                            variant="primary"
                            onClick={() => handleImageCrop(image, index)}
                          >
                            Crop
                          </Button>
                          <Button
                            key={index}
                            variant="primary"
                            onClick={() => handleImageCrop(image, index)}
                          >
                            Next
                          </Button>
                        </Modal.Footer>
                      </>
                    )
                  );
                })}
              </Modal>
            )}
          </div>
        </>
      ) :  (
        <Modal show={show} onHide={() => setShow(false)} centered size="md">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div>
              <div style={{ width: "100%" }}>
                <Cropper
                  ref={profileCropRef}
                  style={{ height: "auto", width: "auto" }}
                  zoomTo={0.1}
                  initialAspectRatio={1}
                  preview=".img-preview"
                  src={imageSrc}
                  viewMode={1}
                  background={false}
                  responsive={true}
                  autoCropArea={5}
                  checkOrientation={false}
                  guides={true}
                  aspectRatio={ 1 / 1}
                />
              </div>

              <br style={{ clear: "both" }} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => getCropData()}>
              Crop
            </Button>
           
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ImageCropper;
