import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { DropdownList } from "./DropdownList";
import { InputField } from "./InputField";
import {
  addDirectJobPost,
  getProjectSingleClientList,
} from "../Redux/Services/OtherServices";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../Utility";

const options = [];

export const BookTalentList = (props) => {
  const dispatch = useDispatch();
  const { receiverId, setShow, setIsJobForm, setLoading } = props;

  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);

  const [selectedProject, setSelectedProject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [jobDetails, setJobDetails] = useState([]);

  const handleForm = async () => {
    setError(true);
    if (!selectedProject) {
      showToast(
        "You need to select any job first or create new job if not exist",
        "error"
      );
      return;
    }
    if (!message) {
      showToast("Message is required", "error");
      return;
    }
    setError(false);
    let body = {
      user_id: loggedInUser?.user_data?.user_id || "",
      receiver_id: receiverId || "",
      action: "job_offer" /*direct_offer, job_offer*/,
      message: message || "",
      project_id: selectedProject || "",
    };
    setLoading(true)
    try {
      const res = await dispatch(addDirectJobPost(body));
      setLoading(false)
      if (res?.status === 200) {
        showToast("Job send successfully", "success");
        setShow(false);
      }
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  };

  const handleSelectOption = (e) => {
    if (e.target.value === "add") {
      setIsJobForm(true);
    } else {
      setSelectedProject(e.target.value);
    }
  };

  const getClientJobs = async () => {
    let body = {
      user_id: loggedInUser?.user_data?.user_id || "",
    };
    setLoading(true)
    let res = await dispatch(getProjectSingleClientList(body));
    setLoading(false)
    if (res?.status == 200) {
      setJobDetails(res?.results?.list || []);
    }
  };

  useEffect(() => {
    if (loggedInUser?.user_data?.user_id) {
      getClientJobs();
    }
  }, [loggedInUser]);

  return (
    <Container>
      {/* <div className="registerForm full-size"> */}
      <div className="verification_contain_form wt-email-cont">
        <div>
          <div class="form-secti">
            <Form.Group>
              <Form.Label>
                <span className="astrik_req">*</span>Add New Project
              </Form.Label>
              <Form.Select
                className="form-control"
                onChange={(e) => {
                  handleSelectOption(e);
                }}
                value={selectedProject}
                isValid={!error && selectedProject}
              >
                <option value="">Create a new project or select an existing one if exist</option>
                {jobDetails?.length &&
                  jobDetails?.map((item, index) => {
                    return (
                      <>
                        <option
                          value={item?.profile?.ID}
                          label={item?.profile?.post_title}
                        >
                          {item?.profile?.post_title}
                        </option>
                       
                      </>
                    );
                  })}
                   <option value="add" label="Add New Project">
                          Add New Project{" "}
                        </option>
              </Form.Select>

              {/* {required && value?.length === 0 ? (
          <Form.Control.Feedback type={"invalid"}>
            This field is required !
          </Form.Control.Feedback>
        ) : null} */}
            </Form.Group>
            <div class="form-group">
              <InputField
                required
                title="Message"
                placeholder="Type your Message"
                type="textarea"
                value={message}
                onChangeText={(e) => setMessage(e?.target?.value)}
                // isValid={!error && message}
              />
            </div>
            <div>
            </div>
            <Button
              class="wt-btn hide_org"
              onClick={handleForm}
              type="button"
            >
              Send booking request
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};
