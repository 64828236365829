import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const DeleteChat = (props) => {
  const { firstClick, secondClick, secondTitle, show, setShow, firstTitle } =
    props;
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
          Delete Message
      </Modal.Header>
      <Modal.Body className="dltbtns">
        {secondTitle ? <p onClick={secondClick}>{secondTitle}</p> : undefined}
        {firstTitle ? <p onClick={firstClick}>{firstTitle}</p> : undefined}
      </Modal.Body>
      {/* <Modal.Footer className="footer-center">
        <Button
          onClick={() => {
            setShow(false);
          }}
        >
          Cancel
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};
