import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { InputField } from "../InputField";
import { useEffect, useState } from "react";
import {
  isValidEmail,
  passwordPattern,
  routeName,
  showToast,
} from "../../Utility";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../Redux/Services/AuthServices";
import { Loader } from "../Loader";
import { getData, storageKey } from "../../Utility/Storage";
import { ForgotPassword } from "./ForgotPassword";
import { OtpVerify } from "./OtpVerify";
import { ResetPassword } from "./ResetPassword";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { auth, db } from "../../Utility/firebase";
import { doc, setDoc } from "firebase/firestore";
import { addFirebaseUid } from "../../Redux/Services/OtherServices";

export const Login = (props) => {
  const { setShow, getUserData } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const authData = useSelector((state) => state?.authReducer);

  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [modalType, setModalType] = useState("login");
  const [response, setResponse] = useState("");
  const [code, setCode] = useState("");

  const emailValid = isValidEmail(email);
  const passwordValid = passwordPattern(password);

  useEffect(() => {
    getRememberCreds();
  }, [checked]);

  const getRememberCreds = async () => {
    let rememberCreds = await getData(storageKey.CREDS);
    let data = JSON.parse(rememberCreds);
    if (data) {
      setChecked(true);
      setEmail(data?.email);
      setPassword(data?.password);
    }
  };

  const handleForm = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      handleLogin();
    }
  };

  const handleFirebaseLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, 'AIzaSyC7DgkbTD-uT_KenPEeQvkJmxLMxJhsZdY');
      navigate(routeName.DASHBOARD);
    } catch (err) {
      
    }
  };

  const handleFirebaseRegister = async (val) => {
    // setLoading(true);
    const displayName = val?.full_name;

    try {
      //Create user
      const res = await createUserWithEmailAndPassword(auth, email, 'AIzaSyC7DgkbTD-uT_KenPEeQvkJmxLMxJhsZdY');

      await updateProfile(res.user, {
        displayName,
        photoURL: val?.profile_image,
      });

      await setDoc(doc(db, "users", res.user.uid), {
        uid: res.user.uid,
        displayName: displayName,
        email,
        photoURL: val?.profile_image,
      });

      //create empty user chats on firestore
      await setDoc(doc(db, "userChats", res.user.uid), {});

      let body = {
        user_id: val.id,
        chat_udi: res.user.uid,
      };

      let response = await dispatch(addFirebaseUid(body));

      if (response?.status === 200) {
        navigate(routeName.DASHBOARD);
      }
    } catch (err) {}
  };

  const handleLogin = async () => {
    if(emailValid){
      showToast("Please enter a valid email address", "error");
      return
    }
    var body = {
      email: email.trim(),
      password: password,
    };
    let res = await dispatch(userLogin(body));
    if (res?.status == 200) {
      setShow(false);
      getUserData();
      // console.log(res?.results, ">>>>>>>");
      let modelType;
      if (res?.results?.user_role == 12) {
        modelType = "Client";
      } else if (res?.results?.user_role === 13) {
        modelType = "Photographer";
      } else if (res?.results?.model_type == "model") {
        modelType = "Model";
      } else if (
        res?.results?.model_type == "child" &&
        res?.results?.user_role === 11
      ) {
        modelType = "Model Kid";
      } else if (
        res?.results?.model_type == "photographer" ||
        res?.results?.user_role === 13
      ) {
        modelType = "Photographer";
      } else if (
        res?.results?.user_role == 15 &&
        res?.results?.model_type == "actor"
      ) {
        modelType = "Actor";
      } else if (
        res?.results?.user_role == 15 &&
        res?.results?.model_type == "child"
      ) {
        modelType = "Actor Kid";
      } else if (res?.results?.user_role == 14) {
        modelType = "Partners";
      }

      if (
        (res?.results?.completed_step == 1 ||
          res?.results?.completed_step == 2) &&
        res?.results?.user_role != 14
      ) {
        navigate(
          `${routeName?.REGISTER}?email=${res?.results?.email}&type=${modelType}`,
          {
            state: { prevRoute: routeName?.LOGIN, data: res?.results },
          }
        );
      } else if (
        res?.results?.user_role == 14 &&
        !res?.results?.profile_approval
      ) {
        navigate(
          `${routeName?.REGISTER}?email=${res?.results?.email}&type=${modelType}`,
          {
            state: { prevRoute: routeName?.LOGIN, data: res?.results },
          }
        );
      } else {
       
        if (res?.results?.firebase_udi) {
          handleFirebaseLogin();
          
        } else {
          handleFirebaseRegister(res?.results);
        }
      }
    }
  };
  const handleRemember = () => {
    if (!email || !password) {
      showToast(
        "Please fill the Email and Password fields to remember!",
        "error"
      );
    } else if (email && password) {
      setChecked(!checked);
    }
    if (email && password && !checked) {
      var rememberCreds = {
        password: password,
        email: email,
      };
      localStorage.setItem(storageKey.CREDS, JSON.stringify(rememberCreds));
    } else if (checked && email && password) {
      localStorage.removeItem(storageKey.CREDS, JSON.stringify(rememberCreds));
    }
  };

  // console.log(modalType,"llllkkk")

  return (
    <div>
      <Loader loading={authData?.isLoading} />
      <div className="modal show" style={{ display: "block" }}>
        <Modal.Dialog centered>
          <Modal.Header
            closeButton
            onHide={() => {
              setShow(false);
            }}
          >
            <Modal.Title>
              {modalType === "forget"
                ? "Forgot Password"
                : modalType === "otp"
                ? "Verify Otp"
                : modalType === "reset"
                ? "Reset Password"
                : "Sign In Now"}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="Loginform">
              {modalType === "login" ? (
                <>
                  <div
                    className="signUp"
                    onClick={() => navigate(routeName?.SIGN_UP)}
                  >
                    Don’t have an account? <a>Sign up</a>
                  </div>
                  <Form noValidate validated={validated} onSubmit={handleForm}>
                    <InputField
                      required
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      onChangeText={(e) => {
                        setEmail(e?.target?.value);
                      }}
                      isValid={validated && emailValid}
                      page="auth"
                    />
                    <InputField
                      required
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChangeText={(e) => {
                        setPassword(e.target.value);
                      }}
                      page="auth"
                      isValid={validated && passwordValid}
                      // page="auth"
                    />

                    <Form.Group
                      controlId="formBasicCheckbox"
                      onClick={() => handleRemember()}
                      // defaultChecked
                    >
                      <Form.Check
                        type={"checkbox"}
                        disabled={email && password ? false : true}
                        label="Remember Me"
                        defaultChecked={checked}
                      />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      Sign In
                    </Button>
                  </Form>
                  <div
                    className="forgetPwd"
                    onClick={() => setModalType("forget")}
                  >
                    Forgot Password{" "}
                  </div>
                </>
              ) : modalType === "forget" ? (
                <ForgotPassword
                  setModalType={setModalType}
                  modalType={modalType}
                  setResponse={setResponse}
                  setShow={setShow}
                />
              ) : modalType === "otp" ? (
                <OtpVerify
                  modalType={modalType}
                  response={response}
                  setModalType={setModalType}
                  code={code}
                  setCode={setCode}
                />
              ) : modalType === "reset" ? (
                <ResetPassword
                  setModalType={setModalType}
                  response={response}
                  code={code}
                />
              ) : undefined}
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    </div>
  );
};
