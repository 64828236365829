import React, { useEffect, useState } from "react";
import SidebarLayout from "../../layout/SidebarLayout";
import { DropdownList, InputField, Loader } from "../../Components";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import {
  deleteUserAccount,
  getAccountSettingDetails,
} from "../../Redux/Services/OtherServices";
import { clearData, getData, storageKey } from "../../Utility/Storage";
import { routeName, showToast } from "../../Utility";
import { useNavigate } from "react-router-dom";
import {
  getUserDetail,
  resetPassword,
} from "../../Redux/Services/AuthServices";
import { Colors } from "../../Constants";
import { Form, Button, Modal } from "react-bootstrap";
import { addPartnerAccessApi } from "../../Redux/Services/PartnerServices";
import { loggedInUsedAction } from "../../Redux/Actions/AuthActions";
import { partnerAccessPermissions } from "../../Redux/Actions/PartnerActions";
export const AccountSetting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.authReducer.loggedInUser);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [modal, setModal] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    Number(loggedInUser?.user_data?.user_role) === 10
      ? "change-password"
      : "security-settings"
  );
  const [validated, setValidated] = useState(false);
  const [reason, setReason] = useState("");
  const [blockAccount, setblockAccount] = useState({
    password: "",
    confirmPassword: "",
    describe: "",
  });
  const [selectedPartner, setSelectedPartner] = useState("");

  const handleSelect = (key) => {
    setSelectedTab(key);
  };
  const [accountSwitches, setAccountSwitches] = useState({
    disableAccount: false,
    hourlyRate: false,
    projectNotification: true,
    messages: false,
    hideAdult: false,
    jobNotification: true
  });
  const [checkedItems, setCheckedItems] = useState({
    all: true,
  });

  useEffect(() => {
    getUserData();
    getAccountDetails();
  }, []);

  const getUserData = async () => {
    let userID = await getData(storageKey?.USER_ID);
    if (userID) {
      let body = {
        user_id: JSON?.parse(userID),
      };
      await dispatch(getUserDetail(body));
    }
  };

  const handleResetPassword = async () => {
    var body = {
      user_email: loggedInUser?.user_data?.user_email,
      old_password: password,
      new_password: newPassword,
      new_re_password: newPassword,
    };
    setLoading(true);
    let res = await dispatch(resetPassword(body));
    setLoading(false);
    if (res?.status == 200) {
      //   navigation.goBack();
    }
  };

  const deleteAccount = async () => {
    let userID = await getData(storageKey?.USER_ID);
    var body = {
      user_id: userID,
      action: "delete",
      password: blockAccount?.password,
      reason: reason,
      description: blockAccount?.describe,
    };
    setLoading(true);
    let res = await dispatch(deleteUserAccount(body));
    setLoading(false);
    console.log("deleteAccount resres------", res);
    if (res?.status == 200) {
      logout();
    }
  };

  const logout = async () => {
    clearData();
    dispatch(loggedInUsedAction(null));
    dispatch(partnerAccessPermissions(null));
    getUserData();
    navigate(routeName?.HOME);
  };

  const getAccountDetails = async () => {
    let userID = await getData(storageKey?.USER_ID);
    var body = {
      user_id: userID,
      action: "get",
    };
    setLoading(true);
    let res = await dispatch(getAccountSettingDetails(body, true));
    setLoading(false);
    // console.log("res?.results-----", res?.results);
    if (res?.status == 200) {
      setAccountSwitches({
        ...accountSwitches,
        disableAccount:
          res?.results?.profile_blocked == "off" ||
          res?.results?.profile_blocked == ""
            ? false
            : true,
        hourlyRate:
          res?.results?.hourly_rate_settings == "off" ||
          res?.results?.hourly_rate_settings == ""
            ? false
            : true,
        projectNotification:
          res?.results?.project_notification == "off" ||
          res?.results?.project_notification == ""
            ? false
            : true,
        messages:
          res?.results?.allow_recived_messages == "off" ||
          res?.results?.allow_recived_messages == ""
            ? false
            : true,
        hideAdult:
          res?.results?.hide_adult_profile == "off" ||
          res?.results?.hide_adult_profile == ""
            ? false
            : true,
      });
    }
  };

  const updateAccountDetails = async () => {
    let userID = await getData(storageKey?.USER_ID);
    var body = {
      user_id: userID,
      action: "update",
      profile_blocked: accountSwitches?.disableAccount ? "on" : "off",
      delete_account: "off",
      hourly_rate_settings: accountSwitches?.hourlyRate ? "on" : "off",
      project_notification: accountSwitches?.projectNotification ? "on" : "off",
      allow_recived_messages: accountSwitches?.messages ? "on" : "off",
      hide_adult_profile: accountSwitches?.hideAdult ? "on" : "off",
    };
    setLoading(true);
    let res = await dispatch(getAccountSettingDetails(body, false));
    setLoading(false);
    if (res?.status == 200) {
      getAccountDetails();
    }
  };

  const settingsOptions = [
    {
      name: "Pause my account",
      type: "pause-account",
      checked: accountSwitches?.disableAccount ? true : false,
      onclick: () => {
        setAccountSwitches({
          ...accountSwitches,
          disableAccount: !accountSwitches?.disableAccount,
        });
      },
    },
    {
      name: "Hide your rate on your profile",
      type: "disable-rate",
      checked: accountSwitches?.hourlyRate ? true : false,
      onclick: () => {
        setAccountSwitches({
          ...accountSwitches,
          hourlyRate: !accountSwitches?.hourlyRate,
        });
      },
    },
    {
      name: "Hide adult social post",
      type: "hide-post",
      checked: accountSwitches?.hideAdult ? true : false,
      onclick: () => {
        setAccountSwitches({
          ...accountSwitches,
          hideAdult: !accountSwitches?.hideAdult,
        });
      },
    },
    {
      name: "Would you like to receive SMS messages from clients and Book Sculp?",
      type: "sms-notification",
      checked: accountSwitches?.messages ? true : false,
      onclick: () => {
        setAccountSwitches({
          ...accountSwitches,
          messages: !accountSwitches?.messages,
        });
      },
    },
    {
      name: "Would you like to receive Job Notifications?",
      type: "project_notification",
      checked: accountSwitches?.projectNotification ? true : false,
      onclick: () => {
        setAccountSwitches({
          ...accountSwitches,
          projectNotification: !accountSwitches?.projectNotification,
        });
      },
    },
  ];

  const handleChangePassword = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      handleResetPassword();
    }
  };

  const handleDeleteAccountForm = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else if (form.checkValidity() === true) {
      setModal(true);
    }
  };

  const reasons = [
    {
      label: "No longer using this account",
      value: "No longer using this account",
    },
    {
      label: "I have privacy concern",
      value: "I have privacy concern",
    },
    {
      label: "Account inactivity",
      value: "Account inactivity",
    },
  ];

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const newCheckedItems = { ...checkedItems };

    if (name === "all") {
      // If "All" is checked, set all checkboxes to true
      titles.forEach((item) => {
        if (!item.hide) {
          newCheckedItems[item.title] = checked;
        }
      });
    } else {
      newCheckedItems[name] = checked;
      if (!checked) {
        newCheckedItems["all"] = false;
      } else {
        const allChecked = titles.every(
          (item) => item.hide || newCheckedItems[item.title]
        );
        newCheckedItems["all"] = allChecked;
      }
    }

    setCheckedItems(newCheckedItems);
  };

  const handleSave = async () => {
    const newStates = {};
    titles.forEach((item) => {
      newStates[item?.title] = checkedItems[item?.title] ? "on" : "off";
    });
    // console.log(newStates);
    try {
      let body = {
        user_id: loggedInUser?.user_data?.user_id,
        action: "update",
        manage_projects: "on",
      };
      Object.entries(newStates).forEach(([key, value]) => {
        body[key] = value;
      });
      setLoading(true);
      const res = await dispatch(addPartnerAccessApi(body));
      setLoading(false);
      if (res?.status === 200) {
        showToast("Agency Access Updated Successfully", "success");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleGetPermissions = async () => {
    try {
      let body = {
        user_id: loggedInUser?.user_data?.user_id,
        action: "get",
      };
      setLoading(true);
      const res = await dispatch(addPartnerAccessApi(body));
      setLoading(false);
      if (res?.status === 200) {
        const permissions = res?.results;
        const newCheckedItems = {};
        titles.forEach((item) => {
          if (!item.hide) {
            newCheckedItems[item.title] = permissions[item.title] === "on";
          }
        });
        setCheckedItems(newCheckedItems);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const titles = [
    {
      name: "All",
      title: "all",
    },
    // {
    //   name: "Inbox",
    //   title: "manage_chat",
    // },
    {
      name:
        Number(loggedInUser?.user_data?.user_role) === 12 ||
        Number(loggedInUser?.user_data?.user_role) === 14
          ? "Post Casting Call"
          : "Manage Portfolio",
      title: "manage_portfolio",
      hide:
        // Number(loggedInUser?.user_data?.user_role) === 12 ||
        // Number(loggedInUser?.user_data?.user_role) === 14
        //   ? true
        //   :
        false,
    },
    {
      name:
        Number(loggedInUser?.user_data?.user_role) === 12 ||
        Number(loggedInUser?.user_data?.user_role) === 14
          ? "Job Post"
          : "Manage Social Post",
      title: "manage_posts",
      hide: false,
      // hide:
      //   Number(loggedInUser?.user_data?.user_role) === 12 ||
      //   Number(loggedInUser?.user_data?.user_role) === 14
      //     ? true
      //     : false,
    },
    {
      name: "Saved Collections",
      title: "manage_saved_collection",
      hide: false,
    },
    {
      name: "Social Connections",
      title: "manage_follow_details",
      hide: false,
    },
  ];

  useEffect(() => {
    if (selectedTab === "agency-access") {
      handleGetPermissions();
    }
  }, [selectedTab]);

  return (
    <SidebarLayout>
      <Loader loading={loading} />
      <div class="searchtalentList dashboard_main">
        <div className="myJobs">
          <Tabs
            defaultActiveKey="security-settings"
            activeKey={selectedTab}
            onSelect={handleSelect}
            className="mb-3"
          >
            {Number(loggedInUser?.user_data?.user_role) === 10 ? undefined : (
              <Tab eventKey="security-settings" title="Security & Settings">
                <div className="wt-securityhold">
                  <div className="wt-securitysettings wt-tabsinfo accInfoIcon">
                    <div className="wt-tabscontenttitle">
                      <h2>Account Security &amp; Settings</h2>
                    </div>
                    <div className="wt-settingscontent">
                      <div className="wt-description">
                        <p>
                          To hide your profile all over the site you can disable
                          your profile temporarily
                        </p>
                      </div>
                      <ul className="wt-accountinfo">
                        {settingsOptions?.map((item, index) => {
                          return (
                            <>
                              <li>
                                <i
                                  onClick={() => item?.onclick()}
                                  class={
                                    item?.checked
                                      ? "fa fa-toggle-on"
                                      : "fa fa-toggle-off"
                                  }
                                  style={{
                                    fontSize: 30,
                                    color: item?.checked
                                      ? Colors?.green
                                      : Colors?.grey,
                                  }}
                                  aria-hidden="true"
                                ></i>
                                <span>{item?.name}</span>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <div
                    className="form-group form-group-half wt-btnarea"
                    onClick={() => updateAccountDetails()}
                  >
                    <a className="wt-btn">Save</a>
                  </div>
                </div>
              </Tab>
            )}
            <Tab eventKey="change-password" title="Change Password">
              <div className="wt-changepassword">
                <div className="wt-tabscontenttitle">
                  <h2>Change Your Password</h2>
                </div>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleChangePassword}
                  className="changePwd"
                >
                  <div className="wt-formtheme wt-userform">
                    <div className="form-group form-group-half">
                      <InputField
                        required
                        title="Old Password"
                        type="password"
                        placeholder="Enter Password"
                        value={password}
                        onChangeText={(e) => {
                          setPassword(e?.target?.value);
                        }}
                      />
                    </div>
                    <div className="form-group form-group-half">
                      <InputField
                        required
                        title="New Password"
                        type="password"
                        placeholder="Enter Password"
                        value={newPassword}
                        onChangeText={(e) => {
                          setNewPassword(e?.target?.value);
                        }}
                      />
                    </div>
                  </div>
                  <Button
                    variant=" wt-btn"
                    class="wt-btn hide_org"
                    type="submit"
                  >
                    Save
                  </Button>
                </Form>
              </div>
            </Tab>
            {Number(loggedInUser?.user_data?.user_role) === 10 ? undefined : (
              <Tab eventKey="delete-account" title="Delete Account">
                <div className="wt-accountdel">
                  <div className="wt-tabscontenttitle">
                    <h2>Delete Account</h2>
                  </div>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleDeleteAccountForm}
                  >
                    <div className="form-group">
                      <InputField
                        required
                        title="Password"
                        type="password"
                        className="form-group"
                        placeholder="Enter Password"
                        value={blockAccount?.password}
                        onChangeText={(e) => {
                          setblockAccount({
                            ...blockAccount,
                            password: e?.target?.value,
                          });
                        }}
                      />
                    </div>

                    {/* <div className="form-group form-group-half">
                      <InputField
                        required
                        title="New Password"
                        type="password"
                        placeholder="Enter Password"
                        value={blockAccount?.confirmPassword}
                        onChangeText={(e) => {
                          setblockAccount({
                            ...blockAccount,
                            confirmPassword: e?.target?.value,
                          });
                        }}
                      />
                    </div> */}
                    <div className="form-group">
                      <DropdownList
                        required
                        title="Select Reason to Leave"
                        placeholder="Select Reason to Leave"
                        options={reasons}
                        value={reason}
                        setValue={setReason}
                      />
                    </div>
                    <div className="form-group">
                      <InputField
                        title="Description (Optional)"
                        type="textarea"
                        placeholder="Enter Description"
                        value={blockAccount?.describe}
                        onChangeText={(e) => {
                          setblockAccount({
                            ...blockAccount,
                            describe: e?.target?.value,
                          });
                        }}
                      />
                    </div>

                    <Button
                      variant=" wt-btn"
                      class="wt-btn hide_org"
                      type="submit"
                    >
                      Delete Account
                    </Button>
                  </Form>
                </div>
              </Tab>
            )}
            {Number(loggedInUser?.user_data?.user_role) === 10 ? undefined : (
              <Tab eventKey="agency-access" title="Agency Access">
                <div className="wt-accountdel">
                  <div className="wt-tabscontenttitle">
                    <h2>
                    {loggedInUser?.user_data?.reffer_by?.partner_id ? ( 
<>
                      You are listed with{" "}
                      <b
                        onClick={() => {
                          const queryParams = new URLSearchParams({
                            id: loggedInUser?.user_data?.reffer_by
                              ?.partner_user_id,
                          });
                          navigate(
                            `${
                              routeName.CLIENT_PROFILE
                            }?${queryParams.toString()}`
                          );
                        }}
                      >
                        {loggedInUser?.user_data?.reffer_by?.partner_name || ""}
                      </b>{" "}
                      </> ) : (
                        <>
                      You are not listed with any agency.
                      </>
                      )}
                    </h2>


                    {/* <span>Allow BookSculp to access your contacts</span> */}
                  </div>
                  {loggedInUser?.user_data?.reffer_by?.partner_id ? (
                    <div>
                      <p>
                        Allow{" "}
                        <b
                          onClick={() => {
                            const queryParams = new URLSearchParams({
                              id: loggedInUser?.user_data?.reffer_by
                                ?.partner_user_id,
                            });
                            navigate(
                              `${
                                routeName.CLIENT_PROFILE
                              }?${queryParams.toString()}`
                            );
                          }}
                        >
                          {loggedInUser?.user_data?.reffer_by?.partner_name ||
                            ""}
                        </b>{" "}
                        to access your account
                      </p>

                      <div className="agencyAccess">
                        {titles.map((item, index) => (
                          <div key={index}>
                            {item?.hide ? undefined : (
                              <div>
                                <input
                                  type="checkbox"
                                  name={item?.title}
                                  checked={checkedItems[item?.title] || false}
                                  onChange={handleCheckboxChange}
                                />
                                <label>{item?.name}</label>
                              </div>
                            )}
                          </div>
                        ))}
                        <div className="">
                          <div>
                            <input
                              type="checkbox"
                              name={"Manage Jobs"}
                              checked={true}
                              onChange={() => {}}
                            />
                            <label>Manage Jobs</label>
                          </div>
                        </div>
                      </div>
                      <Button
                        variant=" wt-btn"
                        class="wt-btn hide_org"
                        type="submit"
                        onClick={handleSave}
                      >
                        Submit
                      </Button>
                    </div>
                  ) : (
                    <div className="col-md-5">
                      <p>
                        Do you want to join any agency?
                      </p>
                      <div>
                        <select
                          name="type"
                          id="type"
                          className="form-control"
                          onChange={(e) => setSelectedPartner(e.target.value)}
                          value={selectedPartner}
                        >
                          <option disabled value={""}>
                            Select Agency
                          </option>
                          {/* {allOptionData?.cancelReason?.length
                  ? allOptionData?.cancelReason?.map((item) => (
                      <option ke={item?.label} value={item?.value}>
                        {item?.label}
                      </option>
                    ))
                  : undefined} */}
                        </select>
                      </div>
                    </div>
                  )}
                </div>
              </Tab>
            )}
          </Tabs>
        </div>
      </div>
      <Modal show={modal} onHide={() => setModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You want to delete the account permanently? You will not be able to
          login this account if you delete the account.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModal(false)}>
            Not Now
          </Button>
          <Button variant="primary" onClick={() => deleteAccount()}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </SidebarLayout>
  );
};

{
  /* <ul className="wt-accountinfo">
<li>
  <div className="wt-on-off">
    <input
      type="checkbox"
      id="hide-on"
      name="contact_form"
    />
    <label htmlFor="hide-on">
      <i />
    </label>
  </div>
  <span>Make my profile public</span>
</li>
<li>
  <div className="wt-on-off pull-right">
    <input
      type="checkbox"
      id="hide-onone"
      name="contact_form"
    />
    <label htmlFor="hide-onone">
      <i />
    </label>
  </div>
  <span>Make my profile searchable</span>
</li>
<li>
  <div className="wt-on-off pull-right">
    <input
      type="checkbox"
      id="hide-onthree"
      name="contact_form"
      defaultChecked=""
    />
    <label htmlFor="hide-onthree">
      <i />
    </label>
  </div>
  <span>Share my profile photo</span>
</li>
<li>
  <div className="wt-on-off pull-right">
    <input
      type="checkbox"
      id="hide-onfour"
      name="contact_form"
      defaultChecked=""
    />
    <label htmlFor="hide-onfour">
      <i />
    </label>
  </div>
  <span>Disable my account temporarily</span>
</li>
<li>
  <div className="wt-on-off pull-right">
    <input
      type="checkbox"
      id="hide-onfive"
      name="contact_form"
    />
    <label htmlFor="hide-onfive">
      <i />
    </label>
  </div>
  <span>Show my client feedback</span>
</li>
<li>
  <div className="wt-on-off pull-right">
    <input
      type="checkbox"
      id="hide-onsix"
      name="contact_form"
    />
    <label htmlFor="hide-onsix">
      <i />
    </label>
  </div>
  <span>Enable/ Disable</span>
</li>
</ul>
  <div className="wt-location wt-tabsinfo">
                  <div className="wt-tabscontenttitle">
                    <h2>Language &amp; Currency</h2>
                  </div>
                  <form className="wt-formtheme wt-userform">
                    <fieldset>
                      <div className="form-group form-group-half">
                        <span className="wt-select">
                          <select>
                            <option value="">Select System Language</option>
                            <option value="">English</option>
                            <option value="">French</option>
                            <option value="">Spanish</option>
                            <option value="">Japanese</option>
                            <option value="">Arabic </option>
                          </select>
                        </span>
                      </div>
                      <div className="form-group form-group-half">
                        <span className="wt-select">
                          <select>
                            <option value="">Select Currency</option>
                            <option value="">Brazilian Real</option>
                            <option value="">US Dollar</option>
                            <option value="">Yuan Renminbi</option>
                            <option value="">Colombian Peso</option>
                            <option value="">Euro</option>
                            <option value="">Hong Kong Dollar</option>
                          </select>
                        </span>
                      </div>
                    </fieldset>
                  </form>
                </div>
                <div className="wt-tabcompanyinfo">
                  <div className="wt-tabscontenttitle">
                    <h2>Dashboard Color Settings</h2>
                  </div>
                  <div className="wt-settingscontent">
                    <div className="wt-description">
                      <p>
                        Incididunt ut labore et dolore magna aliqua aut enim ad
                        exercitation ullamco laboris.
                      </p>
                    </div>
                    <ul className="wt-accountinfo">
                      <li>
                        <div className="wt-on-off">
                          <input
                            type="checkbox"
                            id="hide-on1"
                            name="contact_form"
                          />
                          <label htmlFor="hide-on1">
                            <i />
                          </label>
                        </div>
                        <span>Use dark version for my dashboard</span>
                      </li>
                    </ul>
                  </div>
                </div>


*/
}
