import React from "react";
import { ImageCropper } from "../../Components";
import { bytesToKB } from "../../Utility";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { ImageInfoModal } from "../../Components/Popups";

const UserImages = ({
  handleChooseProfile,
  selectedFile,
  setSelectedFile,
  cropped,
  setCropped,
  uploadProfilePhoto,
  profileImage,
  setProfileImage,
  removeGalleryImage,
  selectedFiles,
  setSelectedFiles,
  galleryCropper,
  setGalleryCropper,
  images,
  setImages,
  handleChooseGallery,
  talent,
  handleGallerySubmit,
  setAngryData,
  AngryData,
  uploadWaistphoto,
  angry,
  setangry,
  happy,
  setHappy,
  happyData,
  setHappyData,
  shoulderDownImage,
  setShoulderDownImage,
  shoulderUpImage,
  setShoulderUpImage,
  shoulderDownImageData,
  shoulderUpImageData,
  sad,
  sadData,
  setSad,
  setSadData,
  waistImage,
  waistImageData,
  setWaistImageData,
  setWaistImage,
  steps,
  handleChooseImages,
  setShoulderDownImageData,
  setShoulderUpImageData,
  page,
  imageType,
  setImageType,
  setIsImageInfoModal,
  isImageInfoModal,
}) => {
  const auth = useSelector((state) => state?.authReducer);
  return (
    <>
      {steps == 3 ? (
        <>
          <div class="form-section">
            <div className="form-section-title">Profile Photo</div>
            <div class="wt-bannerphoto wt-tabsinfo">
              <div class="wt-profilephotocontent">
                <form class="wt-formtheme wt-formprojectinfo wt-formcategory">
                  <div class="form-group form-group-label">
                    <div
                      onClick={() => {
                        if (page === "register") {
                          setIsImageInfoModal(true);
                          setImageType("profile");
                        }
                      }}
                      class="wt-labelgroup"
                    >
                      <label for={page === "register" ? "" : "filep"}>
                        <span class="wt-btn">
                          {page === "register" ? "Upload" : "Select Files"}
                        </span>
                        {page === "register" ? undefined : (
                          <input
                            type="file"
                            name="file"
                            id="filep"
                            onChange={handleChooseProfile}
                          />
                        )}
                      </label>
                      <span>Choose file to upload</span>
                      {auth?.isLoading && (
                        <em class="wt-fileuploading">
                          Uploading
                          <i class="fa fa-spinner fa-spin"></i>
                        </em>
                      )}
                    </div>
                  </div>
                  {selectedFile && (
                    <ImageCropper
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                      imageSrc={selectedFile?.uri}
                      type={"profile"}
                      show={cropped}
                      setShow={setCropped}
                      cropped={cropped}
                      setCropped={setCropped}
                      profileImage={profileImage}
                      setProfileImage={setProfileImage}
                      onCrop={uploadProfilePhoto}
                    />
                  )}
                  {profileImage && (
                    <div class="form-group">
                      <ul class="wt-attachfile wt-attachfilevtwo">
                        <li class="wt-uploadingholder">
                          <div class="wt-uploadingbox">
                            <div class="wt-designimg">
                              <input
                                id="demoq"
                                type="radio"
                                name="employees"
                                value="company"
                              />
                              <label for="demoq">
                                <img
                                  src={profileImage?.uri}
                                  alt="Hire talent | Model near me | Talent acquisition | Modeling agencies near me | Modeling Jobs | Modeling companies near me"
                                />
                                <i class="fa fa-check"></i>
                              </label>
                            </div>
                            <div class="wt-uploadingbar">
                              <span class="uploadprogressbar"></span>
                              <span>{profileImage?.name}</span>
                              <em>
                                {profileImage?.size && (
                                  <>
                                    File size: {bytesToKB(profileImage?.size)}
                                  </>
                                )}
                                <i
                                  class="fa fa-times lnr lnr-cross"
                                  aria-hidden="true"
                                  onClick={() =>
                                    removeGalleryImage(profileImage, "profile")
                                  }
                                ></i>
                              </em>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
          {talent == "Client" || talent == "client" || talent == "Partners" ? undefined : (
            <div class="form-section">
              {selectedFiles?.length != 0 && (
                <ImageCropper
                  selectedFiles={selectedFiles}
                  setSelectedFiles={setSelectedFiles}
                  type={"gallery"}
                  show={galleryCropper}
                  setShow={setGalleryCropper}
                  onCrop={handleGallerySubmit}
                  images={images}
                  setImages={setImages}
                />
              )}
              <div
                style={{ justifyContent: "flex-start" }}
                className="form-section-title"
              >
                Gallery
                <Tooltip
                  style={{ marginLeft: 15 }}
                  arrow
                  placement="top-start"
                  title="These images will be displayed on your public gallery"
                >
                  <span className="tooltipOverlay">
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                  </span>
                </Tooltip>
              </div>
              <div class="wt-bannerphoto wt-tabsinfo">
                <div class="wt-profilephotocontent">
                  <div className="fullCol">
                    <span>
                      Upload a minimum of 3 images. Images should be uploaded in
                      jpg/png format.
                    </span>
                  </div>
                  <form class="wt-formtheme wt-formprojectinfo wt-formcategory">
                    <fieldset>
                      <div class="form-group form-group-label">
                        <div
                          onClick={() => {
                            if (page === "register") {
                              setIsImageInfoModal(true);
                              setImageType("gallery");
                            }
                          }}
                          class={
                            images?.length >= 10
                              ? "limit_border"
                              : "wt-labelgroup"
                          }
                        >
                          {images?.length >= 10 ? (
                            <span className="note">
                              Note : You can choose minimum 3 images or maximum
                              10 images.
                            </span>
                          ) : (
                            <>
                              <label for={page === "register" ? "" : "filew"}>
                                <span class="wt-btn">
                                  {page === "register"
                                    ? "Upload"
                                    : "Select Files"}
                                </span>
                                {page === "register" ? undefined : (
                                  <input
                                    type="file"
                                    name="file"
                                    id="filew"
                                    multiple
                                    onChange={handleChooseGallery}
                                    disabled={images?.length >= 10}
                                  />
                                )}
                              </label>
                              <span>Choose files to upload</span>
                              <em class="wt-fileuploading">
                                Uploading
                                <i class="fa fa-spinner fa-spin"></i>
                              </em>
                            </>
                          )}
                        </div>
                      </div>

                      <div class="form-group">
                        <ul class="wt-attachfile wt-attachfilevtwo">
                          {images?.length != 0 &&
                            images?.map((item, index) => {
                              return (
                                <>
                                  <li class="wt-uploadingholder">
                                    <div class="wt-uploadingbox">
                                      <div class="wt-designimg">
                                        <input
                                          id="demoq"
                                          type="radio"
                                          name="employees"
                                          value="company"
                                          checked=""
                                        />
                                        <label for="demoq">
                                          <img
                                            src={
                                              item?.url ? item?.url : item?.uri
                                            }
                                            alt="Hire talent | Model near me | Talent acquisition | Modeling agencies near me | Modeling Jobs | Modeling companies near me"
                                          />
                                          <i class="fa fa-check"></i>
                                        </label>
                                      </div>
                                      <div class="wt-uploadingbar">
                                        <span class="uploadprogressbar"></span>
                                        <span>{item?.name}</span>
                                        <em>
                                          {item?.size && (
                                            <>
                                              File size: {bytesToKB(item?.size)}
                                            </>
                                          )}

                                          <i
                                            class="fa fa-times lnr lnr-cross"
                                            aria-hidden="true"
                                            onClick={() =>
                                              removeGalleryImage(
                                                item,
                                                "gallery"
                                              )
                                            }
                                          ></i>
                                        </em>
                                      </div>
                                    </div>
                                  </li>
                                </>
                              );
                            })}
                        </ul>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          )}

          {talent === "Actor" || talent === "Actor Kid" ? (
            <>
              <div class="form-section">
                <div className="form-section-title">Waist Up Photo</div>
                <div class="wt-bannerphoto wt-tabsinfo">
                  <div class="wt-profilephotocontent">
                    <form class="wt-formtheme wt-formprojectinfo wt-formcategory">
                      <div class="form-group form-group-label">
                        <div
                          onClick={() => {
                            if (page === "register") {
                              setIsImageInfoModal(true);
                              setImageType("waist");
                            }
                          }}
                          class="wt-labelgroup"
                        >
                          <label for={page === "register" ? "" : "filewa"}>
                            <span class="wt-btn">
                              {page === "register" ? "Upload" : "Select Files"}
                            </span>
                            {page === "register" ? undefined : (
                              <input
                                type="file"
                                name="file"
                                id="filewa"
                                onChange={(e) => handleChooseImages(e, "waist")}
                              />
                            )}
                          </label>
                          <span>Choose file to upload</span>
                          {auth?.isLoading && (
                            <em class="wt-fileuploading">
                              Uploading
                              <i class="fa fa-spinner fa-spin"></i>
                            </em>
                          )}
                        </div>
                      </div>
                      {waistImage && (
                        <ImageCropper
                          selectedFile={waistImage}
                          setSelectedFile={setWaistImage}
                          imageSrc={waistImage?.uri}
                          type={"waist_up"}
                          show={cropped}
                          setShow={setCropped}
                          cropped={cropped}
                          setCropped={setCropped}
                          profileImage={waistImageData}
                          setProfileImage={setWaistImageData}
                          onCrop={uploadWaistphoto}
                        />
                      )}
                      {waistImageData && (
                        <div class="form-group">
                          <ul class="wt-attachfile wt-attachfilevtwo">
                            <li class="wt-uploadingholder">
                              <div class="wt-uploadingbox">
                                <div class="wt-designimg">
                                  <input
                                    id="demoq"
                                    type="radio"
                                    name="employees"
                                    value="company"
                                  />
                                  <label for="demoq">
                                    <img
                                      src={waistImageData?.uri}
                                      alt="Hire talent | Model near me | Talent acquisition | Modeling agencies near me | Modeling Jobs | Modeling companies near me"
                                    />
                                    <i class="fa fa-check"></i>
                                  </label>
                                </div>
                                <div class="wt-uploadingbar">
                                  <span class="uploadprogressbar"></span>
                                  <span>{waistImageData?.name}</span>
                                  <em>
                                    {waistImageData?.size && (
                                      <>
                                        File size:{" "}
                                        {bytesToKB(waistImageData?.size)}
                                      </>
                                    )}
                                    <i
                                      class="fa fa-times lnr lnr-cross"
                                      aria-hidden="true"
                                      onClick={() =>
                                        // setProfileImage("")
                                        removeGalleryImage(
                                          waistImageData,
                                          "waist_up"
                                        )
                                      }
                                    ></i>
                                  </em>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>

              <div class="form-section">
                <div className="form-section-title">
                  Shoulder Up, Facing Camera
                </div>
                <div class="wt-bannerphoto wt-tabsinfo">
                  <div class="wt-profilephotocontent">
                    <form class="wt-formtheme wt-formprojectinfo wt-formcategory">
                      <div class="form-group form-group-label">
                        <div
                          onClick={() => {
                            if (page === "register") {
                              setIsImageInfoModal(true);
                              setImageType("shoulderUp");
                            }
                          }}
                          class="wt-labelgroup"
                        >
                          <label for={page === "register" ? "" : "filesu"}>
                            <span class="wt-btn">
                              {page === "register" ? "Upload" : "Select Files"}
                            </span>
                            {page === "register" ? undefined : (
                              <input
                                type="file"
                                name="file"
                                id="filesu"
                                onChange={(e) =>
                                  handleChooseImages(e, "shoulderUp")
                                }
                              />
                            )}
                          </label>
                          <span>Choose file to upload</span>
                          {auth?.isLoading && (
                            <em class="wt-fileuploading">
                              Uploading
                              <i class="fa fa-spinner fa-spin"></i>
                            </em>
                          )}
                        </div>
                      </div>
                      {shoulderUpImage && (
                        <ImageCropper
                          selectedFile={shoulderUpImage}
                          setSelectedFile={setShoulderUpImage}
                          imageSrc={shoulderUpImage?.uri}
                          type={"shoulders_up_straight"}
                          show={cropped}
                          setShow={setCropped}
                          cropped={cropped}
                          setCropped={setCropped}
                          profileImage={shoulderUpImageData}
                          setProfileImage={setShoulderUpImageData}
                          onCrop={uploadWaistphoto}
                        />
                      )}
                      {shoulderUpImageData && (
                        <div class="form-group">
                          <ul class="wt-attachfile wt-attachfilevtwo">
                            <li class="wt-uploadingholder">
                              <div class="wt-uploadingbox">
                                <div class="wt-designimg">
                                  <input
                                    id="demoq"
                                    type="radio"
                                    name="employees"
                                    value="company"
                                  />
                                  <label for="demoq">
                                    <img
                                      src={shoulderUpImageData?.uri}
                                      alt="Hire talent | Model near me | Talent acquisition | Modeling agencies near me | Modeling Jobs | Modeling companies near me"
                                    />
                                    <i class="fa fa-check"></i>
                                  </label>
                                </div>
                                <div class="wt-uploadingbar">
                                  <span class="uploadprogressbar"></span>
                                  <span>{shoulderUpImageData?.name}</span>
                                  <em>
                                    {shoulderUpImageData?.size && (
                                      <>
                                        File size:{" "}
                                        {bytesToKB(shoulderUpImageData?.size)}
                                      </>
                                    )}
                                    <i
                                      class="fa fa-times lnr lnr-cross"
                                      aria-hidden="true"
                                      onClick={() =>
                                        // setProfileImage("")
                                        removeGalleryImage(
                                          shoulderUpImageData,
                                          "shoulders_up_straight"
                                        )
                                      }
                                    ></i>
                                  </em>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>

              <div class="form-section">
                <div className="form-section-title">
                  Shoulder Up, Side Profile
                </div>
                <div class="wt-bannerphoto wt-tabsinfo">
                  <div class="wt-profilephotocontent">
                    <form class="wt-formtheme wt-formprojectinfo wt-formcategory">
                      <div class="form-group form-group-label">
                        <div
                          onClick={() => {
                            if (page === "register") {
                              setIsImageInfoModal(true);
                              setImageType("shoulderDown");
                            }
                          }}
                          class="wt-labelgroup"
                        >
                          <label for={page === "register" ? "" : "filestu"}>
                            <span class="wt-btn">
                              {page === "register" ? "Upload" : "Select Files"}
                            </span>
                            {page === "register" ? undefined : (
                              <input
                                type="file"
                                name="file"
                                id="filestu"
                                onChange={(e) =>
                                  handleChooseImages(e, "shoulderDown")
                                }
                              />
                            )}
                          </label>
                          <span>Choose file to upload</span>
                          {auth?.isLoading && (
                            <em class="wt-fileuploading">
                              Uploading
                              <i class="fa fa-spinner fa-spin"></i>
                            </em>
                          )}
                        </div>
                      </div>
                      {shoulderDownImage && (
                        <ImageCropper
                          selectedFile={shoulderDownImage}
                          setSelectedFile={setShoulderDownImage}
                          imageSrc={shoulderDownImage?.uri}
                          type={"shoulders_up_turn"}
                          show={cropped}
                          setShow={setCropped}
                          cropped={cropped}
                          setCropped={setCropped}
                          profileImage={shoulderDownImageData}
                          setProfileImage={setShoulderDownImageData}
                          onCrop={uploadWaistphoto}
                        />
                      )}
                      {shoulderDownImageData && (
                        <div class="form-group">
                          <ul class="wt-attachfile wt-attachfilevtwo">
                            <li class="wt-uploadingholder">
                              <div class="wt-uploadingbox">
                                <div class="wt-designimg">
                                  <input
                                    id="demoq"
                                    type="radio"
                                    name="employees"
                                    value="company"
                                  />
                                  <label for="demoq">
                                    <img
                                      src={shoulderDownImageData?.uri}
                                      alt="Hire talent | Model near me | Talent acquisition | Modeling agencies near me | Modeling Jobs | Modeling companies near me"
                                    />
                                    <i class="fa fa-check"></i>
                                  </label>
                                </div>
                                <div class="wt-uploadingbar">
                                  <span class="uploadprogressbar"></span>
                                  <span>{shoulderDownImageData?.name}</span>
                                  <em>
                                    {shoulderDownImageData?.size && (
                                      <>
                                        File size:{" "}
                                        {bytesToKB(shoulderDownImageData?.size)}
                                      </>
                                    )}
                                    <i
                                      class="fa fa-times lnr lnr-cross"
                                      aria-hidden="true"
                                      onClick={() =>
                                        // setProfileImage("")
                                        removeGalleryImage(
                                          shoulderDownImageData,
                                          "shoulders_up_turn"
                                        )
                                      }
                                    ></i>
                                  </em>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </>
          ) : undefined}

          {talent === "Actor" ? (
            <>
              <div class="form-section">
                <div className="form-section-title">Happy</div>
                <div class="wt-bannerphoto wt-tabsinfo">
                  <div class="wt-profilephotocontent">
                    <form class="wt-formtheme wt-formprojectinfo wt-formcategory">
                      <div class="form-group form-group-label">
                        <div
                          onClick={() => {
                            if (page === "register") {
                              setIsImageInfoModal(true);
                              setImageType("happy");
                            }
                          }}
                          class="wt-labelgroup"
                        >
                          <label for={page === "register" ? "" : "filehappy"}>
                            <span class="wt-btn">
                              {page === "register" ? "Upload" : "Select Files"}
                            </span>
                            {page === "register" ? undefined : (
                              <input
                                type="file"
                                name="file"
                                id="filehappy"
                                onChange={(e) => handleChooseImages(e, "happy")}
                              />
                            )}
                          </label>
                          <span>Choose file to upload</span>
                          {auth?.isLoading && (
                            <em class="wt-fileuploading">
                              Uploading
                              <i class="fa fa-spinner fa-spin"></i>
                            </em>
                          )}
                        </div>
                      </div>
                      {happy && (
                        <ImageCropper
                          selectedFile={happy}
                          setSelectedFile={setHappy}
                          imageSrc={happy?.uri}
                          type={"happy"}
                          show={cropped}
                          setShow={setCropped}
                          cropped={cropped}
                          setCropped={setCropped}
                          profileImage={happyData}
                          setProfileImage={setHappyData}
                          onCrop={uploadWaistphoto}
                        />
                      )}
                      {happyData && (
                        <div class="form-group">
                          <ul class="wt-attachfile wt-attachfilevtwo">
                            <li class="wt-uploadingholder">
                              <div class="wt-uploadingbox">
                                <div class="wt-designimg">
                                  <input
                                    id="demoq"
                                    type="radio"
                                    name="employees"
                                    value="company"
                                  />
                                  <label for="demoq">
                                    <img
                                      src={happyData?.uri}
                                      alt="Hire talent | Model near me | Talent acquisition | Modeling agencies near me | Modeling Jobs | Modeling companies near me"
                                    />
                                    <i class="fa fa-check"></i>
                                  </label>
                                </div>
                                <div class="wt-uploadingbar">
                                  <span class="uploadprogressbar"></span>
                                  <span>{happyData?.name}</span>
                                  <em>
                                    {happyData?.size && (
                                      <>
                                        File size: {bytesToKB(happyData?.size)}
                                      </>
                                    )}
                                    <i
                                      class="fa fa-times lnr lnr-cross"
                                      aria-hidden="true"
                                      onClick={() =>
                                        // setProfileImage("")
                                        removeGalleryImage(happyData, "happy")
                                      }
                                    ></i>
                                  </em>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>

              <div class="form-section">
                <div className="form-section-title">Sad</div>
                <div class="wt-bannerphoto wt-tabsinfo">
                  <div class="wt-profilephotocontent">
                    <form class="wt-formtheme wt-formprojectinfo wt-formcategory">
                      <div class="form-group form-group-label">
                        <div
                          onClick={() => {
                            if (page === "register") {
                              setIsImageInfoModal(true);
                              setImageType("sad");
                            }
                          }}
                          class="wt-labelgroup"
                        >
                          <label for={page === "register" ? "" : "filesad"}>
                            <span class="wt-btn">
                              {page === "register" ? "Upload" : "Select Files"}
                            </span>
                            {page === "register" ? undefined : (
                              <input
                                type="file"
                                name="file"
                                id="filesad"
                                onChange={(e) => handleChooseImages(e, "sad")}
                              />
                            )}
                          </label>
                          <span>Choose file to upload</span>
                          {auth?.isLoading && (
                            <em class="wt-fileuploading">
                              Uploading
                              <i class="fa fa-spinner fa-spin"></i>
                            </em>
                          )}
                        </div>
                      </div>
                      {sad && (
                        <ImageCropper
                          selectedFile={sad}
                          setSelectedFile={setSad}
                          imageSrc={sad?.uri}
                          type={"sad"}
                          show={cropped}
                          setShow={setCropped}
                          cropped={cropped}
                          setCropped={setCropped}
                          profileImage={sadData}
                          setProfileImage={setSadData}
                          onCrop={uploadWaistphoto}
                        />
                      )}
                      {sadData && (
                        <div class="form-group">
                          <ul class="wt-attachfile wt-attachfilevtwo">
                            <li class="wt-uploadingholder">
                              <div class="wt-uploadingbox">
                                <div class="wt-designimg">
                                  <input
                                    id="demoq"
                                    type="radio"
                                    name="employees"
                                    value="company"
                                  />
                                  <label for="demoq">
                                    <img
                                      src={sadData?.uri}
                                      alt="Hire talent | Model near me | Talent acquisition | Modeling agencies near me | Modeling Jobs | Modeling companies near me"
                                    />
                                    <i class="fa fa-check"></i>
                                  </label>
                                </div>
                                <div class="wt-uploadingbar">
                                  <span class="uploadprogressbar"></span>
                                  <span>{sadData?.name}</span>
                                  <em>
                                    {sadData?.size && (
                                      <>File size: {bytesToKB(sadData?.size)}</>
                                    )}
                                    <i
                                      class="fa fa-times lnr lnr-cross"
                                      aria-hidden="true"
                                      onClick={() =>
                                        // setProfileImage("")
                                        removeGalleryImage(sadData, "sad")
                                      }
                                    ></i>
                                  </em>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>

              <div class="form-section">
                <div className="form-section-title">Angry</div>
                <div class="wt-bannerphoto wt-tabsinfo">
                  <div class="wt-profilephotocontent">
                    <form class="wt-formtheme wt-formprojectinfo wt-formcategory">
                      <div class="form-group form-group-label">
                        <div
                          onClick={() => {
                            if (page === "register") {
                              setIsImageInfoModal(true);
                              setImageType("angry");
                            }
                          }}
                          class="wt-labelgroup"
                        >
                          <label for={page === "register" ? "" : "fileangry"}>
                            <span class="wt-btn">
                              {page === "register" ? "Upload" : "Select Files"}
                            </span>
                            {page === "register" ? undefined : (
                              <input
                                type="file"
                                name="file"
                                id="fileangry"
                                onChange={(e) => handleChooseImages(e, "angry")}
                              />
                            )}
                          </label>
                          <span>Choose file to upload</span>
                          {auth?.isLoading && (
                            <em class="wt-fileuploading">
                              Uploading
                              <i class="fa fa-spinner fa-spin"></i>
                            </em>
                          )}
                        </div>
                      </div>
                      {angry && (
                        <ImageCropper
                          selectedFile={angry}
                          setSelectedFile={setangry}
                          imageSrc={angry?.uri}
                          type={"angry"}
                          show={cropped}
                          setShow={setCropped}
                          cropped={cropped}
                          setCropped={setCropped}
                          profileImage={AngryData}
                          setProfileImage={setAngryData}
                          onCrop={uploadWaistphoto}
                        />
                      )}
                      {AngryData && (
                        <div class="form-group">
                          <ul class="wt-attachfile wt-attachfilevtwo">
                            <li class="wt-uploadingholder">
                              <div class="wt-uploadingbox">
                                <div class="wt-designimg">
                                  <input
                                    id="demoq"
                                    type="radio"
                                    name="employees"
                                    value="company"
                                  />
                                  <label for="demoq">
                                    <img
                                      src={AngryData?.uri}
                                      alt="Hire talent | Model near me | Talent acquisition | Modeling agencies near me | Modeling Jobs | Modeling companies near me"
                                    />
                                    <i class="fa fa-check"></i>
                                  </label>
                                </div>
                                <div class="wt-uploadingbar">
                                  <span class="uploadprogressbar"></span>
                                  <span>{AngryData?.name}</span>
                                  <em>
                                    {AngryData?.size && (
                                      <>
                                        File size: {bytesToKB(AngryData?.size)}
                                      </>
                                    )}
                                    <i
                                      class="fa fa-times lnr lnr-cross"
                                      aria-hidden="true"
                                      onClick={() =>
                                        // setProfileImage("")
                                        removeGalleryImage(AngryData, "angry")
                                      }
                                    ></i>
                                  </em>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </>
          ) : undefined}
        </>
      ) : null}
      <ImageInfoModal
        show={isImageInfoModal}
        setShow={setIsImageInfoModal}
        imageType={imageType}
        handleChooseProfile={handleChooseProfile}
        handleChooseGallery={handleChooseGallery}
        handleChooseImages={handleChooseImages}
        images={images}
      />
    </>
  );
};

export default UserImages;
