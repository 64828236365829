export const APPLIED_FILTER = "APPLIED_FILTER";

export const GET_MODELS_LIST_LOADING = "GET_MODELS_LIST_LOADING";
export const GET_MODELS_LIST_SUCCESS = "GET_MODELS_LIST_SUCCESS";
export const GET_MODELS_LIST_ERROR = "GET_MODELS_LIST_ERROR";

export const GET_KIDS_LIST_LOADING = "GET_KIDS_LIST_LOADING";
export const GET_KIDS_LIST_SUCCESS = "GET_KIDS_LIST_SUCCESS";
export const GET_KIDS_LIST_ERROR = "GET_KIDS_LIST_ERROR";

export const GET_BLOG_REVIEWS_LIST_LOADING = "GET_BLOG_REVIEWS_LIST_LOADING";
export const GET_BLOG_REVIEWS_LIST_SUCCESS = "GET_BLOG_REVIEWS_LIST_SUCCESS";
export const GET_BLOG_REVIEWS_LIST_ERROR = "GET_BLOG_REVIEWS_LIST_ERROR";

export const GET_FAQ_LIST_LOADING = "GET_FAQ_LIST_LOADING";
export const GET_FAQ_LIST_SUCCESS = "GET_FAQ_LIST_SUCCESS";
export const GET_FAQ_LIST_ERROR = "GET_FAQ_LIST_ERROR";

export const SEND_NEWS_LETTER_LOADING = "SEND_NEWS_LETTER_LOADING";
export const SEND_NEWS_LETTER_SUCCESS = "SEND_NEWS_LETTER_SUCCESS";
export const SEND_NEWS_LETTER_ERROR = "SEND_NEWS_LETTER_ERROR";

export const GET_PHOTOGRAPHER_LIST_LOADING = "GET_PHOTOGRAPHER_LIST_LOADING";
export const GET_PHOTOGRAPHER_LIST_SUCCESS = "GET_PHOTOGRAPHER_LIST_SUCCESS";
export const GET_PHOTOGRAPHER_LIST_ERROR = "GET_PHOTOGRAPHER_LIST_ERROR";

export const GET_JOB_DETAILS_LOADING = "GET_JOB_DETAILS_LOADING";
export const GET_JOB_DETAILS_SUCCESS = "GET_JOB_DETAILS_SUCCESS";
export const GET_JOB_DETAILS_ERROR = "GET_JOB_DETAILS_ERROR";

export const REPOST_JOB_LOADING = "REPOST_JOB_LOADING";
export const REPOST_JOB_SUCCESS = "REPOST_JOB_SUCCESS";
export const REPOST_JOB_ERROR = "REPOST_JOB_ERROR";

export const GET_LANGUAGES_LIST_LOADING = "GET_LANGUAGES_LIST_LOADING";
export const GET_LANGUAGES_LIST_SUCCESS = "GET_LANGUAGES_LIST_SUCCESS";
export const GET_LANGUAGES_LIST_ERROR = "GET_LANGUAGES_LIST_ERROR";

export const GET_COUNTRY_LIST_LOADING = "GET_COUNTRY_LIST_LOADING";
export const GET_COUNTRY_LIST_SUCCESS = "GET_COUNTRY_LIST_SUCCESS";
export const GET_COUNTRY_LIST_ERROR = "GET_COUNTRY_LIST_ERROR";

export const GET_JOB_FILTER_LOADING = "GET_JOB_FILTER_LOADING";
export const GET_JOB_FILTER_SUCCESS = "GET_JOB_FILTER_SUCCESS";
export const GET_JOB_FILTER_ERROR = "GET_JOB_FILTER_ERROR";

export const POST_JOB_LOADING = "POST_JOB_LOADING";
export const POST_JOB_SUCCESS = "POST_JOB_SUCCESS";
export const POST_JOB_ERROR = "POST_JOB_ERROR";

export const UPDATE_POSTED_JOB_LOADING = "UPDATE_POSTED_JOB_LOADING";
export const UPDATE_POSTED_JOB_SUCCESS = "UPDATE_POSTED_JOB_SUCCESS";
export const UPDATE_POSTED_JOB_ERROR = "UPDATE_POSTED_JOB_ERROR";

export const GET_JOBS_LIST_LOADING = "GET_JOBS_LIST_LOADING";
export const GET_JOBS_LIST_SUCCESS = "GET_JOBS_LIST_SUCCESS";
export const GET_JOBS_LIST_ERROR = "GET_JOBS_LIST_ERROR";

export const USER_FOLLOWING_LOADING = "USER_FOLLOWING_LOADING";
export const USER_FOLLOWING_SUCCESS = "USER_FOLLOWING_SUCCESS";
export const USER_FOLLOWING_ERROR = "USER_FOLLOWING_ERROR";

export const GET_FOLLOW_LIST_LOADING = "GET_FOLLOW_LIST_LOADING";
export const GET_FOLLOW_LIST_SUCCESS = "GET_FOLLOW_LIST_SUCCESS";
export const GET_FOLLOW_LIST_ERROR = "GET_FOLLOW_LIST_ERROR";

export const GET_FOLLOW_DETAILS_LOADING = "GET_FOLLOW_DETAILS_LOADING";
export const GET_FOLLOW_DETAILS_SUCCESS = "GET_FOLLOW_DETAILS_SUCCESS";
export const GET_FOLLOW_DETAILS_ERROR = "GET_FOLLOW_DETAILS_ERROR";

export const USER_REPORTING_LOADING = "USER_REPORTING_LOADING";
export const USER_REPORTING_SUCCESS = "USER_REPORTING_SUCCESS";
export const USER_REPORTING_ERROR = "USER_REPORTING_ERROR";

export const GET_SEARCH_RESULTS_LOADING = "GET_SEARCH_RESULTS_LOADING";
export const GET_SEARCH_RESULTS_SUCCESS = "GET_SEARCH_RESULTS_SUCCESS";
export const GET_SEARCH_RESULTS_ERROR = "GET_SEARCH_RESULTS_ERROR";

export const SUBMIT_POST_PROPOSAL_LOADING = "SUBMIT_POST_PROPOSAL_LOADING";
export const SUBMIT_POST_PROPOSAL_SUCCESS = "SUBMIT_POST_PROPOSAL_SUCCESS";
export const SUBMIT_POST_PROPOSAL_ERROR = "SUBMIT_POST_PROPOSAL_ERROR";

export const UPDATE_POST_PROPOSAL_LOADING = "UPDATE_POST_PROPOSAL_LOADING";
export const UPDATE_POST_PROPOSAL_SUCCESS = "UPDATE_POST_PROPOSAL_SUCCESS";
export const UPDATE_POST_PROPOSAL_ERROR = "UPDATE_POST_PROPOSAL_ERROR";

export const GET_PROPOSAL_LIST_LOADING = "GET_PROPOSAL_LIST_LOADING";
export const GET_PROPOSAL_LIST_SUCCESS = "GET_PROPOSAL_LIST_SUCCESS";
export const GET_PROPOSAL_LIST_ERROR = "GET_PROPOSAL_LIST_ERROR";

export const GET_LATEST_PROPOSALS_LOADING = "GET_LATEST_PROPOSALS_LOADING";
export const GET_LATEST_PROPOSALS_SUCCESS = "GET_LATEST_PROPOSALS_SUCCESS";
export const GET_LATEST_PROPOSALS_ERROR = "GET_LATEST_PROPOSALS_ERROR";

export const HIRE_CHECKOUT_LOADING = "HIRE_CHECKOUT_LOADING";
export const HIRE_CHECKOUT_SUCCESS = "HIRE_CHECKOUT_SUCCESS";
export const HIRE_CHECKOUT_ERROR = "HIRE_CHECKOUT_ERROR";

export const GET_NOTIFICATION_LIST_LOADING = "GET_NOTIFICATION_LIST_LOADING";
export const GET_NOTIFICATION_LIST_SUCCESS = "GET_NOTIFICATION_LIST_SUCCESS";
export const GET_NOTIFICATION_LIST_ERROR = "GET_NOTIFICATION_LIST_ERROR";

export const UPDATE_NOTIFY_STATUS_LOADING = "UPDATE_NOTIFY_STATUS_LOADING";
export const UPDATE_NOTIFY_STATUS_SUCCESS = "UPDATE_NOTIFY_STATUS_SUCCESS";
export const UPDATE_NOTIFY_STATUS_ERROR = "UPDATE_NOTIFY_STATUS_ERROR";

export const PROJECT_SINGLE_CLIENT_LOADING = "PROJECT_SINGLE_CLIENT_LOADING";
export const PROJECT_SINGLE_CLIENT_SUCCESS = "PROJECT_SINGLE_CLIENT_SUCCESS";
export const PROJECT_SINGLE_CLIENT_ERROR = "PROJECT_SINGLE_CLIENT_ERROR";

export const MARKED_NOTIFY_STATUS_LOADING = "MARKED_NOTIFY_STATUS_LOADING";
export const MARKED_NOTIFY_STATUS_SUCCESS = "MARKED_NOTIFY_STATUS_SUCCESS";
export const MARKED_NOTIFY_STATUS_ERROR = "MARKED_NOTIFY_STATUS_ERROR";

export const SUMBMIT_CONTACT_FORM_LOADING = "SUMBMIT_CONTACT_FORM_LOADING";
export const SUMBMIT_CONTACT_FORM_SUCCESS = "SUMBMIT_CONTACT_FORM_SUCCESS";
export const SUMBMIT_CONTACT_FORM_ERROR = "SUMBMIT_CONTACT_FORM_ERROR";

export const GET_PROPOSAL_STATUS_LOADING = "GET_PROPOSAL_STATUS_LOADING";
export const GET_PROPOSAL_STATUS_SUCCESS = "GET_PROPOSAL_STATUS_SUCCESS";
export const GET_PROPOSAL_STATUS_ERROR = "GET_PROPOSAL_STATUS_ERROR";

export const GET_MODEL_PROJECTS_LOADING = "GET_MODEL_PROJECTS_LOADING";
export const GET_MODEL_PROJECTS_SUCCESS = "GET_MODEL_PROJECTS_SUCCESS";
export const GET_MODEL_PROJECTS_ERROR = "GET_MODEL_PROJECTS_ERROR";

export const GET_ACCOUNT_SETTING_DETAILS_LOADING =
  "GET_ACCOUNT_SETTING_DETAILS_LOADING";
export const GET_ACCOUNT_SETTING_DETAILS_SUCCESS =
  "GET_ACCOUNT_SETTING_DETAILS_SUCCESS";
export const GET_ACCOUNT_SETTING_DETAILS_ERROR =
  "GET_ACCOUNT_SETTING_DETAILS_ERROR";

export const DELETE_USER_LOADING = "DELETE_USER_LOADING";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_ERROR = "DELETE_USER_ERROR";

export const GET_ALL_TALENTS_LIST_LOADING = "GET_ALL_TALENTS_LIST_LOADING";
export const GET_ALL_TALENTS_LIST_SUCCESS = "GET_ALL_TALENTS_LIST_SUCCESS";
export const GET_ALL_TALENTS_LIST_ERROR = "GET_ALL_TALENTS_LIST_ERROR";

export const GET_ALL_CLIENTS_LIST_LOADING = "GET_ALL_CLIENTS_LIST_LOADING";
export const GET_ALL_CLIENTS_LIST_SUCCESS = "GET_ALL_CLIENTS_LIST_SUCCESS";
export const GET_ALL_CLIENTS_LIST_ERROR = "GET_ALL_CLIENTS_LIST_ERROR";

export const UPLOAD_PORTFOLIO_LOADING = "UPLOAD_PORTFOLIO_LOADING";
export const UPLOAD_PORTFOLIO_SUCCESS = "UPLOAD_PORTFOLIO_SUCCESS";
export const UPLOAD_PORTFOLIO_ERROR = "UPLOAD_PORTFOLIO_ERROR";

export const UPLOAD_SOCIAL_POST_LOADING = "UPLOAD_SOCIAL_POST_LOADING";
export const UPLOAD_SOCIAL_POST_SUCCESS = "UPLOAD_SOCIAL_POST_SUCCESS";
export const UPLOAD_SOCIAL_POST_ERROR = "UPLOAD_SOCIAL_POST_ERROR";

export const UPLOAD_VIDEO_LOADING = "UPLOAD_VIDEO_LOADING";
export const UPLOAD_VIDEO_SUCCESS = "UPLOAD_VIDEO_SUCCESS";
export const UPLOAD_VIDEO_ERROR = "UPLOAD_VIDEO_ERROR";

export const GET_REEL_LIST_LOADING = "GET_REEL_LIST_LOADING";
export const GET_REEL_LIST_SUCCESS = "GET_REEL_LIST_SUCCESS";
export const GET_REEL_LIST_ERROR = "GET_REEL_LIST_ERROR";

export const UPDATE_PORTFOLIO_LOADING = "UPDATE_PORTFOLIO_LOADING";
export const UPDATE_PORTFOLIO_SUCCESS = "UPDATE_PORTFOLIO_SUCCESS";
export const UPDATE_PORTFOLIO_ERROR = "UPDATE_PORTFOLIO_ERROR";

export const UPDATE_SOCIAL_POST_LOADING = "UPDATE_SOCIAL_POST_LOADING";
export const UPDATE_SOCIAL_POST_SUCCESS = "UPDATE_SOCIAL_POST_SUCCESS";
export const UPDATE_SOCIAL_POST_ERROR = "UPDATE_SOCIAL_POST_ERROR";

export const GET_USER_PORTFOLIO_LOADING = "GET_USER_PORTFOLIO_LOADING";
export const GET_USER_PORTFOLIO_SUCCESS = "GET_USER_PORTFOLIO_SUCCESS";
export const GET_USER_PORTFOLIO_ERROR = "GET_USER_PORTFOLIO_ERROR";

export const GET_USER_SOCIAL_POSTS_LOADING = "GET_USER_SOCIAL_POSTS_LOADING";
export const GET_USER_SOCIAL_POSTS_SUCCESS = "GET_USER_SOCIAL_POSTS_SUCCESS";
export const GET_USER_SOCIAL_POSTS_ERROR = "GET_USER_SOCIAL_POSTS_ERROR";

export const GET_PORTFOLIO_DETAILS_LOADING = "GET_PORTFOLIO_DETAILS_LOADING";
export const GET_PORTFOLIO_DETAILS_SUCCESS = "GET_PORTFOLIO_DETAILS_SUCCESS";
export const GET_PORTFOLIO_DETAILS_ERROR = "GET_PORTFOLIO_DETAILS_ERROR";

export const GET_SOCIAL_POST_DETAILS_LOADING =
  "GET_SOCIAL_POST_DETAILS_LOADING";
export const GET_SOCIAL_POST_DETAILS_SUCCESS =
  "GET_SOCIAL_POST_DETAILS_SUCCESS";
export const GET_SOCIAL_POST_DETAILS_ERROR = "GET_SOCIAL_POST_DETAILS_ERROR";

export const ADD_COMMENT_LOADING = "ADD_COMMENT_LOADING";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_ERROR = "ADD_COMMENT_ERROR";

export const DELETE_COMMENT_LOADING = "DELETE_COMMENT_LOADING";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_ERROR = "DELETE_COMMENT_ERROR";

export const LIKE_UNLIKE_COMMENT_LOADING = "LIKE_UNLIKE_COMMENT_LOADING";
export const LIKE_UNLIKE_COMMENT_SUCCESS = "LIKE_UNLIKE_COMMENT_SUCCESS";
export const LIKE_UNLIKE_COMMENT_ERROR = "LIKE_UNLIKE_COMMENT_ERROR";

export const LIKE_DISLIKE_LOADING = "LIKE_DISLIKE_LOADING";
export const LIKE_DISLIKE_SUCCESS = "LIKE_DISLIKE_SUCCESS";
export const LIKE_DISLIKE_ERROR = "LIKE_DISLIKE_ERROR";

export const GET_CHAT_LIST_LOADING = "GET_CHAT_LIST_LOADING";
export const GET_CHAT_LIST_SUCCESS = "GET_CHAT_LIST_SUCCESS";
export const GET_CHAT_LIST_ERROR = "GET_CHAT_LIST_ERROR";

export const GET_USER_LIST_LOADING = "GET_USER_LIST_LOADING";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_ERROR = "GET_USER_LIST_ERROR";

export const SEND_MESSAGE_LOADING = "SEND_MESSAGE_LOADING";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_ERROR = "SEND_MESSAGE_ERROR";

export const CLEAR_USER_CHAT_LOADING = "CLEAR_USER_CHAT_LOADING";
export const CLEAR_USER_CHAT_SUCCESS = "CLEAR_USER_CHAT_SUCCESS";
export const CLEAR_USER_CHAT_ERROR = "CLEAR_USER_CHAT_ERROR";

export const GET_TYPING_STATUS_LOADING = "GET_TYPING_STATUS_LOADING";
export const GET_TYPING_STATUS_SUCCESS = "GET_TYPING_STATUS_SUCCESS";
export const GET_TYPING_STATUS_ERROR = "GET_TYPING_STATUS_ERROR";

export const GET_NOTIFICATION_BADGE_LOADING = "GET_NOTIFICATION_BADGE_LOADING";
export const GET_NOTIFICATION_BADGE_SUCCESS = "GET_NOTIFICATION_BADGE_SUCCESS";
export const GET_NOTIFICATION_BADGE_ERROR = "GET_NOTIFICATION_BADGE_ERROR";

export const GET_READ_STATUS_LOADING = "GET_READ_STATUS_LOADING";
export const GET_READ_STATUS_SUCCESS = "GET_READ_STATUS_SUCCESS";
export const GET_READ_STATUS_ERROR = "GET_READ_STATUS_ERROR";

export const GET_PORTFOLIOS_LOADING = "GET_PORTFOLIOS_LOADING";
export const GET_PORTFOLIOS_SUCCESS = "GET_PORTFOLIOS_SUCCESS";
export const GET_PORTFOLIOS_ERROR = "GET_PORTFOLIOS_ERROR";

export const GET_SOCIAL_POSTS_LOADING = "GET_SOCIAL_POSTS_LOADING";
export const GET_SOCIAL_POSTS_SUCCESS = "GET_SOCIAL_POSTS_SUCCESS";
export const GET_SOCIAL_POSTS_ERROR = "GET_SOCIAL_POSTS_ERROR";

export const DELETE_POST_LOADING = "DELETE_POST_LOADING";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_ERROR = "DELETE_POST_ERROR";

export const GET_TRIMMED_VIDEO_LOADING = "GET_TRIMMED_VIDEO_LOADING";
export const GET_TRIMMED_VIDEO_SUCCESS = "GET_TRIMMED_VIDEO_SUCCESS";
export const GET_TRIMMED_VIDEO_ERROR = "GET_TRIMMED_VIDEO_ERROR";

export const SAVE_POST_LOADING = "SAVE_POST_LOADING";
export const SAVE_POST_SUCCESS = "SAVE_POST_SUCCESS";
export const SAVE_POST_ERROR = "SAVE_POST_ERROR";

export const UNSAVE_POST_LOADING = "UNSAVE_POST_LOADING";
export const UNSAVE_POST_SUCCESS = "UNSAVE_POST_SUCCESS";
export const UNSAVE_POST_ERROR = "UNSAVE_POST_ERROR";

export const GET_SAVED_POST_LOADING = "GET_SAVED_POST_LOADING";
export const GET_SAVED_POST_SUCCESS = "GET_SAVED_POST_SUCCESS";
export const GET_SAVED_POST_ERROR = "GET_SAVED_POST_ERROR";

export const GET_USER_REVIEW_LOADING = "GET_USER_REVIEW_LOADING";
export const GET_USER_REVIEW_SUCCESS = "GET_USER_REVIEW_SUCCESS";
export const GET_USER_REVIEW_ERROR = "GET_USER_REVIEW_ERROR";

export const DELETE_JOB_LOADING = "DELETE_JOB_LOADING";
export const DELETE_JOB_SUCCESS = "DELETE_JOB_SUCCESS";
export const DELETE_JOB_ERROR = "DELETE_JOB_ERROR";

export const GET_CONNECTS_LOADING = "GET_CONNECTS_LOADING";
export const GET_CONNECTS_SUCCESS = "GET_CONNECTS_SUCCESS";
export const GET_CONNECTS_ERROR = "GET_CONNECTS_ERROR";

export const JOB_RATING_LOADING = "JOB_RATING_LOADING";
export const JOB_RATING_SUCCESS = "JOB_RATING_SUCCESS";
export const JOB_RATING_ERROR = "JOB_RATING_ERROR";

export const COMPLETE_PAY_LOADING = "COMPLETE_PAY_LOADING";
export const COMPLETE_PAY_SUCCESS = "COMPLETE_PAY_SUCCESS";
export const COMPLETE_PAY_ERROR = "COMPLETE_PAY_ERROR";

// Payment Gateway
export const GET_PRODUCT_LIST_LOADING = "GET_PRODUCT_LIST_LOADING";
export const GET_PRODUCT_LIST_SUCCESS = "GET_PRODUCT_LIST_SUCCESS";
export const GET_PRODUCT_LIST_ERROR = "GET_PRODUCT_LIST_ERROR";

export const CREATE_SESSION_LOADING = "CREATE_SESSION_LOADING";
export const CREATE_SESSION_SUCCESS = "CREATE_SESSION_SUCCESS";
export const CREATE_SESSION_ERROR = "CREATE_SESSION_ERROR";

export const PRODUCT_CHECKOUT_LOADING = "PRODUCT_CHECKOUT_LOADING";
export const PRODUCT_CHECKOUT_SUCCESS = "PRODUCT_CHECKOUT_SUCCESS";
export const PRODUCT_CHECKOUT_ERROR = "PRODUCT_CHECKOUT_ERROR";

export const PENALTY_CHARGES_LIST_LOADING = "PENALTY_CHARGES_LIST_LOADING";
export const PENALTY_CHARGES_LIST_SUCCESS = "PENALTY_CHARGES_LIST_SUCCESS";
export const PENALTY_CHARGES_LIST_ERROR = "PENALTY_CHARGES_LIST_ERROR";

export const HANDLE_WITHDRAW_LOADING = "HANDLE_WITHDRAW_LOADING";
export const HANDLE_WITHDRAW_SUCCESS = "HANDLE_WITHDRAW_SUCCESS";
export const HANDLE_WITHDRAW_ERROR = "HANDLE_WITHDRAW_ERROR";

export const GET_PAYOUTS_LIST_LOADING = "GET_PAYOUTS_LIST_LOADING";
export const GET_PAYOUTS_LIST_SUCCESS = "GET_PAYOUTS_LIST_SUCCESS";
export const GET_PAYOUTS_LIST_ERROR = "GET_PAYOUTS_LIST_ERROR";

export const ADD_STRIPE_ACCOUNT_LOADING = "ADD_STRIPE_ACCOUNT_LOADING";
export const ADD_STRIPE_ACCOUNT_SUCCESS = "ADD_STRIPE_ACCOUNT_SUCCESS";
export const ADD_STRIPE_ACCOUNT_ERROR = "ADD_STRIPE_ACCOUNT_ERROR";

export const SEND_STRIPE_RESPONSE_LOADING = "SEND_STRIPE_RESPONSE_LOADING";
export const SEND_STRIPE_RESPONSE_SUCCESS = "SEND_STRIPE_RESPONSE_SUCCESS";
export const SEND_STRIPE_RESPONSE_ERROR = "SEND_STRIPE_RESPONSE_ERROR";

export const GET_POSTED_LISTING_LOADING = "GET_POSTED_LISTING_LOADING";
export const GET_POSTED_LISTING_SUCCESS = "GET_POSTED_LISTING_SUCCESS";
export const GET_POSTED_LISTING_ERROR = "GET_POSTED_LISTING_ERROR";

export const GET_RATING_OPTIONS_LOADING = " GET_RATING_OPTIONS_LOADING";
export const GET_RATING_OPTIONS_SUCCESS = " GET_RATING_OPTIONS_SUCCESS";
export const GET_RATING_OPTIONS_ERROR = " GET_RATING_OPTIONS_ERROR";

export const GET_MODEL_RATING_OPTIONS_LOADING = " GET_MODEL_RATING_OPTIONS_LOADING";
export const GET_MODEL_RATING_OPTIONS_SUCCESS = " GET_MODEL_RATING_OPTIONS_SUCCESS";
export const GET_MODEL_RATING_OPTIONS_ERROR = " GET_MODEL_RATING_OPTIONS_ERROR";

export const ADD_DIRECT_JOB_LOADING = " ADD_DIRECT_JOB_LOADING";
export const ADD_DIRECT_JOB_SUCCESS = " ADD_DIRECT_JOB_SUCCESS";
export const ADD_DIRECT_JOB_ERROR = " ADD_DIRECT_JOB_ERROR";

export const ACTION_DIRECT_JOB_LOADING = " ACTION_DIRECT_JOB_LOADING";
export const ACTION_DIRECT_JOB_SUCCESS = " ACTION_DIRECT_JOB_SUCCESS";
export const ACTION_DIRECT_JOB_ERROR = " ACTION_DIRECT_JOB_ERROR";

export const TALENT_FILTERS = "TALENT_FILTERS";

export const INSIGHT_DETAIL_LOADING = "INSIGHT_DETAIL_LOADING";
export const INSIGHT_DETAIL_SUCCESS = "INSIGHT_DETAIL_SUCCESS";
export const INSIGHT_DETAIL_ERROR = "INSIGHT_DETAIL_ERROR";

export const EVENT_ADD_REMOVE_LOADING = "EVENT_ADD_REMOVE_LOADING";
export const EVENT_ADD_REMOVE_SUCCESS = "EVENT_ADD_REMOVE_SUCCESS";
export const EVENT_ADD_REMOVE_ERROR = "EVENT_ADD_REMOVE_ERROR";

export const EXTRA_PAY_LOADING = "EXTRA_PAY_LOADING";
export const EXTRA_PAY_SUCCESS = "EXTRA_PAY_SUCCESS";
export const EXTRA_PAY_ERROR = "EXTRA_PAY_ERROR";

export const GET_EXTRA_PAY_LOADING = "GET_EXTRA_PAY_LOADING";
export const GET_EXTRA_PAY_SUCCESS = "GET_EXTRA_PAY_SUCCESS";
export const GET_EXTRA_PAY_ERROR = "GET_EXTRA_PAY_ERROR";

export const ADD_TALENT_FFEDBACK_LOADING = "ADD_TALENT_FFEDBACK_LOADING";
export const ADD_TALENT_FFEDBACK_SUCCESS = "ADD_TALENT_FFEDBACK_SUCCESS";
export const ADD_TALENT_FFEDBACK_ERROR = "ADD_TALENT_FFEDBACK_ERROR";

export const ADD_CLIENT_FFEDBACK_LOADING = "ADD_CLIENT_FFEDBACK_LOADING";
export const ADD_CLIENT_FFEDBACK_SUCCESS = "ADD_CLIENT_FFEDBACK_SUCCESS";
export const ADD_CLIENT_FFEDBACK_ERROR = "ADD_CLIENT_FFEDBACK_ERROR";

export const JOB_CANCELED_CLIENT_LOADING = "JOB_CANCELED_CLIENT_LOADING";
export const JOB_CANCELED_CLIENT_SUCCESS = "JOB_CANCELED_CLIENT_SUCCESS";
export const JOB_CANCELED_CLIENT_ERROR = "JOB_CANCELED_CLIENT_ERROR";

export const JOB_CANCELED_TALENT_LOADING = "JOB_CANCELED_TALENT_LOADING";
export const JOB_CANCELED_TALENT_SUCCESS = "JOB_CANCELED_TALENT_SUCCESS";
export const JOB_CANCELED_TALENT_ERROR = "JOB_CANCELED_TALENT_ERROR";