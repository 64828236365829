import React, { useEffect, useState } from "react";
import { Footer, Header, Loader } from "../../Components";
import { getInsightDetailApi } from "../../Redux/Services/OtherServices";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  INSIGHTS_DETAIL_DESCRIPTION,
  INSIGHTS_DETAIL_TITLE,
} from "../../Constants/MetaData";

const InsightDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(location?.search);
  const id = queryParams?.get("id");

  // const { id } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [insightDetails, setinsightDetails] = useState("");

  const handleSaveJob = async (postId, status) => {
    setLoading(true);
    let body = {
      post_type: "blog",
      post_id: id,
    };
    let res = await dispatch(getInsightDetailApi(body));
    if (res?.status == 200) {
      setinsightDetails(res?.results);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSaveJob();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{INSIGHTS_DETAIL_TITLE}</title>
        <meta
          name="keywords"
          content={INSIGHTS_DETAIL_TITLE}
        />
        <meta name="description" content={INSIGHTS_DETAIL_DESCRIPTION} />
      </Helmet>
      <Loader loading={loading} />
      <Header />
      <div className="pageTitle">
        <div className="container">
          <div className="wt-title">
            <h1>{insightDetails?.post_title}</h1>
          </div>
        </div>
      </div>
      <div className="container">
        {insightDetails?.thumbnail?.url ? (
          <div className="thumbnailImg">
            <img src={insightDetails?.thumbnail?.url} />
          </div>
        ) : null}

        <div
          className="blogContent"
          dangerouslySetInnerHTML={{ __html: insightDetails?.post_content }}
        ></div>
      </div>
      <div></div>
      <Footer />
    </div>
  );
};

export default InsightDetail;
